<template>
  <MpModal id="restriction" class="show">
    <div class="modal-inner">
      <div class="modal-message-box">
        <div class="restriction-img">
          <img :src="$image('question/goodplay.svg')" alt="がんばったね！" />
        </div>
        <div class="restriction-message-box">
          <div>
            <div class="restriction-message-title">がんばったね！</div>
            <div class="restriction-message-body">
              <span>30</span
              ><ruby><rb>分</rb><rp>(</rp><rt>ぷん</rt><rp>)</rp></ruby
              >をこえたので<br />
              すこし&nbsp;&nbsp;&nbsp;&nbsp;きゅうけいしましょう。<br />
              アプリをとじてください。
            </div>
          </div>
        </div>
      </div>
    </div>
  </MpModal>
</template>

<script>
import MpModal from "@/components/ui/MpModal.vue";
import Activity from "@/mixins/activity";
export default {
  components: {
    MpModal,
  },
  mixins: [Activity],
  mounted() {
    // 画面ボタン操作イベント
    this.$store.dispatch("history/viewButtonEvent", {
      loginId: this.$store.getters["auth/getLoginId"],
      view: this.$store.getters["app/getCurrentView"],
      event: "使用制限モーダル表示",
      table: this.$store.getters["app/getCurrentTable"],
    });
    // 音声再生
    this.$sound.restriction().play();
  },
};
</script>