export default {
    filters: {
        // 秒数から○○分××秒の分の部分を求める
        minute: function (value) {
            return Math.floor(value / 60);
        },
        // 秒数から○○分××秒の秒の部分を求める
        second: function (value) {
            return value % 60;
        },
        ruby: function (value) {
            if (value === 0 || value === 2  || value === 5) {
                return 'ふん';
            } else if (value === 1 || value === 3 || value === 4) {
                return 'ぷん';
            } else {
                return '';
            }
        },
    },
    data: function () {
        return {
            questions: [], // 問題集
            max: 99, // 最大出題数
            position: 1, // 現在の問題番号（１始まり）            
            complete: false, // 全問完了
            processInterval: 1000, // プロセスインターバル（ミリ秒）
            completionInterval: 500, // 終了処理インターバル（ミリ秒）
        };
    },
    created: function () {
        // 問題集取得
        this.$log.debug("問題開始！！");
        this.$log.debug("ステージ=>%s", this.$store.getters["app/getCurrentStage"]);
        this.$log.debug("レベル=>%s", this.$store.getters["app/getCurrentTable"]);
        this.$log.debug("テーブル=>%s", this.$store.getters["app/getCurrentLevel"]);
        this.questions = this.$store.getters["app/getQuestions"];
        this.$log.debug("問題数:%d", this.questions.length);
        this.$log.debug("最大問題数:%d", this.max);
        this.$log.debug("出題数:%d", this.questionCount);
        this.$log.debug(this.questions);
    },
    computed: {
        // ログインID
        loginId: function () {
            return this.$store.getters["auth/getLoginId"];
        },
        //  レベル
        level: function () {
            return this.$store.getters["app/getCurrentLevel"];
        },
        // 出題数
        questionCount: function () {
            return this.questions.length <= this.max
                ? this.questions.length
                : this.max;
        },
        // 回答数
        answered: function () {
            return this.position <= 0 ? 0 : this.position - 1;
        },
        // 学習中のコンテンツ
        content: function () {
            return this.$store.getters["app/getCurrentContent"];
        },
        // 問題の被乗数
        multiplicand: function () {
            return this.position > 0 && this.position <= this.questions.length ? this.questions[this.position - 1][0] : -1;
        },
        // 問題の乗数
        multiplier: function () {
            return this.position > 0 && this.position <= this.questions.length ? this.questions[this.position - 1][1] : -1;
        },
        isProcessing() {
            return this.$store.getters["app/isProcessing"];
        },
    },
    methods: {
        // 次問題出題イベントフック
        onNext() {
            // 各機能で必要に応じてオーバーライドしてください。
        },
        // コンテンツ終了フックイベント
        onComplete() {
            // 各機能で必要に応じてオーバーライドしてください。
        },
        // コンテンツ終了後フックイベント
        onAfterComplete() {
            // 各機能で必要に応じてオーバーライドしてください。
        },
        // 問題を始めます
        startExpression() {
            // 問題初期化
            this.$store.commit("app/setProcessing", false);
            this.$store.commit("app/setShowHint", false);
            this.$store.commit("app/setHintClosing", false);

            // 問題開始
            this.$log.debug("問題開始=>問題番号:%d", this.position);
            this.$refs.expression.start(this.position, this.multiplicand, this.multiplier);
        },
        // 式にキー操作を入力します
        enterExpression(value) {
            // 電卓の入力結果を問題に送信
            this.$refs.expression.enter(value);
        },
        // 採点結果を判定します
        judgementExpression(expression) {
            this.$log.debug("採点結果の判定:%s", JSON.stringify(expression));
            if (expression.result) {
                // 回答数加算
                this.$store.commit("learningState/inclementAnswered", this.content);
                this.position++;
                if (this.questionCount >= this.position) {
                    // 指定時間待機後に次の問題開始
                    this.$timer.once(() => {
                        this.$log.debug("次の問題=>問題番号:%d", this.position);
                        this.$refs.expression.start(this.position, this.multiplicand, this.multiplier);
                        // 次の問題出題タイミング
                        this.$store.dispatch("history/startQuestion", {
                            loginId: this.loginId,
                            content: this.content,
                            position: this.position,
                            multiplicand: this.multiplicand,
                            multiplier: this.multiplier,
                        });
                        this.onNext();   // 次問題出題フック
                        this.$store.commit("app/setProcessing", false);
                    }, this.processInterval);
                } else {
                    // 全問終了

                    this.$log.debug("コンテンツ終了！！");
                    this.onComplete(); // コンテンツ終了フック

                    this.$timer.once(() => {
                        this.complete = true;
                        this.$store.commit("app/setProcessing", false);
                        this.onAfterComplete(); // コンテンツ終了後フック
                    }, this.completionInterval);
                }
                // プロセス終了
                this.$log.debug("プロセス終了(%d)", this.position);

            } else {
                // 不正解の場合

                // 不正解の場合は、すぐに入力を受け付ける
                this.$store.commit("app/setProcessing", false);

                // 指定時間待機後に再開
                this.$timer.once(() => {
                    // プロセス終了
                    this.$log.debug("プロセス終了(%d)", this.position);
                    this.$log.debug("問題再開:%d", this.position);
                    this.$refs.expression.restart();
                }, this.processInterval);
            }
        },
        // 問題をリセットします
        resetExpression() {
            // 完了フラグクリア
            this.complete = false;
            // 問題番号初期化
            this.position = 1;
            this.$log.debug("問題リセット=>問題番号:%d", this.position);
        },
    },
}