<template>
  <component v-bind:is="currentView">
    <template v-slot:simple>
      <MpOverlay />
      <MpMenu />
      <MpOrientation ref="orientation" />
      <MpCopyright :isPortrait="isPortrait"/>
    </template>
    <template>
      <MpCopyright :isPortrait="isPortrait"/>
      <MpOverlay />
      <MpMenu />
      <MpRestriction v-if="isRestriction" />
      <MpLearningStateEditor />
      <MpHistoryLog />
      <MpOrientation ref="orientation" />
      <MpServiceWorker
        v-if="
          isServiceWorker &&
          (!isServiceWorkerReady || isServiceWorkerUpdatefound)
        "
      />
    </template>
  </component>
</template>

<script>
import MpOverlay from "@/components/ui/MpOverlay"; // オーバーレイ
import MpMenu from "@/components/ui/MpMenu"; // メニュー
import MpRestriction from "@/components/ui/MpRestriction"; // 1日の使用制限
import MpOrientation from "@/components/ui/MpOrientation"; // 縦横制御
import MpServiceWorker from "@/components/ui/MpServiceWorker"; // サービスワーカー画面
import ViewLoading from "@/components/view/Loading.vue"; // ローディング画面
import ViewLogin from "@/components/view/Login.vue"; // ログイン画面
import ViewRestrictionCancel from "@/components/view/RestrictionCancel.vue"; // 1日の使用制限解除画面
import ViewStageNormal from "@/components/view/stage/Normal.vue"; // ノーマルステージメニュー画面
import ViewStageBonus from "@/components/view/stage/Bonus.vue"; // ボーナスメニュー画面
import ViewTableNormalSingle from "@/components/view/table/NormalSingle"; // レベル選択画面（N単、きく・いう）
import ViewTableNormalMulti from "@/components/view/table/NormalMulti"; // レベル選択画面（N複）
import ViewTableBonusMulti from "@/components/view/table/BonusMulti"; // レベル選択画面（B複）
import ViewLevelConversation from "@/components/view/level/Conversation"; // きく・いう
import ViewLevelNormalSingle1 from "@/components/view/level/NormalSingle1"; // N単-Lv.1
import ViewLevelNormalSingle2 from "@/components/view/level/NormalSingle2"; // N単-Lv.2
import ViewLevelNormalSingle3 from "@/components/view/level/NormalSingle3"; // N単-Lv.3
import ViewLevelNormalSingle4 from "@/components/view/level/NormalSingle4"; // N単-Lv.4
import ViewLevelNormalSingle5 from "@/components/view/level/NormalSingle5"; // N単-Lv.5
import ViewLevelNormalSingle6 from "@/components/view/level/NormalSingle6"; // N単-Lv.6
import ViewLevelNormalMulti1 from "@/components/view/level/NormalMulti1"; // N複-Lv.1
import ViewLevelNormalMulti2 from "@/components/view/level/NormalMulti2"; // N複-Lv.2
import ViewLevelBonusMulti1 from "@/components/view/level/BonusMulti1"; // B複-Lv.1
import ViewLevelBonusMulti2 from "@/components/view/level/BonusMulti2"; // B複-Lv.2
import ViewTimesTable from "@/components/view/TimesTable"; // 九九一覧
import MpLearningStateEditor from "@/components/ui/MpLearningStateEditor.vue";
import MpHistoryLog from "@/components/ui/MpHistoryLog.vue";
import MpCopyright from "@/components/ui/MpCopyright.vue";
import Restriction from "@/mixins/restriction";
import ServiceWorker from "@/mixins/serviceWorker";
import InstallPrompt from "@/components/view/InstallPrompt"; // アプリインストール画面
export default {
  name: "App",
  components: {
    MpOverlay,
    MpMenu,
    MpRestriction,
    MpOrientation,
    MpServiceWorker,
    ViewLoading,
    ViewLogin,
    ViewRestrictionCancel,
    ViewStageNormal,
    ViewStageBonus,
    ViewTableNormalSingle,
    ViewTableNormalMulti,
    ViewTableBonusMulti,
    ViewLevelConversation,
    ViewLevelNormalSingle1,
    ViewLevelNormalSingle2,
    ViewLevelNormalSingle3,
    ViewLevelNormalSingle4,
    ViewLevelNormalSingle5,
    ViewLevelNormalSingle6,
    ViewLevelNormalMulti1,
    ViewLevelNormalMulti2,
    ViewLevelBonusMulti1,
    ViewLevelBonusMulti2,
    ViewTimesTable,
    MpLearningStateEditor,
    MpHistoryLog,
    MpCopyright,
    InstallPrompt,
},
  mixins: [Restriction, ServiceWorker],
  data() {
    return {
      isPortrait: false,
    };
  },
  computed: {
    currentView() {
      return this.$store.getters["app/getCurrentView"];
    },
  },
  watch: {
    currentView: {
      handler() {
        this.$nextTick(() => {
          const container = document.querySelector('#container');
          if (!container) {
            return;
          }
          this.isPortrait = container.clientHeight > container.clientWidth;
        });
      },
    },
  },
  mounted() {
    // アプリケーションバージョン保存
    this.$log.info("アプリケーションバージョン:%s", this.$version);
    this.$log.debug("ログレベル:%s", process.env.VUE_APP_LOG_LEVEL);
    this.$log.debug("モード:%s", process.env.NODE_ENV);
    // サービスワーカー初期化
    if (this.isServiceWorker) this.initServiceWorker();
    // ダブルクリック抑止
    document.addEventListener(
      "dblclick",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
    // 縦横チェックイベント追加
    window.addEventListener("resize", () => {
      this.$log.debug(
        "画面が変更されました=>%d x %d",
        document.documentElement.clientWidth,
        document.documentElement.clientHeight
      );
      // 画面縦横強制チェック
      if (this.$refs.orientation) this.$refs.orientation.validate();
    });
    // 途中でアプリがバックグランドに回ったり端末がスリープ状態になった時は、その時点で制限時間計測をリセットする
    document.addEventListener("visibilitychange", () => {
      this.$log.debug("Document.visibilityState=>%s", document.visibilityState);
      if (
        document.visibilityState === "hidden" &&
        this.$store.getters["auth/isBeforeRestriction"]
      ) {
        // 使用制限初期化
        this.$log.debug("制限時間計測リセット！！");
        this.$store.commit("auth/resetRestrection");
      }
    });
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.$store.commit('app/setDeferredPrompt', e);
    });
    // ローディング画面表示
    this.$store.commit("app/setCurrentView", "ViewLoading");
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/main.scss";
@import "~@/assets/scss/main2.scss";
</style>