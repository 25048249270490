<template>
  <div id="overlay" :class="{ show: show }">
    <slot />
  </div>
</template>

<script>
export default {
  computed: {
    show() {
      return this.$store.getters["app/isOverlay"];
    },
  },
};
</script>