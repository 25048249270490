export default {
    data: function () {
        return {
            sequence: {
                table2: {
                    name: "table2",
                    title: "2",
                    view: "ViewTableNormalSingle",
                    premise: "",
                    times: [2]
                },
                table3: {
                    name: "table3",
                    title: "3",
                    view: "ViewTableNormalSingle",
                    premise: "table2",
                    times: [3]
                },
                table2to3: {
                    name: "table2to3",
                    title: "2〜3",
                    view: "ViewTableNormalMulti",
                    premise: "table3",
                    times: [2, 3]
                },
                table4: {
                    name: "table4",
                    title: "4",
                    view: "ViewTableNormalSingle",
                    premise: "table2to3",
                    times: [4]
                },
                table2to4: {
                    name: "table2to4",
                    title: "2〜4",
                    view: "ViewTableNormalMulti",
                    premise: "table4",
                    times: [2, 3, 4]
                },
                table5: {
                    name: "table5",
                    title: "5",
                    view: "ViewTableNormalSingle",
                    premise: "table2to4",
                    times: [5]
                },
                table2to5: {
                    name: "table2to5",
                    title: "2〜5",
                    view: "ViewTableNormalMulti",
                    premise: "table5",
                    times: [2, 3, 4, 5]
                },
                table6: {
                    name: "table6",
                    title: "6",
                    view: "ViewTableNormalSingle",
                    premise: "table2to5",
                    times: [6]
                },
                table2to6: {
                    name: "table2to6",
                    title: "2〜6",
                    view: "ViewTableNormalMulti",
                    premise: "table6",
                    times: [2, 3, 4, 5, 6]
                },
                table7: {
                    name: "table7",
                    title: "7",
                    view: "ViewTableNormalSingle",
                    premise: "table2to6",
                    times: [7]
                },
                table2to7: {
                    name: "table2to7",
                    title: "2〜7",
                    view: "ViewTableNormalMulti",
                    premise: "table7",
                    times: [2, 3, 4, 5, 6, 7]
                },
                table8: {
                    name: "table8",
                    title: "8",
                    view: "ViewTableNormalSingle",
                    premise: "table2to7",
                    times: [8]
                },
                table2to8: {
                    name: "table2to8",
                    title: "2〜8",
                    view: "ViewTableNormalMulti",
                    premise: "table8",
                    times: [2, 3, 4, 5, 6, 7, 8]
                },
                table9: {
                    name: "table9",
                    title: "9",
                    view: "ViewTableNormalSingle",
                    premise: "table2to8",
                    times: [9]
                },
                table2to9: {
                    name: "table2to9",
                    title: "2〜9",
                    view: "ViewTableNormalMulti",
                    premise: "table9",
                    times: [2, 3, 4, 5, 6, 7, 8, 9]
                }
            }
        };
    },
    computed: {
        currentTable() {
            const nomalStages = Object.entries(this.sequence);
            let step = 0;
            for (let key in nomalStages) {
                let table = nomalStages[key][0];
                if (
                    !this.$store.getters["learningState/isTableClear"]("normal", table)
                ) {
                    break;
                }
                step++;
            }
            return nomalStages[step] ? nomalStages[step][0] : '';
        },
    },
    methods: {
        // 一つ前のテーブル取得
        getPrev(table) {
            var index = Object.keys(this.sequence).indexOf(table);
            if (index == 0) {
                this.$log.debug("一つ前のテーブルなし");
                return null;
            }
            var prev = Object.values(this.sequence)[index - 1];
            this.$log.debug("一つ前のテーブル");
            return prev;
        },
        // 一つ後のテーブル取得
        getNext(table) {
            var index = Object.keys(this.sequence).indexOf(table);
            var max = Object.keys(this.sequence).length;
            if (index >= max - 1) {
                this.$log.debug("一つ後のテーブルなし");
                return null;
            }
            var next = Object.values(this.sequence)[index + 1];
            this.$log.debug("一つ後のテーブル");
            return next;
        },
        // 対象テーブルクリア有無
        isTableClear(table) {
            this.$log.debug("テーブルクリア判定:%s", table);
            return table ? this.$store.getters["learningState/isTableClear"]("normal", table) : true;
        },
    }
}