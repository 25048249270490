<template>
  <div>
    <a class="btn_back" href="#" @click.prevent.stop="doBack">もどる</a>
  </div>
</template>

<script>
export default {
  props: {
    view: {
      type: String,
      required: true,
    },
  },
  methods: {
    doBack() {
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "戻る",
        table: this.$store.getters["app/getCurrentTable"],
      });
      // テーブル選択解除
      this.$store.dispatch("app/deselectTable");
      // ビュー設定
      this.$store.commit("app/setCurrentView", this.view);
    },
  },
};
</script>