<template>
  <div id="progress">
    <div class="progress-bar">
      <div
        class="meter"
        v-for="n of max"
        :key="n"
        :class="{ active: n <= value }"
      >
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    max: {
      type: Number,
      default: 100,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    // プログレス幅
    width: function () {
      var width = Math.floor((100 / this.max) * this.value * 100) / 100;
      if (width > 100) {
        width = 100;
      }
      this.$log.debug("プログレス:%s", width + "%");
      return width + "%";
    },
  },
};
</script>