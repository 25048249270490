<template>
  <div
    class="stage"
    :class="[
      locked ? 'locked' : '',
      current ? 'current' : '',
      isTableClear(table.name) ? 'cleared' : '',
    ]"
  >
    <a
      href="#"
      class="island-img"
      :class="[addtionalIslandClass]"
      @click.stop.prevent="doSelect"
    >
      <img
        :src="$image(`normal/${islandIcon}`)"
        :alt="table.title + 'のだん'"
      />
    </a>
    <a href="#" class="ship-img" @click.stop.prevent="doSelect">
      <img
        :src="$image(`normal/${shipIcon}`)"
        alt="table.title + 'のだん'"
      />
    </a>
    <a href="#" @click.stop.prevent="doSelect">
      <p>{{ table.title }}<span>の</span><span>だん</span></p>
    </a>
  </div>
</template>

<script>
import Normal from "@/mixins/normal";
export default {
  mixins: [Normal],
  props: {
    table: {
      type: Object,
      required: true,
    },
    addtionalIslandClass: {
      type: String,
      required: false,
      default: "",
    },
    islandIcon: {
      type: String,
      required: true,
    },
    shipIcon: {
      type: String,
      required: true,
    },
  },
  computed: {
    locked() {
      return this.table.premise && !this.isTableClear(this.table.premise);
    },
    current() {
      return this.table.name == this.currentTable;
    },
    isIntroduction() {
      return (
        // 2の段の最初のみ案内ガイド開始
        this.table.times == 2 &&
        this.$store.getters["learningState/isIntroduction"]
      );
    },
  },
  methods: {
    // ステージ選択
    doSelect() {
      if (this.locked) return;
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: this.table.title + "のだん選択",
      });
      // テーブル選択
      this.$store.dispatch("app/selectTable", this.table.name);
      // ビュー変更
      this.$store.commit("app/setCurrentView", this.table.view);

      if (!this.isIntroduction) {
        // ２の段の最初でない場合

        // スタートガイド再生
        this.$sound.start(this.table.name).play();
      }
    },
  },
};
</script>