<template>
  <div
    id="container"
    class="question"
    :class="{ 'show-hint': showHint, cleared: complete }"
  >
    <slot />
    <div id="tooltip-box" :class="{ show: showTooltip }">
      <div class="tooltip">
        このボタンをおすと、<br />
        ヒントをみることができるよ。
      </div>
    </div>
    <header>
      <div id="nav">
        <MpFinish :disabled="complete" />
        <MpHamburger />
      </div>
    </header>
    <MpHintBox
      :position="position"
      :multiplicand="multiplicand"
      :multiplier="multiplier"
      @hintFinish="doHintFinish"
    />
    <main>
      <div id="message">
        <div class="message-inner"></div>
      </div>
      <div id="content">
        <div id="question">
          <div id="controls">
            <div id="time"></div>
            <MpHintTimestable
              ref="hint"
              :position="position"
              :multiplicand="multiplicand"
              :multiplier="multiplier"
              :disabled="showTooltip"
              :disabledClass="complete || isPlayQuestion"
              :pickup="showTooltip"
              @hintStart="doHintStart"
              :complete="complete"
            />
          </div>
          <MpExpression ref="expression" @marking="onMarking" />
          <MpProgress :max="questionCount" :value="answered" />
        </div>
        <MpCalculator
          @enterKey="enterExpression"
          v-if="!complete && !showHint"
        />
      </div>
      <div id="welldone">
        <img :src="$image('welldone.svg')" />
      </div>
      <MpCompletion v-if="complete" />
    </main>
  </div>
</template>

<script>
import MpHamburger from "@/components/ui/MpHamburger";
import MpFinish from "@/components/ui/MpFinish";
import MpHintTimestable from "@/components/ui/MpHintTimestable";
import MpHintBox from "@/components/ui/MpHintBox";
import MpExpression from "@/components/ui/MpExpression";
import MpCalculator from "@/components/ui/MpCalculator";
import MpProgress from "@/components/ui/MpProgress";
import MpCompletion from "@/components/ui/MpCompletion.vue";
import Question from "@/mixins/question";
import Restriction from "@/mixins/restriction";
export default {
  components: {
    MpHamburger,
    MpFinish,
    MpHintTimestable,
    MpHintBox,
    MpExpression,
    MpCalculator,
    MpProgress,
    MpCompletion,
  },
  mixins: [Question, Restriction],
  data: function () {
    return {
      showTooltip: false, // ツールチップ表示有無（true:表示、false:非表示）
      guideInterval: 0, // ガイドインターバル（ミリ秒）
      showHint: false, // ヒント表示有無（true:表示、false:非表示）
      isHint: false, // ヒントを利用したかどうか（true:利用した、false:利用していない）
      isWrong: false, // 誤答があるかどうか（true:ある、false:ない）
      isPlayQuestion: false, // 問題読み上げ中かどうか（true:読み上げ中、false:読み上げ中でない）
    };
  },
  watch: {
    showTooltip: function (val) {
      if (val) {
        // オーバーレイ表示
        this.$store.commit("app/setOverlay", true);
        // ヒントガイド音声再生
        this.$sound.guide("ns_level2").play(() => {
          this.$timer.once(() => {
            // ツールチップ非表示
            this.showTooltip = false;
            // 最初の問題出題タイミング
            this.$store.dispatch("history/startQuestion", {
              loginId: this.loginId,
              content: this.content,
              position: this.position,
              multiplicand: this.multiplicand,
              multiplier: this.multiplier,
            });
            // 問題読み上げ
            this.playQuestion();
          }, this.guideInterval);
        });
      } else {
        // オーバーレイ非表示
        this.$store.commit("app/setOverlay", false);
      }
    },
  },
  mounted() {
    // 使用制限抑止
    this.disableRestriction();
    // 問題開始
    this.startExpression();
    // ツールチップ開始
    this.showTooltip = true;
  },
  destroyed() {
    // 使用制限抑止解除
    this.enableRestriction();
  },
  methods: {
    // 採点時
    onMarking(expression) {
      // 結果判定
      if (expression.result) {
        // 正解の場合

        // ヒントタイマー停止
        this.$refs.hint.stopTimer(this.position);

        if (this.isWrong || this.isHint) {
          // 誤答後やヒントを見た（聞いた）後に正答を入力した場合

          // 答え音声再生
          this.$sound
            .multiplication(this.multiplicand, this.multiplier)
            .play(() => {
              // 採点結果判定共通処理
              this.judgementExpression(expression);
              // 式正解
              this.$refs.expression.correct();
            });
        } else {
          // 採点結果判定共通処理
          this.judgementExpression(expression);
          // 式正解
          this.$refs.expression.correct();
        }
      } else {
        // 不正解の場合
        this.isWrong = true;
        // 採点結果判定共通処理
        this.judgementExpression(expression);
        // 式不正解
        this.$refs.expression.wrong();
      }
    },
    // 次問題出題
    onNext() {
      // 誤答あるかどうかリセット
      this.isWrong = false;
      // ヒントを利用したかどうかリセット
      this.isHint = false;
      // 問題読み上げ
      this.playQuestion();
    },
    // 問題読み上げ
    playQuestion() {
      if (!this.complete) {
        this.$log.debug(
          "問題読み上げ:%dx%d",
          this.multiplicand,
          this.multiplier
        );
        this.isPlayQuestion = true;
        this.$sound.question(this.multiplicand, this.multiplier).play(() => {
          this.isPlayQuestion = false;
          // ヒントタイマー開始
          if (this.$refs.hint) this.$refs.hint.startTimer(this.position);
        });
      }
    },
    // ヒント開いた時
    doHintStart() {
      this.$log.debug("ヒント開始");
      this.showHint = true;
      this.isHint = true;
    },
    // ヒント閉じた時
    doHintFinish() {
      this.$log.debug("ヒント終了");
      this.showHint = false;
    },
  },
};
</script>