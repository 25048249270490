export default {
    data: function () {
        return {
            weaknessJudgementSec: 5, // 苦手な九九判定基準時間（秒）
            weaknessResults: [], // 苦手な九九を判定結果リスト      
        };
    },
    computed: {
        // 苦手な九九が存在するかどうか（true:存在する、false:存在しない）
        isWeakness: function () {
            return this.weaknessResults && this.weaknessResults.length > 0;
        },
    },
    methods: {
        // 苦手な九九を判定します
        judgementWeakness(results) {
            this.$log.debug("苦手な九九の判定:%s", JSON.stringify(results));
            this.weaknessResults = [];
            if (!results || results.length == 0) return;

            // ①まずは、被乗数、乗数ごとにソート
            results.sort((a, b) => {
                if (a.multiplicand < b.multiplicand) return -1;
                if (a.multiplicand > b.multiplicand) return 1;
                if (a.multiplier < b.multiplier) return -1;
                if (a.multiplier > b.multiplier) return 1;
                return 0;
            });
            this.$log.debug("①被乗数、乗数ごとのソート:%s", JSON.stringify(results));

            // ②同じ問題が２問以上出ている場合は、平均時間を求めておく
            var multiplicand = 0;
            var multiplier = 0;
            var sumTimes = 0;
            var count = 0;
            var mergedResults = [];
            for (var result of results) {
                if (count > 0) {
                    if (result.multiplicand != multiplicand || result.multiplier != multiplier) {
                        mergedResults.push({ multiplicand: multiplicand, multiplier: multiplier, instrumentTime: (sumTimes / count) });
                        sumTimes = 0;
                        count = 0;
                    }
                }
                multiplicand = result.multiplicand;
                multiplier = result.multiplier;
                sumTimes += result.instrumentTime;
                count++;
            }
            mergedResults.push({ multiplicand: multiplicand, multiplier: multiplier, instrumentTime: (sumTimes / count) });
            this.$log.debug("②平均時間計算後の配列:%s", JSON.stringify(mergedResults));

            // ③nullおよび乗数が0は判断基準から除外
            mergedResults = mergedResults.filter((result) => result && result.multiplier > 0);

            // ④苦手な九九判定基準時間（秒）以上の問題のみ抽出
            this.weaknessResults = mergedResults.filter(
                (result) => result.instrumentTime >= this.weaknessJudgementSec
            );
            this.$log.debug("苦手な九九の配列:%s", JSON.stringify(this.weaknessResults));

            // ⑤計測時間降順でソートする
            this.weaknessResults.sort((a, b) => {
                if (a.instrumentTime > b.instrumentTime) return -1;
                if (a.instrumentTime < b.instrumentTime) return 1;
                // 以下、同じ秒数の場合、乗数の大きい問題優先
                if (a.multiplier > b.multiplier) return -1;
                if (a.multiplier < b.multiplier) return 1;
                // 以下、同じ秒数と同じ乗数の場合、被乗数の大きい問題優先
                if (a.multiplicand > b.multiplicand) return -1;
                if (a.multiplicand < b.multiplicand) return 1;
                return 0;
            });
            this.$log.debug(
                "苦手な九九のソート後:%s",
                JSON.stringify(this.weaknessResults)
            );
        },
    }
}