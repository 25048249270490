
function getDefaultState() {
    return {
        table2: [],
        table3: [],
        table4: [],
        table5: [],
        table6: [],
        table7: [],
        table8: [],
        table9: [],
    }
}

const state = () => (getDefaultState());

const getters = {
    // 九九テーブルに録音データが存在するかどうか（true:する、false:しない）
    exists: state => table => {
        return state[table] && state[table].length > 0 ? true : false;
    },
    // 録音データを取得
    getAudioData: state => table => {
        return state[table] ? state[table] : [];
    }
}

const mutations = {
    // 九九テーブルに録音データを登録します
    save(state, { table, audioData }) {
        state[table] = audioData;
    },
    // 録音データを初期化します
    init(state) {
        Object.assign(state, getDefaultState());
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}