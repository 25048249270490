<template>
  <div id="hint">
    <button
      type="button"
      class="btn-speaker"
      @click="doHint"
      :class="{
        disabled: playHint || isProcessing || disabledClass,
        pickup: pickup,
      }"
      :disabled="disabled || playHint"
    >
      <div class="suggest" v-if="!complete && showHintHand"></div>
    </button>
  </div>
</template>

<script>
const MP_HINT_LISTENING_HINT_STAR_EVENT = "hintStart"; // ヒントが開始される直前のイベント
const MP_HINT_LISTENING_HINT_FINISH_EVENT = "hintFinish"; // ヒントが終了した直後のイベント
export default {
  props: {
    // 問題番号
    position: {
      type: Number,
      required: true,
    },
    // 再生するヒントの被乗数
    multiplicand: {
      type: Number,
      required: true,
    },
    // 再生するヒントの乗数
    multiplier: {
      type: Number,
      required: true,
    },
    // ヒント無効フラグ（true:無効、false:有効）
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    // ヒント無効クラス（true:有効、false:無効）
    disabledClass: {
      type: Boolean,
      required: false,
      default: false,
    },
    // ボタンピックアップ
    pickup: {
      type: Boolean,
      required: false,
      default: false,
    },
    // コンテンツ終了フラグ
    complete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      playHint: false, // ヒント再生中フラグ（true:表示中、false:非表示）
      showHintHand: false, // ヒントハンド表示有無（true:表示、false:非表示）
      showHintHandInterval: 15000, // ヒントハンド表示時間（ミリ秒）
      hintHandTimer: [],
      hintCount: 0, // ヒント使用数
    };
  },
  computed: {
    loginId: function () {
      return this.$store.getters["auth/getLoginId"];
    },
    content: function () {
      return this.$store.getters["app/getCurrentContent"];
    },
    isProcessing() {
      return this.$store.getters["app/isProcessing"];
    },
  },
  watch: {
    showHintHand: function (val) {
      if (val) {
        // 学習履歴ヒント手印表示
        this.$store.dispatch("history/hintHand", {
          loginId: this.loginId,
          content: this.content,
          position: this.position,
          multiplicand: this.multiplicand,
          multiplier: this.multiplier,
        });
      }
    },
  },
  destroyed() {
    // ヒントタイマー全停止
    this.$log.debug("ヒントハンドタイマー全停止開始");
    for (let position in this.hintHandTimer) {
      if (this.hintHandTimer[position] === -1) {
        continue;
      }
      this.$log.debug("ヒントハンドタイマー停止(%d)", position);
      this.hintHandTimer[position].exit();
    }
  },
  methods: {
    // ヒント実行
    doHint() {
      this.$log.debug("ヒント実行開始");
      // ヒントハンドタイマーキャンセル
      this.stopTimer(this.position);
      // ヒント使用数
      this.hintCount++;
      // 学習履歴ヒント開始
      this.$store.dispatch("history/hintStart", {
        loginId: this.loginId,
        content: this.content,
        position: this.position,
        multiplicand: this.multiplicand,
        multiplier: this.multiplier,
        hintCount: this.hintCount,
      });
      // ヒント開始イベント
      this.$emit(MP_HINT_LISTENING_HINT_STAR_EVENT);
      // ハンドマーク非表示
      this.showHintHand = false;
      // ヒント開始
      this.playHint = true;
      // 九九再生
      this.$sound.timestable(this.multiplicand, this.multiplier).play(
        () => {},
        () => {
          // ヒント終了
          this.playHint = false;
          // ヒント終了イベント
          this.$emit(MP_HINT_LISTENING_HINT_FINISH_EVENT);
        }
      );
    },
    // タイマー開始
    startTimer(position) {
      this.$log.debug("ヒントハンドタイマー開始(%d)", position);
      if (position in this.hintHandTimer) {
        // 既に起動済み
        this.$log.debug("ヒントハンドタイマー起動済み(%d)", position);
        return;
      }
      this.hintHandTimer[position] = this.$timer.once(() => {
        // ヒントハンド表示時間経過後にハンドアイコン表示
        this.$log.debug("ヒント表示(%d)", position);
        if (!this.showHint) this.showHintHand = true;
      }, this.showHintHandInterval);
    },
    // タイマー終了
    stopTimer(position) {
      if (position in this.hintHandTimer) {
        if (this.hintHandTimer[position] !== -1) {
          this.$log.debug("ヒントハンドタイマー終了(%d)", position);
          this.hintHandTimer[position].exit();
          this.hintHandTimer[position] = -1;
        } else {
          this.$log.debug("ヒントハンドタイマー終了済み(%d)", position);
        }
      } else {
        this.$log.debug("ヒントハンドタイマー抑止(%d)", position);
        this.hintHandTimer[position] = -1;
      }
      // ヒントハンド非表示
      this.showHintHand = false;
    },
    // リセット
    reset() {
      this.hintUsed = this.hintLimit;
    },
    // 停止
    stopPlayingHint() {
      this.playHint = false;
    },
  },
};
</script>