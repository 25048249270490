<template>
  <MpModal class="modal-tool" v-if="isShow">
    <div class="history-log">
      <p>学習履歴確認</p>
      <button @click="doClear">クリア</button>
      <button @click="doClose">閉じる</button>
      <div class="history">
        <ul>
          <li v-for="(log, index) in logs" :key="index">{{ log }}</li>
        </ul>
      </div>
    </div>
  </MpModal>
</template>

<script>
import MpModal from "@/components/ui/MpModal.vue";
export default {
  components: {
    MpModal,
  },
  data: function () {
    return {
      logs: [],
    };
  },
  computed: {
    isShow: function () {
      return this.$store.getters["app/isShowHistoryLog"];
    },
  },
  mounted() {
    this.logs = this.$store.getters["history/getHistory"];
  },
  methods: {
    doClear() {
      this.$store.commit("history/clearHistory");
    },
    doClose() {
      this.$store.commit("app/setShowHistoryLog", false);
    },
  },
};
</script>