import { AES, enc } from 'crypto-js';

import moment from 'moment';

const state = () => ({
    store: 'ready',
    c: "", // 暗号化された認証情報
    access_token: "", // アクセストークン
    loginDateTime: "", // ログイン日時
    serviceWorkerReady: false, // サービスワーカー準備
    serviceWorkerUpdate: '',   // サービスワーカー更新状態（updatefound:更新あり、cached:キャッシュ済み、updated:更新済み）
    restriction: {  // 使用制限            
        startDateTime: '',   // 使用制限開始日時（年月日・時分秒）
        accumulatedMinute: 0, // 累積時間（分）
        disable: false, // 使用制限無効フラグ（true:使用制限無効、false:使用制限有効）
        timerStartDate: '', // タイマー開始日
    },
    isUnrestricted: 0,
});

const getters = {
    // ログインが有効かチェック
    isLoginValid: state => {
        return state.c
            && state.loginDateTime
            && moment().subtract(process.env.VUE_APP_LOGIN_VALIDATE_TERM, 'm').isSameOrBefore(state.loginDateTime);
    },
    // ログインID取得
    getLoginId: (_, { getCredentials }) => {
        const credentials = getCredentials;

        return credentials ? credentials.login_id : "";
    },
    // パスワード取得
    getPassword: (_, { getCredentials }) => {
        const credentials = getCredentials;

        return credentials ? credentials.password : "";
    },
    // 認証情報取得
    getCredentials: state => {
        const encryptedCredentials = state.c;
        if (!encryptedCredentials) {
            return null;
        }

        const key = process.env.VUE_APP_CRYPTO_KEY;
        const credentials = JSON.parse(AES.decrypt(encryptedCredentials, key).toString(enc.Utf8));

        return credentials;
    },
    // アクセストークン取得
    getAccessToken: state => {
        return state.access_token;
    },
    // サービスワーカー準備取得
    isServiceWorkerReady: state => {
        return state.serviceWorkerReady;
    },
    // サービスワーカー更新状態取得
    getServiceWorkerUpdate: state => {
        return state.serviceWorkerUpdate;
    },
    // ストア準備状態
    isReady: state => {
        return state.store === "ready";
    },
    // 使用制限開始日時
    getRestrictionStartDateTime: state => {
        return state.restriction.startDateTime;
    },
    // 使用制限累積時間（ふん）
    getRestrictionAccumulatedMinute: state => {
        return state.restriction.accumulatedMinute;
    },
    // 使用制限中かどうか（true:使用制限中、false:使用制限中でない）
    isRestriction: state => {
        return !state.restriction.disable
            && state.restriction.startDateTime
            && moment(new Date()).isBefore(moment(state.restriction.startDateTime).add(process.env.VUE_APP_RESTRICTION_TERM, 'm'));
    },
    // 使用制限前かどうか（true:使用制限前、false:使用制限中）
    isBeforeRestriction: state => {
        return !state.restriction.startDateTime;
    },
    // 使用制限期限切れかどうか（true:期限切れ、false:有効）
    isRestrictionExpired: state => {
        return !moment(state.restriction.timerStartDate).isSame(new Date(), 'day');
    },
    getIsUnrestricted: state => {
        return (state.isUnrestricted === 1);
    }
}

const mutations = {
    setCredentials(state, credentials) {
        const source = JSON.stringify(credentials);
        const key = process.env.VUE_APP_CRYPTO_KEY;
        const encryptedCredentials = AES.encrypt(source, key).toString();

        state.c = encryptedCredentials;
    },
    setAccessToken(state, access_token) {
        state.access_token = access_token;
    },
    setLoginDateTime(state, loginDatetime) {
        state.loginDateTime = moment(loginDatetime).format('YYYY-MM-DD HH:mm:ss');
    },
    resetCredentials(state) {
        state.c = "";
    },
    resetLoginDateTime(state) {
        state.loginDateTime = "";
    },
    setServiceWorkerReady(state, value) {
        state.serviceWorkerReady = value;
    },
    setServiceWorkerUpdate(state, value) {
        state.serviceWorkerUpdate = value;
    },
    // 使用制限初期化
    resetRestrection(state) {
        state.restriction.startDateTime = '';
        state.restriction.accumulatedMinute = 0;
        state.restriction.timerStartDate = '';
    },
    // 累積使用時間加算
    inclementRestrectionAccumulatedMinute(state) {
        state.restriction.accumulatedMinute += 1;
    },
    // 使用制限開始
    startRestriction(state) {
        state.restriction.startDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    },
    // 使用制限抑止フラグ
    setRestrictionDisable(state, value) {
        state.restriction.disable = value;
        if (!value && state.restriction.startDateTime) {
            // 抑止解除で既に強制停止が開始されていた場合、再度強制停止開始時間を設定しなおす
            state.restriction.startDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        }
    },
    // 使用制限タイマー開始日設定
    setRestrictionTimerStartDate(state) {
        state.restriction.timerStartDate = moment(new Date()).format("YYYY-MM-DD")
    },
    setIsUnrestricted(state, isUnrestricted) {
        state.isUnrestricted = isUnrestricted;
    }
}

const actions = {
    // ログイン認証
    async login(context, credentials) {
        this.$log.debug("ログイン認証開始");
        const url = process.env.VUE_APP_API_PATH_AUTH_LOGIN;
        const rnd = Math.round(Math.random() * 1000000000);
        this.$log.debug("認証認証送信=>%s", url);
        await this.$axios.post(`${url}?rnd=${rnd}`, credentials).then((response) => {
            this.$log.debug("送信成功");
            let token = response.data.access_token;
            let isUnrestricted = Number(response.data['is_unrestricted']);
            this.$log.debug("認証トークン=>%s", token);
            this.$axios.defaults.headers.Authorization = 'Bearer ' + token;
            context.commit("setAccessToken", token);
            context.commit("setIsUnrestricted", isUnrestricted);

            this.$log.debug("ログイン認証成功");
        }).catch((e) => {
            this.$log.error("ログイン認証失敗=>%s", e);
            throw e;
        });
    },
    // トークンリフレッシュ
    async refresh(context) {
        this.$log.debug("トークンリフレッシュ開始");
        var token = context.getters["getAccessToken"];
        this.$log.debug("アクセストークン=>%s", token);
        this.$axios.defaults.headers.Authorization = 'Bearer ' + token;
        const url = process.env.VUE_APP_API_PATH_AUTH_REFRESH;
        this.$log.debug("トークンリフレッシュ送信=>%s", url);
        await this.$axios.post(url).then((response) => {
            this.$log.debug("送信成功");
            token = response.data.access_token;
            this.$log.debug("リフレッシュトークン=>%s", token);
            this.$axios.defaults.headers.Authorization = 'Bearer ' + token;
            context.commit("setAccessToken", token);
            this.$log.debug("トークンリフレッシュ成功");
        }).catch((e) => {
            this.$log.error("トークンリフレッシュ失敗=>%s", e);
            throw e;
        });
    },
    // ログアウト
    async logout(context) {
        this.$log.debug("ログアウト");
        const url = process.env.VUE_APP_API_PATH_AUTH_LOGOUT;
        this.$log.debug("ログアウト送信=>%s", url);
        await this.$axios.post(url).then(() => {
            this.$log.debug("送信成功");
            context.commit("setAccessToken", "");
            this.$log.debug("ログアウト成功");
        }).catch((e) => {
            this.$log.error("ログアウト失敗=>%s", e);
            context.commit("setAccessToken", "");
            throw e;
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}