<template>
<img class="copyright" :class="{ 'portrait': isPortrait }" :src="$image('copyright.svg')" alt="">
</template>

<script>
export default {
  props: {
    isPortrait: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.copyright {
  width: 25%;
  position: absolute;
  top: 10px;
  left: 15px;
  background-color: #ffffff;
  padding: 0px 5px;

  &.portrait {
    width: 50%;
  }
}
</style>