<template>
  <div
    id="hbg"
    :class="{ restriction: isRestriction && !visibleOrientationGide }"
  >
    <div @click.stop.prevent="open">
      <img :src="$image('hbg.svg')" alt="メニューを開く" />
    </div>
  </div>
</template>

<script>
import Restriction from "@/mixins/restriction";
export default {
  mixins: [Restriction],
  computed: {
    visibleOrientationGide() {
      return this.$store.getters["app/visibleOrientationGide"];
    },
  },
  methods: {
    open() {
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "ハンバーガーメニュー開く",
        table: this.$store.getters["app/getCurrentTable"],
      });
      // メニューオープン
      this.$store.dispatch("app/openMenu");
    },
  },
};
</script>