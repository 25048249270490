<template>
  <div id="container" class="single">
    <slot name="simple"></slot>
    <header>
      <h1>さんすう (九九)</h1>
      <h2>使用制限解除画面</h2>
    </header>
    <main>
      <ValidationObserver
        ref="validator"
        v-slot="{ /* eslint-disable */ errors /* eslint-enable */ }"
      >
        <form @submit.prevent="doCancel">
          <fieldset>
            <label for="password">パスワード</label>
            <ValidationProvider
              vid="password"
              name="パスワード"
              rules="required"
            >
              <input
                id="password"
                name="password"
                type="password"
                maxlength="100"
                autocomplete="off"
                v-model="password"
                rules="required|max:100"
                @input="reset"
              />
            </ValidationProvider>
          </fieldset>
          <p class="message">{{ errorMessage ? errorMessage : "&nbsp;" }}</p>
          <button type="submit">解除</button>
        </form>
      </ValidationObserver>
    </main>
  </div>
</template>

<script>
import Restriction from "@/mixins/restriction";
export default {
  mixins: [Restriction],
  data: function () {
    return {
      password: "",
      errorMessage: "",
      hasError: false,
    };
  },
  computed: {
    errors() {
      return this.$refs.validator.errors;
    },
    isInitializeSkip() {
      return process.env.VUE_APP_SKIP_INITIALIZE.toUpperCase() === "ON";
    },
  },
  created() {
    // 横レイアウト強制
    this.$log.debug("横レイアウト強制");
    this.$store.commit("app/enforceOrientationLandscape");
  },
  methods: {
    doCancel() {
      if (this.isInitializeSkip) {
        // 初期化スキップ時

        // 使用制限解除
        this.restrictionCancel();
        return;
      }

      if (this.hasError) return;

      // バリデーション実行
      this.$log.debug("バリデーション実行！！");
      this.$refs.validator.validate().then((success) => {
        this.$log.debug("バリデーション結果=>%s", success);
        if (success) {
          if (this.password === this.$store.getters["auth/getPassword"]) {
            // 使用制限解除
            this.restrictionCancel();
          } else {
            this.errorMessage = "パスワードが違います";
            this.hasError = true;
          }
        }

        // バリデーションエラーが存在する場合
        if (this.errors.password.length > 0) {
          this.errorMessage = this.errors.password[0];
        }
        this.hasError = true;
      });
    },
    reset() {
      this.hasError = false;
      this.errorMessage = "";
    },
    restrictionCancel() {
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "解除",
      });
      // 使用制限初期化
      this.$store.commit("auth/resetRestrection");
      // アプリビュー変更（制限前の画面に戻る）
      var restrectedView = this.$store.getters["app/getRestrictedView"];
      this.$store.commit("app/setCurrentViewWithoutHistory", restrectedView);
      // 使用制限タイマー開始
      this.startRestrictionTimer();
    },
  },
};
</script>