export default {
    methods: {
        isOnline() {
            this.$log.debug("オンライン・オフラインチェック");
            if (navigator.onLine) {
                this.$log.debug("== オンライン ==");
                return true;
            } else {
                this.$log.debug("== オフライン ==");
                return false;
            }
        },
        activitySendLog() {
            this.$log.debug("【ログ送信】開始")
            if (!this.isOnline()) return;
            // トークンリフレッシュ
            this.$log.debug("【ログ送信】トークンリフレッシュ");
            this.$store
                .dispatch("auth/refresh")
                .then(() => {
                    this.$log.debug("【ログ送信】リフレッシュ成功");
                    // ユーザ学習状況送信
                    this.$log.debug("【ログ送信】学習状況送信");
                    this.$store.dispatch("learningState/post");
                    // 学習履歴送信
                    this.$log.debug("【ログ送信】学習履歴送信");
                    this.$store.dispatch("history/post");
                })
                .catch(() => {
                    // NGまたはオフライン
                    this.$log.debug("【ログ送信】トークンリフレッシュ失敗");
                });
        }
    }
}