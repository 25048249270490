<template>
  <div id="hint">
    <button
      v-if="hintLimit == 0"
      id="hintButton"
      type="button"
      class="btn-light"
      @click="doHint(false)"
      :class="{
        disabled: showHint || isProcessing || disabledClass || hintClosing,
        suggest: !complete && showHintHand,
        pickup: pickup,
      }"
      :disabled="disabled"
    ></button>
    <div id="hint-limited" v-if="hintLimit > 0">
      <button
        type="button"
        class="btn-light"
        @click="doLimitedHint"
        :class="{
          disabled:
            showHint ||
            isProcessing ||
            disabledClass ||
            hintClosing ||
            hintUsed <= 0,
          suggest: !complete && showHintHand,
          pickup: pickup,
        }"
        :disabled="
          disabled || showHint || isProcessing || hintClosing || hintUsed <= 0
        "
      >
        &nbsp;
      </button>
      <span v-for="n in hintLimit" :key="n" :class="{ used: n > hintUsed }"
        ><img :src="$image('question/hintlimit.svg')" alt="ヒント"
      /></span>
    </div>
  </div>
</template>

<script>
const MP_HINT_TIMESTABLE_HINT_STAR_EVENT = "hintStart"; // ヒントが開始される直前のイベント
export default {
  props: {
    // 問題番号
    position: {
      type: Number,
      required: true,
    },
    // 被乗数
    multiplicand: {
      type: Number,
      require: true,
    },
    // 乗数
    multiplier: {
      type: Number,
      require: true,
    },
    // ヒント無効フラグ（true:無効、false:有効）
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    // ヒント無効クラス（true:有効、false:無効）
    disabledClass: {
      type: Boolean,
      required: false,
      default: false,
    },
    // ヒント回数制限（0:無制限）
    hintLimit: {
      type: Number,
      required: false,
      default: 0,
    },
    // ボタンピックアップ
    pickup: {
      type: Boolean,
      required: false,
      default: false,
    },
    // コンテンツ終了フラグ
    complete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      showHintHand: false, // ヒントハンド表示有無（true:表示、false:非表示）
      showHintHandInterval: 15000, // ヒントハンド表示時間（ミリ秒）
      hintHandTimer: [],
      hintUsed: this.hintLimit, // 使用可能ヒント数
      hintCount: 0, // ヒント使用数
    };
  },
  computed: {
    showHint: function () {
      return this.$store.getters["app/isShowHint"];
    },
    hintClosing: function () {
      return this.$store.getters["app/isHintClosing"];
    },
    loginId: function () {
      return this.$store.getters["auth/getLoginId"];
    },
    content: function () {
      return this.$store.getters["app/getCurrentContent"];
    },
    isProcessing() {
      return this.$store.getters["app/isProcessing"];
    },
  },
  watch: {
    showHintHand: function (val) {
      if (val) {
        // 学習履歴ヒント手印表示
        this.$store.dispatch("history/hintHand", {
          loginId: this.loginId,
          content: this.content,
          position: this.position,
          multiplicand: this.multiplicand,
          multiplier: this.multiplier,
        });
      }
    },
  },
  destroyed() {
    // ヒントタイマー全停止
    this.$log.debug("ヒントハンドタイマー全停止開始");
    for (let position in this.hintHandTimer) {
      if (this.hintHandTimer[position] === -1) {
        continue;
      }
      this.$log.debug("ヒントハンドタイマー停止(%d)", position);
      this.hintHandTimer[position].exit();
    }
  },
  methods: {
    // ヒント実行
    doHint(isAuto = false) {
      // ヒントハンドタイマーキャンセル
      this.stopTimer(this.position);
      // ヒント閉じるリセット
      this.$store.commit("app/setHintClosing", false);
      if (isAuto) {
        this.$log.debug("自動ヒント実行開始");
      } else {
        this.$log.debug("ヒント実行開始");
        // ヒント使用数
        this.hintCount++;
      }
      // 学習履歴ヒント開始
      this.$store.dispatch("history/hintStart", {
        loginId: this.loginId,
        content: this.content,
        position: this.position,
        multiplicand: this.multiplicand,
        multiplier: this.multiplier,
        hintCount: this.hintCount,
        isAuto: isAuto,
      });
      // ヒント開始イベント
      this.$emit(MP_HINT_TIMESTABLE_HINT_STAR_EVENT, isAuto);
      // ハンドマーク非表示
      this.showHintHand = false;
      // ヒント表示
      this.$store.commit("app/setShowHint", true);
    },
    // 制限付きヒント実行
    doLimitedHint() {
      this.$log.debug("制限付きヒント実行開始");
      // ヒント使用回数減
      this.hintUsed--;
      this.$log.debug("ヒント使用回数残り:%d", this.hintUsed);
      // ヒント実行
      this.doHint();
    },
    // タイマー開始
    startTimer(position) {
      this.$log.debug("ヒントハンドタイマー開始(%d)", position);
      if (position in this.hintHandTimer) {
        // 既に起動済み
        this.$log.debug("ヒントハンドタイマー起動済み(%d)", position);
        return;
      }
      this.hintHandTimer[position] = this.$timer.once(() => {
        // ヒントハンド表示時間経過後にハンドアイコン表示
        this.$log.debug("ヒント表示(%d)", position);
        if (!this.showHint) this.showHintHand = true;
      }, this.showHintHandInterval);
    },
    // タイマー終了
    stopTimer(position) {
      if (position in this.hintHandTimer) {
        if (this.hintHandTimer[position] !== -1) {
          this.$log.debug("ヒントハンドタイマー終了(%d)", position);
          this.hintHandTimer[position].exit();
          this.hintHandTimer[position] = -1;
        } else {
          this.$log.debug("ヒントハンドタイマー終了済み(%d)", position);
        }
      } else {
        this.$log.debug("ヒントハンドタイマー抑止(%d)", position);
        this.hintHandTimer[position] = -1;
      }
      // ヒントハンド非表示
      this.showHintHand = false;
    },
    // リセット
    reset() {
      this.hintUsed = this.hintLimit;
      this.hintCount = 0;
    },
  },
};
</script>