<template>
  <div
    id="modal-box"
    :class="{
      show:
        isShowFirstGuide ||
        isShowWeaknessGuide ||
        isShowCompletionGuide1 ||
        isShowCompletionGuide2 ||
        isShowCompletionGuide3 ||
        isShowFinalGuide,
    }"
  >
    <MpModal
      id="first-message"
      class="modal-inner"
      :class="{ show: isShowFirstGuide }"
      v-if="isShowFirstGuide"
      @destroyed="onFirstGuideDestroyed"
    >
      <div class="modal-message-box">
        <slot name="firstGuide"></slot>
      </div>
      <div class="modal-btn-box">
        <div>
          <button
            type="button"
            class="btn-forward"
            @click="doNext"
          >
            すすむ
          </button>
        </div>
      </div>
    </MpModal>
    <MpModal
      id="listen-message"
      class="modal-inner"
      :class="{ show: isShowWeaknessGuide }"
      v-if="isShowWeaknessGuide"
      @destroyed="onWeaknessGuideDestroyed"
    >
      <div class="modal-message-box">
        <div class="message">さいしょに&nbsp;きいてみよう！</div>
        <MpWeakness
          ref="weaknessFirst"
          :results="weaknessResults"
          @mounted="onWeaknessMounted"
          @listenAll="onWeaknessListenAll"
          :showAnswer="false"
        />
      </div>
      <div class="modal-btn-box">
        <div>
          <button
            type="button"
            class="btn-forward"
            @click="doStart"
            :class="{ disabled: !isWeaknessListenAll }"
          >
            スタート
          </button>
        </div>
      </div>
    </MpModal>
    <MpModal
      id="result-message"
      class="modal-inner"
      :class="[
        resultMessageClass,
        {
          show:
            isShowCompletionGuide1 ||
            isShowCompletionGuide2 ||
            isShowCompletionGuide3,
          'stage-clear': isBackgoudClear & isContetClear,
        },
      ]"
      v-if="
        isShowCompletionGuide1 ||
        isShowCompletionGuide2 ||
        isShowCompletionGuide3
      "
    >
      <div class="modal-message-box">
        <div
          id="finished-complete"
          class="result-box"
          :class="{ show: isShowCompletionGuide1 }"
        >
          <div class="result-img">
            <img
              :src="$image('question/congratulations.svg')"
              alt="おめでとう！"
            />
          </div>
          <div class="result-message-box">
            <slot name="completionGuide1"></slot>
          </div>
        </div>
        <div
          id="finished-good"
          class="result-box"
          :class="{ show: isShowCompletionGuide2 }"
        >
          <div class="result-img">
            <img
              :src="$image('question/goodplay.svg')"
              alt="はやくできたね！"
            />
          </div>
          <div class="result-message-box">
            <slot name="completionGuide2"></slot>
            <div class="retry">
              <button
                type="button"
                class="btn-forward"
                @click="doRetry"
                :class="{
                  disabled: isWeakness && !isWeaknessListenAll,
                }"
              >
                もう１かい
              </button>
            </div>
          </div>
        </div>
        <div
          id="finished-played"
          class="result-box"
          :class="{ show: isShowCompletionGuide3 }"
        >
          <div class="result-img">
            <img
              :src="$image('question/goodplay.svg')"
              alt="がんばったね！"
            />
          </div>
          <div class="result-message-box">
            <slot name="completionGuide3"></slot>
            <div class="retry">
              <button
                type="button"
                class="btn-forward"
                @click="doRetry"
                :class="{
                  disabled: isWeakness && !isWeaknessListenAll,
                }"
              >
                もう１かい
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-listen-box" v-if="isWeakness">
        <div id="listen-repeat">
          <div class="message">さいごに&nbsp;きいてみよう！</div>
          <MpWeakness
            ref="weaknessLast"
            :results="weaknessResults"
            @mounted="onWeaknessMounted"
            @listenAll="onWeaknessListenAll"
            @listenStart="onWeaknessListenStart"
            @listenFinish="onWeaknessListenFinish"
          />
        </div>
      </div>
      <div id="finish-modal">
        <a
          href="#"
          @click.stop.prevent="doFinish"
          :class="{
            disabled:
              ((isShowCompletionGuide2 || isShowCompletionGuide3) &&
                isWeakness &&
                !isWeaknessListenAll) ||
              ((isShowCompletionGuide2 || isShowCompletionGuide3) &&
                isWeakness &&
                isWeaknessListenAll &&
                isWeaknessListen) ||
              (isShowCompletionGuide1 && isWeakness && isWeaknessListen),
          }"
        >
          おわる
        </a>
      </div>
    </MpModal>
    <MpModal
      id="result-message"
      class="modal-inner all-clear"
      v-if="isShowFinalGuide"
      :class="{ show: isShowFinalGuide }"
    >
      <div class="modal-message-box">
        <div
          id="finished-all"
          class="result-box"
          :class="{ show: isShowFinalGuide }"
        >
          <div class="result-img">
            <img
              :src="$image('question/congratulations.svg')"
              alt="おめでとう！"
            />
          </div>
          <div class="result-message-box">
            <div>
              <p class="result-message-title">おめでとう！</p>
              <p class="result-message-body">九九をマスターしたね！！</p>
            </div>
          </div>
        </div>
        <div id="finish-modal">
          <a href="#" @click.stop.prevent="doFinalFinish"> おわる </a>
        </div>
      </div>
    </MpModal>
  </div>
</template>

<script>
import MpModal from "@/components/ui/MpModal.vue";
import MpWeakness from "@/components/ui/MpWeakness.vue";
import Activity from "@/mixins/activity";

const MP_GUIDANCE_FIRST_GUIDE_FINISH_EVENT = "firstGuideFinish"; // ファーストガイド終了イベント
const MP_GUIDANCE_WEAKNESS_GUIDE_FINISH_EVENT = "weaknessGuideFinish"; // 苦手な九九終了イベント
const MP_GUIDANCE_BEFORE_FINISH_EVENT = "beforeFinish"; // ガイダンス終了前イベント
const MP_GUIDANCE_RETRY_EVENT = "retry"; // ガイダンスリトライイベント
export default {
  components: {
    MpModal,
    MpWeakness,
  },
  mixins: [Activity],
  props: {
    // ファーストガイド音源
    firstGuidSoundId: {
      type: String,
      required: true,
    },
    // 苦手な九九リスト
    weaknessResults: {
      type: Array,
      default: () => [],
    },
    // クリアバックグラウンド有効有無（true:有効、false:無効）
    isBackgoudClear: {
      type: Boolean,
      default: false,
    },
    // ファイナルガイド有無（true:あり、false:なし）
    isFinalGuide: {
      type: Boolean,
      default: false,
    },
    // 結果ガイダンスクラス
    resultMessageClass: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      isShowFirstGuide: false, // ファーストガイド表示有無（true:表示、false:非表示）
      isShowWeaknessGuide: false, // 苦手な九九ガイド
      isWeaknessListenAll: false, // 全ての苦手な九九を聞いたかどうか（true:聞いた、false:聞いていない）
      isShowCompletionGuide1: false, // クリアガイド１（クリアした場合）
      isShowCompletionGuide2: false, // クリアしなかったが、前回より良かった場合
      isShowCompletionGuide3: false, // クリアせず、前回より良くなかった場合
      isShowFinalGuide: false, // 全コンテンツクリア画面表示有無（true:表示する、false:表示しない）
      soundFirstGuid: null, // ファーストガイド音源
      isFirstGuidanceFinish: false, // ファーストガイダンス終了有無（true:終了、false:終了していない）
      isContetClear: false, // コンテンツクリア有無（true:クリア、false:未クリア）
      isWeaknessListen: false, // 苦手な九九再生フラグ（true:再生中、false:非再生）
    };
  },
  computed: {
    loginId: function () {
      return this.$store.getters["auth/getLoginId"];
    },
    content: function () {
      return this.$store.getters["app/getCurrentContent"];
    },
    // コンテンツ学習回数
    challengeCount() {
      return this.$store.getters["learningState/getContentChallengeCount"](
        this.content.stage,
        this.content.table,
        this.content.level
      );
    },
    // コンテンツクリア回数
    clearCount() {
      return this.$store.getters["learningState/getContentClearCount"](
        this.content.stage,
        this.content.table,
        this.content.level
      );
    },
    // 総問題数
    answered() {
      return this.$store.getters["learningState/getAnswered"];
    },
    // 総学習時間
    totalTime() {
      return this.$store.getters["learningState/getTotalTime"];
    },
    // 苦手な九九が存在するかどうか（true:存在する、false:存在しない）
    isWeakness: function () {
      return this.weaknessResults && this.weaknessResults.length > 0;
    },
    // 全コンテンツクリアガイダンス有無（true:表示、false:非表示）
    isContentClearAllGuidance: function () {
      return this.$store.getters["learningState/isContentClearAllGuidance"];
    },
    // 苦手な九九さいしょにきいてみよう指マーク表示有無（true:表示、false:非表示）
    isWeaknessSuggestFirst: function () {
      return this.$store.getters["learningState/isWeaknessSuggestFirst"];
    },
    // 苦手な九九さいごにきいてみよう指マーク表示有無（true:表示、false:非表示）
    isWeaknessSuggestLast: function () {
      return this.$store.getters["learningState/isWeaknessSuggestLast"];
    },
  },
  watch: {
    isShowFirstGuide: function (val) {
      if (val) {
        // ファーストガイド表示

        // ガイド音声再生
        this.isFirstGuidanceFinish = false;
        this.soundFirstGuid = this.$sound.guide(this.firstGuidSoundId);
        this.soundFirstGuid.play(() => {
          this.isFirstGuidanceFinish = true;
        });
      } else {
        // ファーストガイド非表示
        if (this.soundFirstGuid) this.soundFirstGuid.stop();
      }
    },
    isShowWeaknessGuide: function (val) {
      if (val) {
        // 学習履歴学習前苦手な九九
        this.$store.dispatch("history/weaknessStart", {
          loginId: this.loginId,
          content: this.content,
        });
        // 苦手な九九表示音声
        this.$sound.listenFirst().play(() => {
          if (this.isWeaknessSuggestFirst) {
            // 苦手な九九指マーク表示
            this.$refs.weaknessFirst.showWeaknessSuggest();
            // 苦手な九九指マークは、この画面を最初に表示した時のみ
            this.$store.commit("learningState/setWeaknessSuggestFirst", false);
          }
        });
      }
    },
    isShowCompletionGuide1: function (val) {
      if (val) {
        // クリアした場合
        this.isContetClear = true;
        this.$sound.congratulations().play(() => {
          if (this.isWeakness) this.$sound.listenLast().play();
        });

        // コンテンツクリアイベント
        this.$store.dispatch("history/contentCleare", {
          loginId: this.loginId,
          content: this.content,
        });

        if (this.isWeakness) {
          // 学習履歴学習後苦手な九九
          this.$store.dispatch("history/weaknessEnd", {
            loginId: this.loginId,
            content: this.content,
          });
        }
      }
    },
    isShowCompletionGuide2: function (val) {
      if (val) {
        // クリアしなかったが、前回より良かった場合
        this.isContetClear = false;
        this.$sound.quickly().play(() => {
          if (this.isWeakness)
            this.$sound.listenLast().play(() => {
              if (this.isWeaknessSuggestLast) {
                // 苦手な九九指マーク表示
                this.$refs.weaknessLast.showWeaknessSuggest();
                // 苦手な九九指マークは、この画面を最初に表示した時のみ
                this.$store.commit(
                  "learningState/setWeaknessSuggestLast",
                  false
                );
              }
            });
        });

        // コンテンツ未クリアイベント
        this.$store.dispatch("history/contentNotClear", {
          loginId: this.loginId,
          content: this.content,
        });

        if (this.isWeakness) {
          // 学習履歴学習後苦手な九九
          this.$store.dispatch("history/weaknessEnd", {
            loginId: this.loginId,
            content: this.content,
          });
        }
      }
    },
    isShowCompletionGuide3: function (val) {
      if (val) {
        // クリアせず、前回より良くなかった場合
        this.isContetClear = false;
        this.$sound.triedHard().play(() => {
          if (this.isWeakness)
            this.$sound.listenLast().play(() => {
              if (this.isWeaknessSuggestLast) {
                // 苦手な九九指マーク表示
                this.$refs.weaknessLast.showWeaknessSuggest();
                // 苦手な九九指マークは、この画面を最初に表示した時のみ
                this.$store.commit(
                  "learningState/setWeaknessSuggestLast",
                  false
                );
              }
            });
        });

        // コンテンツ未クリアイベント
        this.$store.dispatch("history/contentNotClear", {
          loginId: this.loginId,
          content: this.content,
        });

        if (this.isWeakness) {
          // 学習履歴学習後苦手な九九
          this.$store.dispatch("history/weaknessEnd", {
            loginId: this.loginId,
            content: this.content,
          });
        }
      }
    },
    isShowFinalGuide: function (val) {
      if (val) {
        // 全コンテンツクリアした場合の音声
        this.$sound.masterAll().play();
      }
    },
  },
  destroyed() {
    if (this.listenLastSound) this.listenLastSound.stop();
  },
  methods: {
    // ファーストガイド表示
    showFirstGuide() {
      this.$log.debug("ファーストガイド表示");
      // ファーストガイド表示
      this.isShowFirstGuide = true;
    },
    // すすむ
    doNext() {
      this.$log.debug("ファーストガイド すすむボタン押下");
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "すすむボタン",
        table: this.$store.getters["app/getCurrentTable"],
      });
      // ファーストガイド非表示
      this.isShowFirstGuide = false;
    },
    // ファーストガイド破棄
    onFirstGuideDestroyed() {
      this.$log.debug("ファーストガイド破棄");
      // ファーストガイド終了イベント
      this.$emit(MP_GUIDANCE_FIRST_GUIDE_FINISH_EVENT);
    },
    // 苦手な九九表示
    showWeaknessGuide() {
      this.$log.debug("苦手な九九表示");
      // 苦手な九九表示
      this.isShowWeaknessGuide = true;
    },
    // 苦手な九九マウント時
    onWeaknessMounted() {
      this.$log.debug("苦手な九九マウント時");
      this.isWeaknessListenAll = false;
    },
    // 全ての苦手な九九を聞いたとき
    onWeaknessListenAll() {
      this.$log.debug("全ての苦手な九九を聞いたとき");
      this.isWeaknessListenAll = true;
    },
    // スタート
    doStart() {
      this.$log.debug("苦手な九九 スタートボタン押下");
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "スタートボタン",
        table: this.$store.getters["app/getCurrentTable"],
      });
      // 苦手な九九非表示
      this.isShowWeaknessGuide = false;
    },
    // 苦手な九九再生開始
    onWeaknessListenStart() {
      this.isWeaknessListen = true;
    },
    // 苦手な九九再生終了
    onWeaknessListenFinish() {
      this.isWeaknessListen = false;
    },
    // 苦手な九九破棄
    onWeaknessGuideDestroyed() {
      this.$log.debug("苦手な九九破棄");
      // 苦手な九九終了イベント
      this.$emit(MP_GUIDANCE_WEAKNESS_GUIDE_FINISH_EVENT);
    },
    // クリアガイド１（クリアした場合）表示
    showCompletionGuide1() {
      this.$log.debug("クリアガイド１（クリアした場合）");
      this.isShowCompletionGuide1 = true;
    },
    // クリアしなかったが、前回より良かった場合表示
    showCompletionGuide2() {
      this.$log.debug("クリアしなかったが、前回より良かった場合");
      this.isShowCompletionGuide2 = true;
    },
    // クリアせず、前回より良くなかった場合表示
    showCompletionGuide3() {
      this.$log.debug("クリアせず、前回より良くなかった場合");
      this.isShowCompletionGuide3 = true;
    },
    // おわる
    doFinish() {
      if (this.isWeaknessListen) return;
      this.$log.debug("おわるボタン操作");
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "コンテンツおわるボタン",
        table: this.$store.getters["app/getCurrentTable"],
      });
      // ガイダンス前終了イベント
      this.$log.debug("ガイダンス前終了イベント");
      this.$emit(MP_GUIDANCE_BEFORE_FINISH_EVENT);
      // コンテンツ学習時間計測終了
      this.$store.commit("app/stopLearningTime");
      // コンテンツ学習時間取得
      var time = this.$store.getters["app/getLearningTime"];
      // 総学習時間加算
      this.$store.commit("learningState/addTotalTime", time);
      // 学習履歴１コンテンツの学習時間
      this.$store.dispatch("history/finishContent", {
        loginId: this.loginId,
        content: this.content,
        time: time,
      });
      // 学習履歴コンテンツ累積ログ① 総学習回数
      this.$store.dispatch("history/cumulativeNumberOfChallenge", {
        loginId: this.loginId,
        content: this.content,
        challenge: this.challengeCount,
      });
      // 学習履歴コンテンツ累積ログ② クリア回数
      this.$store.dispatch("history/cumulativeNumberOfClear", {
        loginId: this.loginId,
        content: this.content,
        clear: this.clearCount,
      });
      // 学習履歴コンテンツ累積ログ③ 総問題数
      this.$store.dispatch("history/cumulativeNumberOfAnswered", {
        loginId: this.loginId,
        answered: this.answered,
      });
      // 学習履歴コンテンツ累積ログ④ 総学習時間
      this.$store.dispatch("history/cumulativeTotalLearningTime", {
        loginId: this.loginId,
        time: this.totalTime,
      });
      // ログ送信アクティビティ
      this.activitySendLog();
      // 終了ガイド非表示
      this.isShowCompletionGuide1 = false;
      this.isShowCompletionGuide2 = false;
      this.isShowCompletionGuide3 = false;
      if (
        this.isFinalGuide &&
        this.isContetClear &&
        this.isContentClearAllGuidance
      ) {
        // 全コンテンツクリア画面表示
        this.$log.debug("全コンテンツクリアガイド表示");
        this.isShowFinalGuide = true;
        // 全コンテンツクリア画面表示有無設定
        this.$store.commit("learningState/setContentClearAllGuidance", false);
      } else {
        // アプリビュー変更（前の画面に戻る）
        var prevView = this.$store.getters["app/getPrevView"];
        this.$store.commit("app/setCurrentView", prevView);
      }
    },
    // 全コンテンツクリア画面のおわる
    doFinalFinish() {
      this.$log.debug("全コンテンツクリア画面のおわるボタン操作");
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "全コンテンツおわるボタン",
        table: this.$store.getters["app/getCurrentTable"],
      });
      // 終了ガイド非表示
      this.isShowCompletionGuide1 = false;
      this.isShowCompletionGuide2 = false;
      this.isShowCompletionGuide3 = false;
      this.isShowFinalGuide = false;
      // アプリビュー変更（前の画面に戻る）
      var prevView = this.$store.getters["app/getPrevView"];
      this.$store.commit("app/setCurrentView", prevView);
    },
    // もう１かい
    doRetry() {
      this.$log.debug("もう１かいボタン操作");
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "もう１かいボタン",
        table: this.$store.getters["app/getCurrentTable"],
      });
      // 終了ガイド非表示
      this.isShowCompletionGuide1 = false;
      this.isShowCompletionGuide2 = false;
      this.isShowCompletionGuide3 = false;

      this.isWeaknessListen = false;
      // ガイダンスリトライイベント
      this.$emit(MP_GUIDANCE_RETRY_EVENT);
      // 学習履歴コンテンツ累積ログ① 総学習回数
      this.$store.dispatch("history/cumulativeNumberOfChallenge", {
        loginId: this.loginId,
        content: this.content,
        challenge: this.challengeCount,
      });
      // 学習履歴コンテンツ累積ログ② クリア回数
      this.$store.dispatch("history/cumulativeNumberOfClear", {
        loginId: this.loginId,
        content: this.content,
        clear: this.clearCount,
      });
      // 学習履歴コンテンツ累積ログ③ 総問題数
      this.$store.dispatch("history/cumulativeNumberOfAnswered", {
        loginId: this.loginId,
        answered: this.answered,
      });
      // 学習履歴コンテンツ累積ログ④ 総学習時間
      this.$store.dispatch("history/cumulativeTotalLearningTime", {
        loginId: this.loginId,
        time: this.totalTime,
      });
    },
  },
};
</script>