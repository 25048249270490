const Timer = {
    install(Vue) {
        Vue.prototype.$timer = {
            /**
             * １回のみのタイマー
             * @param {Function} callback コールバック間数
             * @param {Number} delay タイマー値（ミリ秒）
             * @returns タイマーインスタンス
             */
            once(callback, delay) {
                var timerId, startTime, remaining = delay;
                var self;
                return new class {
                    constructor() {
                        self = this;
                        self.resume();
                    }

                    log(message) {
                        Vue.prototype.$log.debug(message + "(%d)=>スタート(%s)、残り(%d)", timerId, startTime, remaining);
                    }

                    // タイマー停止
                    pause = function () {
                        self.log("タイマー停止 開始");
                        remaining = remaining - (new Date() - startTime);
                        clearTimeout(timerId);
                        self.log("タイマー停止 終了");
                    };

                    // タイマー開始
                    resume = function () {
                        self.log("タイマー起動 開始");
                        startTime = Date.now();
                        clearTimeout(timerId);
                        timerId = setTimeout(callback, remaining);
                        self.log("タイマー起動 終了");
                    };

                    // タイマー終了
                    exit = function () {
                        clearInterval(timerId);
                        self.log("タイマー終了");
                    };
                }
            },

            /**
             * 繰り返しタイマー
             * 
             * @param {Function} ontimeout タイムアウトコールバック
             * @param {Function} onend タイマー終了コールバック
             * @param {Number} interval タイマーインターバル（ミリ秒）
             * @param {Number} max 最大繰り返し回数、但し0は無制限
             * @returns タイマーインスタンス
             */
            recursive(ontimeout, onend, interval, max) {
                var timerId, startTime, remaining = 0;
                var state = 0; //  0:アイドル, 1:起動中, 2:停止中, 3:再開
                var callbackCount = 0; // コルバック回数
                var self;
                return new class {
                    constructor() {
                        startTime = new Date();
                        self = this;
                        timerId = setInterval(self.callback, interval);
                        state = 1;
                        self.log("繰り返しタイマー開始");
                    }

                    log(message) {
                        Vue.prototype.$log.debug(message + "(%d)=>ステータス:%d、コールバック回数(%d)、最大(%d)、インターバル(%d)", timerId, state, callbackCount, max, interval);
                    }

                    trace(message) {
                        Vue.prototype.$log.trace(message + "(%d)=>ステータス:%d、コールバック回数(%d)、最大(%d)、インターバル(%d)", timerId, state, callbackCount, max, interval);
                    }

                    // タイマー停止
                    pause = function () {
                        self.log("タイマー停止開始");
                        // 既に停止中の場合無視する
                        if (state != 1) {
                            self.log("タイマー起動中以外のため停止失敗");
                            return;
                        }
                        remaining = interval - (new Date() - startTime);
                        clearInterval(timerId);
                        state = 2;
                        self.log("タイマー停止終了");
                    };

                    // タイマー再開
                    resume = function () {
                        self.log("タイマー再開開始");
                        // 停止中以外は無視する
                        if (state != 2) {
                            self.log("タイマー停止中でないため停止失敗");
                            return;
                        }
                        state = 3;
                        setTimeout(self.callback, remaining);
                        self.log("タイマー再開終了");
                    };

                    // タイマー終了
                    exit = function () {
                        clearInterval(timerId);
                        state = 9;
                        self.log("タイマー終了");
                    };

                    callback = function () {
                        self.trace("コールバック開始");
                        if (max != 0 && callbackCount >= max) {
                            self.log("既に終了済みのコールバック");
                            return;
                        }
                        if (state == 3) {
                            // 停止からの再開の場合
                            self.log("タイマー停止中からの再開");
                            // タイムアウトコールバック
                            callbackCount++;
                            ontimeout();
                            self.log("タイムアウトコールバック実行");
                            if (max != 0 && callbackCount >= max) {
                                // コールバック回数が最大数を超えた場合

                                // 終了コールバック  
                                onend();
                                self.log("終了コールバック実行");
                            } else {
                                // 繰り返しタイマー再開
                                startTime = new Date();
                                timerId = setInterval(self.callback, interval);
                                self.log("タイマー再開");
                                state = 1;
                            }
                        } else {
                            // 通常のコールバックの場合
                            self.trace("通常のコールバック");

                            // タイムアウトコールバック
                            callbackCount++;
                            ontimeout();
                            self.trace("タイムアウトコールバック実行");

                            if (max != 0 && callbackCount >= max) {
                                // コールバック回数が最大数を超えた場合

                                // 終了コールバック  
                                onend();
                                self.log("終了コールバック実行");

                                // タイマー停止
                                clearInterval(timerId);
                                self.log("タイマー停止");
                            }
                        }
                        self.trace("コールバック終了");
                    };
                }
            }
        }
    }
}

export default Timer;