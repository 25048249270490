<template>
  <div id="container" class="menu">
    <slot />
    <header>
      <h1>うみ</h1>
      <div id="solved">
        いままでに&nbsp;<span>{{ answered }}もん</span>&nbsp;といたよ！
      </div>
      <div id="nav">
        <button id="list" @click="doTimesTable">九九のいちらん</button>
        <MpHamburger />
      </div>
    </header>
    <main>
      <div id="steps">
        <MpNormalPath :class="['path1']" table="table2" />
        <MpNormalPath :class="['path2']" table="table3" />
        <MpNormalPath :class="['path3']" table="table2to3" />
        <MpNormalPath :class="['path4']" table="table4" />
        <MpNormalPath :class="['path5']" table="table2to4" />
        <MpNormalPath :class="['path6']" table="table5" />
        <MpNormalPath :class="['path7']" table="table2to5" />
        <MpNormalPath :class="['path8']" table="table6" />
        <MpNormalPath :class="['path9']" table="table2to6" />
        <MpNormalPath :class="['path10']" table="table7" />
        <MpNormalPath :class="['path11']" table="table2to7" />
        <MpNormalPath :class="['path12']" table="table8" />
        <MpNormalPath :class="['path13']" table="table2to8" />
        <MpNormalPath :class="['path14']" table="table9" />
        <MpNormalPath :class="['path15']" table="table2to9" />
      </div>
      <div id="achievements">
        <MpAchievement class="achievement1" :count="100" :answered="answered">
          <img :src="$image('normal/N_1.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement2" :count="200" :answered="answered">
          <img :src="$image('normal/N_2.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement3" :count="300" :answered="answered">
          <img :src="$image('normal/N_3.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement4" :count="400" :answered="answered">
          <img :src="$image('normal/N_4.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement5" :count="500" :answered="answered">
          <img :src="$image('normal/N_5.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement6" :count="600" :answered="answered">
          <img :src="$image('normal/N_6.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement7" :count="800" :answered="answered">
          <img :src="$image('normal/N_7.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement8" :count="1000" :answered="answered">
          <img :src="$image('normal/N_8.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement9" :count="1200" :answered="answered">
          <img :src="$image('normal/N_9.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement10" :count="1400" :answered="answered">
          <img :src="$image('normal/N_10.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement11" :count="1600" :answered="answered">
          <img :src="$image('normal/N_11.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement12" :count="1800" :answered="answered">
          <img :src="$image('normal/N_12.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement13" :count="2000" :answered="answered">
          <img :src="$image('normal/N_13.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement14" :count="2200" :answered="answered">
          <img :src="$image('normal/N_14.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement15" :count="2400" :answered="answered">
          <img :src="$image('normal/N_15.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement16" :count="2600" :answered="answered">
          <img :src="$image('normal/N_16.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement17" :count="2800" :answered="answered">
          <img :src="$image('normal/N_17.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement18" :count="3000" :answered="answered">
          <img :src="$image('normal/N_18.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement19" :count="3500" :answered="answered">
          <img :src="$image('normal/N_19.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement20" :count="4000" :answered="answered">
          <img :src="$image('normal/N_20.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement21" :count="4500" :answered="answered">
          <img :src="$image('normal/N_21.svg')" alt="" />
        </MpAchievement>
        <MpAchievement class="achievement22" :count="5000" :answered="answered">
          <img :src="$image('normal/N_22.svg')" alt="" />
        </MpAchievement>
      </div>
      <div id="stages">
        <MpNormalStage
          :class="['stage2']"
          :table="sequence.table2"
          addtionalIslandClass="island1"
          islandIcon="island1.svg"
          shipIcon="ship_p_2.svg"
        />
        <MpNormalStage
          :class="['stage3']"
          :table="sequence.table3"
          addtionalIslandClass="island1"
          islandIcon="island1.svg"
          shipIcon="ship_p_3.svg"
        />
        <MpNormalStage
          :class="['stage2-3']"
          :table="sequence.table2to3"
          addtionalIslandClass="island1"
          islandIcon="island1.svg"
          shipIcon="ship_p_2-3.svg"
        />
        <MpNormalStage
          :class="['stage4']"
          :table="sequence.table4"
          addtionalIslandClass="island1"
          islandIcon="island1.svg"
          shipIcon="ship_p_4.svg"
        />
        <MpNormalStage
          :class="['stage2-4']"
          :table="sequence.table2to4"
          addtionalIslandClass="island1"
          islandIcon="island1.svg"
          shipIcon="ship_p_2-4.svg"
        />
        <MpNormalStage
          :class="['stage5']"
          :table="sequence.table5"
          addtionalIslandClass="island1"
          islandIcon="island1.svg"
          shipIcon="ship_p_5.svg"
        />
        <MpNormalStage
          :class="['stage2-5']"
          :table="sequence.table2to5"
          addtionalIslandClass="island2"
          islandIcon="island2.svg"
          shipIcon="ship_p_2-5.svg"
        />
        <MpNormalStage
          :class="['stage6']"
          :table="sequence.table6"
          addtionalIslandClass="island2"
          islandIcon="island2.svg"
          shipIcon="ship_p_6.svg"
        />
        <MpNormalStage
          :class="['stage2-6']"
          :table="sequence.table2to6"
          addtionalIslandClass="island2"
          islandIcon="island2.svg"
          shipIcon="ship_p_2-6.svg"
        />
        <MpNormalStage
          :class="['stage7']"
          :table="sequence.table7"
          addtionalIslandClass="island2"
          islandIcon="island2.svg"
          shipIcon="ship_p_7.svg"
        />
        <MpNormalStage
          :class="['stage2-7']"
          :table="sequence.table2to7"
          addtionalIslandClass="island2"
          islandIcon="island2.svg"
          shipIcon="ship_p_2-7.svg"
        />
        <MpNormalStage
          :class="['stage8']"
          :table="sequence.table8"
          addtionalIslandClass="island2"
          islandIcon="island2.svg"
          shipIcon="ship_p_8.svg"
        />
        <MpNormalStage
          :class="['stage2-8']"
          :table="sequence.table2to8"
          addtionalIslandClass="island3"
          islandIcon="island3.svg"
          shipIcon="ship_p_2-8.svg"
        />
        <MpNormalStage
          :class="['stage9']"
          :table="sequence.table9"
          addtionalIslandClass="island3"
          islandIcon="island3.svg"
          shipIcon="ship_p_9.svg"
        />
        <MpNormalStage
          :class="['stage2-9']"
          :table="sequence.table2to9"
          addtionalIslandClass="island3"
          islandIcon="island3.svg"
          shipIcon="ship_p_2-9.svg"
        />
        <div
          class="stage bonus treasure"
          :class="[!isNormalStageCompleted ? 'locked' : 'open']"
        >
          <a href="#" @click.stop.prevent="doBonus">
            <p>
              <img
                v-if="isNormalStageCompleted"
                :src="$image('normal/treasure_o.svg')"
                alt="ボーナスステージ"
              />
              <img
                v-else
                :src="$image('normal/treasure_c.svg')"
                alt="ボーナスステージ"
              />
            </p>
          </a>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MpHamburger from "@/components/ui/MpHamburger";
import MpNormalStage from "@/components/ui/MpNormalStage";
import MpNormalPath from "@/components/ui/MpNormalPath";
import MpAchievement from "@/components/ui/MpAchievement";
import Normal from "@/mixins/normal";
export default {
  components: {
    MpHamburger,
    MpNormalStage,
    MpNormalPath,
    MpAchievement,
  },
  mixins: [Normal],
  computed: {
    answered() {
      const answered = this.$store.getters["learningState/getAnswered"];
      const max = 9999;

      if (answered > max) {
        return max;
      }

      return answered;
    },
    isNormalStageCompleted() {
      return this.isTableClear('table2to9');
    },
  },
  created() {
    // 横レイアウト強制
    this.$log.debug("横レイアウト強制");
    this.$store.commit("app/enforceOrientationLandscape");
  },
  mounted() {
    // ノーマルステージ選択
    this.$store.dispatch("app/selectStage", "normal");
  },
  methods: {
    // 九九いちらん表示
    doTimesTable() {
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "九九いちらん表示",
      });
      // 画面遷移
      this.$store.commit("app/setCurrentView", "ViewTimesTable");
    },
    // ボーナスステージ表示
    doBonus() {
      if (!this.isTableClear("table2to9")) return;
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "うちゅう選択",
      });
      // 画面遷移
      this.$store.commit("app/setCurrentView", "ViewStageBonus");
    },
  },
};
</script>