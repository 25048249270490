var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"listen-exprs"},[(_vm.weaknessFirst)?_c('div',{staticClass:"listen-expr"},[(_vm.showAnswer)?_c('button',{staticClass:"btn-speaker",class:{
        disabled: _vm.isPlayWeaknessFirst || _vm.isPlayWeaknessSecond,
      },attrs:{"type":"button"},on:{"click":_vm.playWeaknessFirst}},[(_vm.viewSuggestFirst && !_vm.isSuggestStop)?_c('div',{staticClass:"suggest"}):_vm._e()]):_vm._e(),_c('div',{staticClass:"modal-expr"},[_c('div',{staticClass:"multiplicand"},[_vm._v(_vm._s(_vm.weaknessFirst[0]))]),_c('div',{staticClass:"times"},[_vm._v("×")]),_c('div',{staticClass:"multiplier"},[_vm._v(_vm._s(_vm.weaknessFirst[1]))]),_c('div',{staticClass:"equal"},[_vm._v("=")]),_c('div',{staticClass:"product"},[_vm._v(" "+_vm._s(_vm.showAnswer ? _vm.weaknessFirst[0] * _vm.weaknessFirst[1] : "")+" ")])]),(!_vm.showAnswer)?_c('button',{staticClass:"btn-speaker",class:{
        disabled: _vm.isPlayWeaknessFirst || _vm.isPlayWeaknessSecond,
      },attrs:{"type":"button"},on:{"click":_vm.playWeaknessFirst}},[(_vm.viewSuggestFirst && !_vm.isSuggestStop)?_c('div',{staticClass:"suggest"}):_vm._e()]):_vm._e()]):_vm._e(),(_vm.weaknessSecond)?_c('div',{staticClass:"listen-expr"},[(_vm.showAnswer)?_c('button',{staticClass:"btn-speaker",class:{
        disabled: _vm.isPlayWeaknessFirst || _vm.isPlayWeaknessSecond,
      },attrs:{"type":"button"},on:{"click":_vm.playWeaknessSecond}},[(_vm.viewSuggestSecond && !_vm.isSuggestStop)?_c('div',{staticClass:"suggest"}):_vm._e()]):_vm._e(),_c('div',{staticClass:"modal-expr"},[_c('div',{staticClass:"multiplicand"},[_vm._v(_vm._s(_vm.weaknessSecond[0]))]),_c('div',{staticClass:"times"},[_vm._v("×")]),_c('div',{staticClass:"multiplier"},[_vm._v(_vm._s(_vm.weaknessSecond[1]))]),_c('div',{staticClass:"equal"},[_vm._v("=")]),_c('div',{staticClass:"product"},[_vm._v(" "+_vm._s(_vm.showAnswer ? _vm.weaknessSecond[0] * _vm.weaknessSecond[1] : "")+" ")])]),(!_vm.showAnswer)?_c('button',{staticClass:"btn-speaker",class:{
        disabled: _vm.isPlayWeaknessFirst || _vm.isPlayWeaknessSecond,
      },attrs:{"type":"button"},on:{"click":_vm.playWeaknessSecond}},[(_vm.viewSuggestSecond && !_vm.isSuggestStop)?_c('div',{staticClass:"suggest"}):_vm._e()]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }