<template>
  <div>
    <slot />
  </div>
</template>

<script>
const MP_MODAL_DESTROYED_EVENT = "destroyed"; // モーダル破棄後イベント
export default {
  props: {
    finish: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // オーバーレイ表示
    this.$store.commit("app/setOverlay", true);
  },
  destroyed() {
    // オーバーレイ停止
    this.$store.commit("app/setOverlay", false);
    // モーダル破棄イベント
    this.$emit(MP_MODAL_DESTROYED_EVENT);
  },
};
</script>