<template>
  <div id="answer">
    <div id="numpad">
      <div class="numrow">
        <button
          class="numkey num-7"
          @click="enterKey('7')"
          :disabled="isProcessing"
        ></button>
        <button
          class="numkey num-8"
          @click="enterKey('8')"
          :disabled="isProcessing"
        ></button>
        <button
          class="numkey num-9"
          @click="enterKey('9')"
          :disabled="isProcessing"
        ></button>
      </div>
      <div class="numrow">
        <button
          class="numkey num-4"
          @click="enterKey('4')"
          :disabled="isProcessing"
        ></button>
        <button
          class="numkey num-5"
          @click="enterKey('5')"
          :disabled="isProcessing"
        ></button>
        <button
          class="numkey num-6"
          @click="enterKey('6')"
          :disabled="isProcessing"
        ></button>
      </div>
      <div class="numrow">
        <button
          class="numkey num-1"
          @click="enterKey('1')"
          :disabled="isProcessing"
        ></button>
        <button
          class="numkey num-2"
          @click="enterKey('2')"
          :disabled="isProcessing"
        ></button>
        <button
          class="numkey num-3"
          @click="enterKey('3')"
          :disabled="isProcessing"
        ></button>
      </div>
      <div class="numrow row-last">
        <button
          class="numkey clear"
          @click="enterKey('clear')"
          :disabled="isProcessing"
        ></button>
        <button
          class="numkey num-0"
          @click="enterKey('0')"
          :disabled="isProcessing"
        ></button>
        <button
          class="numkey enter"
          @click.prevent="enterKey('enter')"
          :disabled="isProcessing"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
const MP_CALCULATOR_ENTER_KEY_EVENT = "enterKey"; // キー入力時のイベント（引数:キー文字列）
export default {
  computed: {
    isProcessing() {
      return this.$store.getters["app/isProcessing"];
    },
  },
  methods: {
    enterKey(key) {
      if (key == "enter") {
        this.$log.debug("プロセス開始");
        this.$store.commit("app/setProcessing", true);
      }
      this.$emit(MP_CALCULATOR_ENTER_KEY_EVENT, key);
    },
  },
};
</script>