<template>
  <div id="container" class="level">
    <slot />
    <header>
      <div id="nav">
        <MpHamburger />
      </div>
    </header>
    <h1>{{ title }}のだん</h1>
    <div id="current-stages">
      <img
        :src="$image('level/level2.png')"
        alt="2のだん"
        id="stage2"
        v-if="times.includes(2)"
      />
      <img
        :src="$image('level/level3.png')"
        alt="3のだん"
        id="stage3"
        v-if="times.includes(3)"
      />
      <img
        :src="$image('level/level4.png')"
        alt="4のだん"
        id="stage4"
        v-if="times.includes(4)"
      />
      <img
        :src="$image('level/level5.png')"
        alt="5のだん"
        id="stage5"
        v-if="times.includes(5)"
      />
      <img
        :src="$image('level/level6.png')"
        alt="6のだん"
        id="stage6"
        v-if="times.includes(6)"
      />
      <img
        :src="$image('level/level7.png')"
        alt="7のだん"
        id="stage7"
        v-if="times.includes(7)"
      />
      <img
        :src="$image('level/level8.png')"
        alt="8のだん"
        id="stage8"
        v-if="times.includes(8)"
      />
      <img
        :src="$image('level/level9.png')"
        alt="9のだん"
        id="stage9"
        v-if="times.includes(9)"
      />
    </div>
    <main class="main-multi">
      <div id="grid-container" class="multi">
        <div id="prev" class="grid-item stage-select">
          <MpLevelPrev v-if="prev" :table="prev" />
        </div>
        <div id="content">
          <MpLevel
            id="level1"
            class="grid-item level-select"
            level="level1"
            view="ViewLevelNormalMulti1"
            :locked="false"
          >
            レベル<span class="level-num">1</span>
          </MpLevel>
          <MpLevel
            id="level2"
            class="grid-item level-select"
            level="level2"
            view="ViewLevelNormalMulti2"
            :locked="!isContentClear('level1')"
          >
            レベル<span class="level-num">2</span>
          </MpLevel>
        </div>
        <div id="next" class="grid-item stage-select">
          <MpLevelNext v-if="next" :table="next" />
        </div>
      </div>
    </main>
    <footer class="footer-multi">
      <MpBack view="ViewStageNormal" />
    </footer>
  </div>
</template>

<script>
import MpHamburger from "@/components/ui/MpHamburger";
import MpLevel from "@/components/ui/MpLevel";
import MpLevelPrev from "@/components/ui/MpLevelPrev";
import MpLevelNext from "@/components/ui/MpLevelNext";
import MpBack from "@/components/ui/MpBack";
import Normal from "@/mixins/normal";
export default {
  components: {
    MpHamburger,
    MpLevel,
    MpLevelPrev,
    MpLevelNext,
    MpBack,
  },
  mixins: [Normal],
  computed: {
    stage() {
      return this.$store.getters["app/getCurrentStage"];
    },
    table() {
      return this.$store.getters["app/getCurrentTable"];
    },
    title() {
      return this.sequence[this.table].title;
    },
    times() {
      return this.sequence[this.table].times;
    },
    prev() {
      return this.getPrev(this.table);
    },
    next() {
      return this.getNext(this.table);
    },
  },
  created() {
    // 横レイアウト強制
    this.$log.debug("横レイアウト強制");
    this.$store.commit("app/enforceOrientationLandscape");
  },
  mounted() {
    // レベル選択解除
    this.$store.dispatch("app/deselectLevel");
  },
  methods: {
    isContentClear(level) {
      return this.$store.getters["learningState/isContentClear"](
        this.stage,
        this.table,
        level
      );
    },
  },
};
</script>