import moment from 'moment';

const state = () => (
    [] // 学習履歴
);

const getters = {
    getHistory: state => {
        return state;
    },
}

const mutations = {
    clearHistory(state) {
        state.splice(0);
    },
    /**
     * CSV形式で学習履歴を記録します。
     * 以下、学習履歴の書式です。
     *
     * 1桁目:ログインID
     *
     * 2桁目:学習年月日（年月日）"2021/09/13"
     *
     * 3桁目:時間（時:分:秒.ミリ秒）"09:47:33.32"
     *
     * 4桁目:コンテンツ名（ステージ テーブル（2の段、2〜3の段、6・7の段など） レベル（レベル1、レベル2など）
     *
     * 5桁目以降:コンテンツ名以降のイベント
     * 
     * @param {String} message ログ出力メッセージ
     * @param {String} loginId ログインID
     * @param {Object} content コンテンツ
     * @param {Array} events コンテンツ名以降のイベント
     */
    record(state, { message, loginId, content, events }) {
        var d = new Date();
        var history =
            loginId
            + ',' + moment(d).format("YYYY-MM-DD")
            + ',' + moment(d).format("HH:mm:ss.SSS");
        if (content) {
            history += ',' + stages[content.stage]
                + '　' + tables[content.table]
                + (content.level ? '　' + levels[content.level] : '');
        }
        if (events) {
            for (var e of events) {
                history += ',' + e;
            }
        }
        this.$log.info("学習履歴（%s）=>%s", message, history);
        state.push(history);
    }
}

const stages = {
    normal: 'うみ',
    bonus: 'うちゅう',
    timestable: '九九一覧',
}

const tables = {
    table1: '1の段',
    table2: '2の段',
    table3: '3の段',
    table4: '4の段',
    table5: '5の段',
    table6: '6の段',
    table7: '7の段',
    table8: '8の段',
    table9: '9の段',
    table10: '10の段',
    table2to3: '2〜3の段',
    table2to4: '2〜4の段',
    table2to5: '2〜5の段',
    table2to6: '2〜6の段',
    table2to7: '2〜7の段',
    table2to8: '2〜8の段',
    table2to9: '2〜9の段',
    table6to8: '6〜8の段',
    table7to9: '7〜9の段',
    table6to9: '6〜9の段',
    table6and7: '6・7の段',
    table7and8: '7・8の段',
    table8and9: '8・9の段',
    table6and9: '6・9の段',
    table4and7: '4・7の段',
}

const levels = {
    conversation: 'きく・いう',
    level1: 'Lv.1',
    level2: 'Lv.2',
    level3: 'Lv.3',
    level4: 'Lv.4',
    level5: 'Lv.5',
    level6: 'Lv.6',
}

const viewNames = {
    ViewLogin: 'ログイン画面',
    ViewRestrictionCancel: '1日の使用制限解除画面',
    ViewStageNormal: 'うみメニュー画面',
    ViewStageBonus: 'うちゅうメニュー画面',
    ViewTableNormalSingle: 'うみ レベル選択({table})画面',
    ViewTableNormalMulti: 'うみ レベル選択({table})画面',
    ViewTableBonusMulti: 'うちゅう レベル選択({table})画面',
    ViewLevelConversation: 'うみ　{table}　きく・いう',
    ViewLevelNormalSingle1: 'うみ　{table}　Lv.1',
    ViewLevelNormalSingle2: 'うみ　{table}　Lv.2',
    ViewLevelNormalSingle3: 'うみ　{table}　Lv.3',
    ViewLevelNormalSingle4: 'うみ　{table}　Lv.4',
    ViewLevelNormalSingle5: 'うみ　{table}　Lv.5',
    ViewLevelNormalSingle6: 'うみ　{table}　Lv.6',
    ViewLevelNormalMulti1: 'うみ　{table}　Lv.1',
    ViewLevelNormalMulti2: 'うみ　{table}　Lv.2',
    ViewLevelBonusMulti1: 'うちゅう　{table}　Lv.1',
    ViewLevelBonusMulti2: 'うちゅう　{table}　Lv.2',
    ViewTimesTable: '九九いちらん画面',
}

const actions = {
    // 学習履歴コンテンツ学習開始
    startContent(context, { loginId, content }) {
        context.commit("record", {
            message: "コンテンツ学習開始",
            loginId: loginId,
            content: content,
            events: ['学習開始']
        });
    },
    // １問ごとの問題出題タイミング
    startQuestion(context, { loginId, content, position, multiplicand, multiplier }) {
        context.commit("record", {
            message: "１問ごとの問題出題タイミング",
            loginId: loginId,
            content: content,
            events: [position + '問目', multiplicand + '×' + multiplier + '=', "問題表示"]
        });
    },
    // 学習履歴10キー操作
    tenKey(context, { loginId, content, position, multiplicand, multiplier, keyCode }) {
        context.commit("record", {
            message: "10キー操作",
            loginId: loginId,
            content: content,
            events: [position + '問目', multiplicand + '×' + multiplier + '=', keyCode]
        });
    },
    // 学習履歴クリアキー操作
    clear(context, { loginId, content, position, multiplicand, multiplier }) {
        context.commit("record", {
            message: "けす操作",
            loginId: loginId,
            content: content,
            events: [position + '問目', multiplicand + '×' + multiplier + '=', 'けす']
        });
    },
    // 学習履歴エンターキー操作
    enter(context, { loginId, content, position, multiplicand, multiplier, value, answer, result }) {
        context.commit("record", {
            message: "エンターキー操作",
            loginId: loginId,
            content: content,
            events: [position + '問目', multiplicand + '×' + multiplier + '=', 'すすむ', value, answer, result ? '正解' : '不正解']
        });
    },
    // 学習履歴ヒント手印表示
    hintHand(context, { loginId, content, position, multiplicand, multiplier }) {
        context.commit("record", {
            message: "ヒント手印表示",
            loginId: loginId,
            content: content,
            events: [position + '問目', multiplicand + '×' + multiplier + '=', '手印表示']
        });
    },
    // 学習履歴ヒント開始
    hintStart(context, { loginId, content, position, multiplicand, multiplier, hintCount, isAuto = false }) {
        if (isAuto) {
            // 自動ヒントの場合
            context.commit("record", {
                message: "ヒント開始",
                loginId: loginId,
                content: content,
                events: [position + '問目', multiplicand + '×' + multiplier + '=', '自動ヒント']
            });
        } else {
            // 手動ヒントの場合
            context.commit("record", {
                message: "ヒント開始",
                loginId: loginId,
                content: content,
                events: [position + '問目', multiplicand + '×' + multiplier + '=', 'ヒント' + hintCount + '回目']
            });
        }
    },
    // 学習履歴ヒント閉じる
    hintClose(context, { loginId, content, position, multiplicand, multiplier }) {
        context.commit("record", {
            message: "ヒント閉じる",
            loginId: loginId,
            content: content,
            events: [position + '問目', multiplicand + '×' + multiplier + '=', 'ヒント画面閉じる']
        });
    },
    // 学習履歴学習前苦手な九九
    weaknessStart(context, { loginId, content }) {
        context.commit("record", {
            message: "苦手な九九開始",
            loginId: loginId,
            content: content,
            events: ['学習前_苦手な九九']
        });
    },
    // 学習履歴学習後苦手な九九
    weaknessEnd(context, { loginId, content }) {
        context.commit("record", {
            message: "苦手な九九開始",
            loginId: loginId,
            content: content,
            events: ['学習後_苦手な九九']
        });
    },
    // 学習履歴苦手な九九
    weakness(context, { loginId, content, position, multiplicand, multiplier }) {
        context.commit("record", {
            message: "苦手な九九",
            loginId: loginId,
            content: content,
            events: [position + '問目', multiplicand + '×' + multiplier + '=']
        });
    },
    // 学習履歴苦手な九九タップ
    weaknessTap(context, { loginId, content, position, multiplicand, multiplier }) {
        context.commit("record", {
            message: "苦手な九九タップ",
            loginId: loginId,
            content: content,
            events: [position + '問目', multiplicand + '×' + multiplier + '=', 'TAP']
        });
    },
    // 学習履歴九九読み上げ再生タップ
    timestablePlayTap(context, { loginId, content, multiplicand, multiplier }) {
        context.commit("record", {
            message: "九九読み上げ再生タップ",
            loginId: loginId,
            content: content,
            events: [multiplier + '問目', multiplicand + '×' + multiplier + '=', 'TAP 読み上げ']
        });
    },
    // 学習履歴九九読み上げ再生終了
    timestablePlayFinish(context, { loginId, content, multiplicand, multiplier }) {
        context.commit("record", {
            message: "九九読み上げ再生終了",
            loginId: loginId,
            content: content,
            events: [multiplier + '問目', multiplicand + '×' + multiplier + '=', '音声終了']
        });
    },
    // 学習履歴その他のタップイベント
    othersTapEvent(context, { loginId, content, event }) {
        context.commit("record", {
            message: "その他のタップイベント",
            loginId: loginId,
            content: content,
            events: ['TAP ' + event]
        });
    },
    // 学習履歴その他のイベント
    othersEvent(context, { loginId, content, event }) {
        context.commit("record", {
            message: "その他のイベント",
            loginId: loginId,
            content: content,
            events: [event]
        });
    },
    // 制限時間終了イベント
    timeout(context, { loginId, content }) {
        context.commit("record", {
            message: "制限時間終了",
            loginId: loginId,
            content: content,
            events: ['制限時間オーバー']
        });
    },
    // コンテンツクリアイベント
    contentCleare(context, { loginId, content }) {
        context.commit("record", {
            message: "コンテンツクリア",
            loginId: loginId,
            content: content,
            events: ['クリア']
        });
    },
    // コンテンツ未クリアイベント
    contentNotClear(context, { loginId, content }) {
        context.commit("record", {
            message: "コンテンツ未クリア",
            loginId: loginId,
            content: content,
            events: ['未クリア']
        });
    },
    // 1コンテンツの学習時間（”レベル選択ボタン”押下から”おわるボタン”押下までの時間）
    finishContent(context, { loginId, content, time }) {
        const mils = time % 1000;
        const sec = Math.floor(time / 1000) % 60;
        const min = Math.floor(time / 1000 / 60) % 60;
        const hours = Math.floor(time / 1000 / 60 / 60);
        context.commit("record", {
            message: "1コンテンツの学習時間",
            loginId: loginId,
            content: content,
            events: ['1コンテンツの学習時間　'
                + hours
                + ':' + min
                + ':' + sec
                + ':' + mils]
        });
    },
    // 学習履歴コンテンツ累積ログ① 総学習回数（レベル選択画面で、”きくいう”や”○のだん”をタップした際、何回目の学習か？がわかるように）
    cumulativeNumberOfChallenge(context, { loginId, content, challenge }) {
        context.commit("record", {
            message: "コンテンツ累積ログ① 総学習回数",
            loginId: loginId,
            content: content,
            events: ['総学習回数 ' + challenge + '回']
        });
    },
    // 学習履歴コンテンツ累積ログ② クリア回数（レベルごとに、何回目のクリアか？がわかるように）
    cumulativeNumberOfClear(context, { loginId, content, clear }) {
        context.commit("record", {
            message: "コンテンツ累積ログ② クリア回数",
            loginId: loginId,
            content: content,
            events: ['各クリア回数 ' + clear + '回']
        });
    },
    // 学習履歴コンテンツ累積ログ③ 総問題数（上記、”１コンテンツの学習時間”の累積時間）
    cumulativeNumberOfAnswered(context, { loginId, answered }) {
        context.commit("record", {
            message: "コンテンツ累積ログ③ 総問題数",
            loginId: loginId,
            content: '',
            events: ['総問題数 ' + answered + '問']
        });
    },
    // 学習履歴コンテンツ累積ログ④ 総学習時間
    cumulativeTotalLearningTime(context, { loginId, time }) {
        const mils = time % 1000;
        const sec = Math.floor(time / 1000) % 60;
        const min = Math.floor(time / 1000 / 60) % 60;
        const hours = Math.floor(time / 1000 / 60 / 60);
        context.commit("record", {
            message: "コンテンツ累積ログ④ 総学習時間",
            loginId: loginId,
            content: '',
            events: ['総学習時間　'
                + hours
                + ':' + min
                + ':' + sec
                + ':' + mils]
        });
    },
    // 画面ボタン操作イベント
    viewButtonEvent(context, { loginId, view, event, table }) {
        context.commit("record", {
            message: "画面ボタン操作イベント",
            loginId: loginId,
            content: '',
            events: [viewNames[view].replace('{table}', tables[table] ? tables[table] : ''), event]
        });
    },

    // 学習履歴送信
    async post(context, login_id = null) {
        this.$log.debug("学習履歴送信開始")
        login_id ??= context.rootGetters['auth/getLoginId'];
        // 履歴送信
        const url = process.env.VUE_APP_API_PATH_HISTORY.replace('{loginId}', login_id);
        this.$log.debug("学習履歴送信=>%s", url);
        var records = context.getters["getHistory"];
        await this.$axios.post(url, { records }).then((response) => {
            this.$log.debug("学習履歴送信成功");
            if (response.data.result === "success" && response.data.count == records.length) {
                // 履歴クリア
                context.commit("clearHistory");
                this.$log.debug("学習履歴クリア！！");
            }
        }).catch((e) => {
            this.$log.debug("学習履歴送信失敗！！");
            this.$log.debug(e);
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}