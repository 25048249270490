<template>
  <button id="btn-finish" @click="doFinish">おわる</button>
</template>

<script>
const MP_FINISH_START_EVENT = "finish"; // おわるボタン押下時イベント
export default {
  props: {
    // 累積ログ出力有無（true:出力あり、false:出力なし）
    cumulativeLog: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    loginId: function () {
      return this.$store.getters["auth/getLoginId"];
    },
    content: function () {
      return this.$store.getters["app/getCurrentContent"];
    },
    // コンテンツ学習回数
    challengeCount() {
      return this.$store.getters["learningState/getContentChallengeCount"](
        this.content.stage,
        this.content.table,
        this.content.level
      );
    },
    // コンテンツクリア回数
    clearCount() {
      return this.$store.getters["learningState/getContentClearCount"](
        this.content.stage,
        this.content.table,
        this.content.level
      );
    },
    // 総問題数
    answered() {
      return this.$store.getters["learningState/getAnswered"];
    },
    // 総学習時間
    totalTime() {
      return this.$store.getters["learningState/getTotalTime"];
    },
  },
  methods: {
    doFinish() {
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.loginId,
        view: this.$store.getters["app/getCurrentView"],
        event: "おわるボタン",
        table: this.$store.getters["app/getCurrentTable"],
      });
      // おわるボタン押下時イベント
      this.$emit(MP_FINISH_START_EVENT);
      // 累積ログ出力
      if (this.cumulativeLog) {
        // 学習履歴コンテンツ累積ログ① 総学習回数
        this.$store.dispatch("history/cumulativeNumberOfChallenge", {
          loginId: this.loginId,
          content: this.content,
          challenge: this.challengeCount,
        });
        // 学習履歴コンテンツ累積ログ② クリア回数
        this.$store.dispatch("history/cumulativeNumberOfClear", {
          loginId: this.loginId,
          content: this.content,
          clear: this.clearCount,
        });
        // 学習履歴コンテンツ累積ログ③ 総問題数
        this.$store.dispatch("history/cumulativeNumberOfAnswered", {
          loginId: this.loginId,
          answered: this.answered,
        });
        // 学習履歴コンテンツ累積ログ④ 総学習時間
        this.$store.dispatch("history/cumulativeTotalLearningTime", {
          loginId: this.loginId,
          time: this.totalTime,
        });
      }
      // アプリビュー変更（前の画面に戻る）
      var prevView = this.$store.getters["app/getPrevView"];
      this.$store.commit("app/setCurrentViewWithoutHistory", prevView);
    },
  },
};
</script>