<template>
  <div :class="{ locked: locked }">
    <a href="#" @click.prevent.stop="doSelect">
      <span
        v-for="n in numberOfMedals"
        :key="n"
        class="cleared on"
        v-bind:class="['cleared' + n]"
        >&nbsp;</span
      >
      <span class="new" v-bind:class="{ on: !intoroduction && isNew }"
        >New</span
      >
      <div class="btn_level"><slot /></div>
    </a>
    <slot name="footer"></slot>
  </div>
</template>

<script>
const levels = {
  conversation: "きく・いう",
  level1: "レベル1",
  level2: "レベル2",
  level3: "レベル3",
  level4: "レベル4",
  level5: "レベル5",
  level6: "レベル6",
};
export default {
  props: {
    level: {
      type: String,
      required: true,
    },
    view: {
      type: String,
      required: true,
    },
    locked: {
      type: Boolean,
      required: false,
      default: true,
    },
    intoroduction: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    loginId: function () {
      return this.$store.getters["auth/getLoginId"];
    },
    stage() {
      return this.$store.getters["app/getCurrentStage"];
    },
    table() {
      return this.$store.getters["app/getCurrentTable"];
    },
    challengeCount() {
      return this.$store.getters["learningState/getContentChallengeCount"](
        this.stage,
        this.table,
        this.level
      );
    },
    clearCount() {
      return this.$store.getters["learningState/getContentClearCount"](
        this.stage,
        this.table,
        this.level
      );
    },
    isNew() {
      return !this.locked && this.challengeCount == 0;
    },
    numberOfMedals() {
      if (this.clearCount > 3) {
        // メダルは最大３つまで
        return 3;
      }
      return this.clearCount;
    },
  },
  methods: {
    doSelect() {
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: levels[this.level] + "選択",
        table: this.$store.getters["app/getCurrentTable"],
      });
      // レベル選択
      this.$log.debug("選択レベル=>%s", this.level);
      this.$store.dispatch("app/selectLevel", this.level);
      // 学習履歴コンテンツ学習開始
      this.$store.dispatch("history/startContent", {
        loginId: this.loginId,
        content: this.$store.getters["app/getCurrentContent"],
      });
      // コンテンツ学習時間計測開始
      this.$store.commit("app/startLearningTime");
      // コンテンツのチャレンジ回数加算
      this.$store.commit("learningState/incrementContentChallengeCount", {
        stage: this.stage,
        table: this.table,
        level: this.level,
      });
      // ビュー変更
      this.$store.commit("app/setCurrentView", this.view);
    },
  },
};
</script>