<template>
  <div id="container" class="question">
    <slot />
    <header>
      <div id="nav">
        <MpFinish />
        <MpHamburger />
      </div>
    </header>
    <main>
      <div id="message">
        <div class="message-inner"></div>
      </div>
      <div id="content">
        <div id="question">
          <div id="controls">
            <MpTimerLimit
              ref="timer"
              :limit="timerLimit"
              :terminate="timerTerminate"
              @terminate="onTimerTerminate"
              @finish="onTimerFinish"
            />
          </div>
          <MpExpression ref="expression" @marking="onMarking" />
          <MpProgress
            class="progress-multi"
            :max="questionCount"
            :value="answered"
          />
        </div>
        <MpCalculator @enterKey="enterExpression" v-if="!complete" />
      </div>
    </main>
    <MpGuidance
      ref="guidance"
      firstGuidSoundId="bm_level2_first"
      :weaknessResults="weaknessResults"
      @firstGuideFinish="onFirstGuideFinish"
      @weaknessGuideFinish="onWeaknessGuideFinish"
      @beforeFinish="doFinish"
      @retry="doRetry"
      :resultMessageClass="isCleared ? 'result-time' : ''"
      :isBackgoudClear="true"
      :isFinalGuide="true"
    >
      <template v-slot:firstGuide>
        <p>
          ぜんぶで<span class="emphasis"
            ><span class="num">{{ questionCount }}</span
            >もん</span
          ><br />
          <span class="emphasis"
            ><span class="num">{{ timerLimit | minute }}</span
            ><ruby
              ><rb>分</rb><rp>(</rp><rt>{{ timerLimit | minute | ruby }}</rt
              ><rp>)</rp></ruby
            ><span class="num">{{ timerLimit | second }}</span>
            <ruby><rb>秒</rb><rp>(</rp><rt>びょう</rt><rp>)</rp></ruby></span
          >いないでやってみよう！
        </p>
      </template>
      <template v-slot:completionGuide1>
        <div>
          <p class="result-message-title">おめでとう！</p>
          <p class="result-message-body">
            <span
              >{{ time | minute
              }}<ruby
                ><rb>分</rb><rp>(</rp><rt>{{ time | minute | ruby }}</rt
                ><rp>)</rp></ruby
              >{{ time | second
              }}<ruby
                ><rb>秒</rb><rp>(</rp><rt>びょう</rt><rp>)</rp></ruby
              ></span
            >でした。
          </p>
        </div>
      </template>
      <template v-slot:completionGuide2>
        <div class="result-message-title">はやくできたね！</div>
        <div class="result-message-body q-count">
          <div>
            あと<span>{{ remainsBefore }}</span
            >もん
          </div>
          <div class="q-count-img">
            <img :src="$image('question/arrow_result.svg')" alt="" />
          </div>
          <div>
            あと<span>{{ remains }}</span
            >もん
          </div>
        </div>
      </template>
      <template v-slot:completionGuide3>
        <div class="result-message-title">がんばったね！</div>
        <div class="result-message-body">
          あと<span>{{ remains }}</span
          >もんでした。
        </div>
      </template>
    </MpGuidance>
  </div>
</template>

<script>
import MpHamburger from "@/components/ui/MpHamburger";
import MpFinish from "@/components/ui/MpFinish";
import MpTimerLimit from "@/components/ui/MpTimerLimit";
import MpExpression from "@/components/ui/MpExpression";
import MpProgress from "@/components/ui/MpProgress";
import MpCalculator from "@/components/ui/MpCalculator";
import MpGuidance from "@/components/ui/MpGuidance.vue";
import Question from "@/mixins/question";
import Weakness from "@/mixins/weakness";
import Restriction from "@/mixins/restriction";
export default {
  components: {
    MpHamburger,
    MpFinish,
    MpTimerLimit,
    MpExpression,
    MpProgress,
    MpCalculator,
    MpGuidance,
  },
  mixins: [Question, Weakness, Restriction],
  data: function () {
    return {
      timerLimit: 90, // 制限時間（秒）
      timerTerminate: true, // 時間制限（true:あり、false:なし）
      time: 0, // 開始から終了までの時間（秒）
      results: [], // 回答結果
    };
  },
  computed: {
    // 前回クリアできなかった問題数
    remainsBefore: function () {
      if (this.$store.getters["learningState/getTime"](this.content) == 0) {
        // 初めて当該コンテンツを実施する場合は、前回クリアできなかった問題数なし
        return 0;
      }
      // 上記以外は、問題数から前回回答数を引いて前回クリアできなかった問題数を求める
      return (
        this.questionCount -
        this.$store.getters["learningState/getContentResults"](this.content)
          .length
      );
    },
    // 今回クリアできなかった問題数
    remains: function () {
      return this.questionCount - this.answered;
    },
    // クリアしたかどうか
    isCleared: function () {
      return this.remains == 0;
    },
  },
  mounted() {
    // 使用制限抑止
    this.disableRestriction();
    // 問題開始
    this.startExpression();
    // ファーストガイド表示
    this.$refs.guidance.showFirstGuide();
  },
  destroyed() {
    // 使用制限抑止解除
    this.enableRestriction();
  },
  methods: {
    // ファーストガイド終了時
    onFirstGuideFinish() {
      // 同じ段の中で直近に学習したコンテンツの最後に表示した苦手な九九と同じものを表示
      this.weaknessResults = this.$store.getters[
        "learningState/getLatestWeakness"
      ](this.content.stage, this.content.table);
      if (this.isWeakness) {
        // 苦手な九九が存在する場合

        // 苦手な九九ガイド表示
        this.$refs.guidance.showWeaknessGuide();
      } else {
        // 最初の問題出題タイミング
        this.$store.dispatch("history/startQuestion", {
          loginId: this.loginId,
          content: this.content,
          position: this.position,
          multiplicand: this.multiplicand,
          multiplier: this.multiplier,
        });
        // 計測開始
        this.$refs.expression.startInstrument();
        // タイマー開始
        this.$refs.timer.start();
      }
    },
    // 苦手な九九ガイド終了時
    onWeaknessGuideFinish() {
      // 最初の問題出題タイミング
      this.$store.dispatch("history/startQuestion", {
        loginId: this.loginId,
        content: this.content,
        position: this.position,
        multiplicand: this.multiplicand,
        multiplier: this.multiplier,
      });
      // 計測開始
      this.$refs.expression.startInstrument();
      // タイマー開始
      this.$refs.timer.start();
    },
    // 採点時
    onMarking(expression) {
      // 結果判定
      if (expression.result) {
        // 正解の場合

        // 計測終了
        this.$refs.expression.exitInstrument();
        // 結果保存
        this.$log.debug("回答結果保持:%s", JSON.stringify(expression));
        this.results.push(expression);
        // 採点結果判定共通処理
        this.judgementExpression(expression);
        // 式正解
        this.$refs.expression.correct();
      } else {
        // 不正解の場合

        // 採点結果判定共通処理
        this.judgementExpression(expression);
        // 式不正解
        this.$refs.expression.wrong();
      }
    },
    // 次問題出題
    onNext() {
      // 計測開始
      this.$refs.expression.startInstrument();
    },
    // タイマー終了時
    onTimerFinish(total) {
      this.$log.debug("タイマー終了!!=>タイマー時間:%d", total);
      this.time = total;
    },
    // コンテンツ終了
    onComplete() {
      this.$log.debug("クリア画面判定=>かかった時間:%d", this.time);
      this.$log.debug(
        "今回クリアできなかった問題数=>問題数:%d - 回答数:%d = %d",
        this.questionCount,
        this.answered,
        this.remains
      );
      // 計測終了
      this.$refs.expression.exitInstrument();
      // タイマー終了
      this.$refs.timer.exit();
      // 苦手な九九を判定
      this.judgementWeakness(this.results);
      // 苦手な九九を保存
      this.$store.commit("learningState/setLatestWeakness", {
        content: this.content,
        weakness: this.weaknessResults,
      });
      // クリア回数加算
      this.$store.commit(
        "learningState/incrementContentClearCount",
        this.content
      );
    },
    // コンテンツ終了後
    onAfterComplete() {
      // クリア画面表示
      this.$refs.guidance.showCompletionGuide1();
    },
    // タイマー強制終了時
    onTimerTerminate(total) {
      this.$log.debug("タイマー強制終了!!=>タイマー時間:%d", total);
      this.time = total;

      // 制限時間終了イベント
      this.$store.dispatch("history/timeout", {
        loginId: this.loginId,
        content: this.content,
      });

      // タイマー強制処理が終了するまでプロセス停止
      this.$log.debug("プロセス停止");
      this.$store.commit("app/setProcessing", true);

      this.$timer.once(() => {
        this.$log.debug(
          "今回クリアできなかった問題数=>問題数:%d - 回答数:%d = %d",
          this.questionCount,
          this.answered,
          this.remains
        );
        if (!this.isCleared) {
          // 計測終了
          this.$refs.expression.exitInstrument();
          // 苦手な九九を判定
          this.judgementWeakness(this.results);
          // 苦手な九九を保存
          this.$store.commit("learningState/setLatestWeakness", {
            content: this.content,
            weakness: this.weaknessResults,
          });
          if (this.remains < this.remainsBefore) {
            // 前回より良かった場合
            this.$refs.guidance.showCompletionGuide2();
          } else {
            // 前回より良くなかった場合(同じも含む)
            this.$refs.guidance.showCompletionGuide3();
          }
        }
        this.$store.commit("app/setProcessing", false);
        this.$log.debug("プロセス再開");
      }, this.processInterval);
    },
    // もういちど
    doRetry() {
      // 開始から終了までの時間保存
      this.$store.commit("learningState/setTime", {
        content: this.content,
        time: this.time,
      });
      // 回答結果保存
      this.$store.commit("learningState/setContentResults", {
        content: this.content,
        results: this.results,
      });

      // タイマーリセット
      this.$refs.timer.reset();
      // 問題リセット
      this.resetExpression();
      // 回答結果クリア
      this.results = [];
      // 問題開始
      this.startExpression();
      // ファーストガイド表示
      this.$refs.guidance.showFirstGuide();
    },
    // 終了
    doFinish() {
      // 開始から終了までの時間保存
      this.$store.commit("learningState/setTime", {
        content: this.content,
        time: this.time,
      });
      // 回答結果保存
      this.$store.commit("learningState/setContentResults", {
        content: this.content,
        results: this.results,
      });
    },
  },
};
</script>