<template>
  <div id="loading" class="box_serviceworker">
    <div class="inner">
      <img class="content" src="~@/assets/img/loading.gif" />
    </div>
  </div>
</template>
<script>
import ServiceWorker from "@/mixins/serviceWorker";
export default {
  mixins: [ServiceWorker],
  mounted() {
    // オーバーレイ表示
    this.$store.commit("app/setOverlay", true);
  },
  destroyed() {
    if (this.isServiceWorkerUpdated) {
      // アプリケーション更新
      this.appUpdate();
    } else {
      // オーバーレイ非表示
      this.$store.commit("app/setOverlay", false);
    }
  },
};
</script>