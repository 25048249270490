import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app';
import auth from './modules/auth';
import learningState from './modules/learningState';
import history from './modules/history';
import recorded from './modules/recorded';
import soundData from './modules/soundData';
import imageData from './modules/imageData';
import createPersistedState from "vuex-persist-indexeddb";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    auth,
    learningState,
    history,
    recorded,
    soundData,
    imageData,
  },
  plugins: [
    createPersistedState({
      key: 'MultiplicationApp',
      paths: ['auth', 'learningState', 'history', 'recorded', 'soundData', 'imageData'],
    }),
  ],
})
