<template>
  <MpModal class="modal-tool" v-if="isShow">
    <div class="learning-state-editor">
      <p>学習状況編集</p>
      <button @click="doInit">初期化</button>
      <button @click="doUpdate">変更</button>
      <button @click="doRefresh">最新</button>
      <button @click="doClose">閉じる</button>
      <textarea class="editor" v-model="learningState"></textarea>
    </div>
  </MpModal>
</template>

<script>
import MpModal from "@/components/ui/MpModal.vue";
export default {
  components: {
    MpModal,
  },
  data: function () {
    return {
      learningState: {},
    };
  },
  computed: {
    isShow: function () {
      return this.$store.getters["app/isShowLearningStateEditor"];
    },
  },
  mounted() {
    this.learningState = JSON.stringify(
      this.$store.getters["learningState/getState"]
    );
  },
  methods: {
    doInit() {
      this.learningState = JSON.stringify(
        this.$store.getters["learningState/getDefaultState"]
      );
    },
    doUpdate() {
      var newState = JSON.parse(this.learningState);
      this.$store.commit("learningState/copy", newState);
    },
    doRefresh() {
      this.learningState = JSON.stringify(
        this.$store.getters["learningState/getState"]
      );
    },
    doClose() {
      this.$store.commit("app/setShowLearningStateEditor", false);
    },
  },
};
</script>