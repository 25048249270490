export default {
    data: function () {
        return {
            restrictionTimer: null,
        }
    },
    computed: {
        isRestriction() {
            return this.$store.getters["auth/isRestriction"];
        },
        isUnrestricted(){
            return this.$store.getters['auth/getIsUnrestricted'];
        }
    },
    methods: {
        // 使用制限タイマー開始
        startRestrictionTimer() {
            if(this.isUnrestricted){
                this.$log.debug("使用制限タイマー無効");
                return false;
            }
            if (!this.isRestriction) {
                if (this.$store.getters["auth/isRestrictionExpired"]) {
                    // 累積時間カウント後、日付を跨いだ場合
                    this.$log.debug("累積時間カウント後、日付を跨いだので使用制限解除!!");
                    this.$store.commit("auth/resetRestrection");
                } else if (this.$store.getters["auth/getRestrictionStartDateTime"]) {
                    // 使用制限後、一定期間経過後
                    this.$log.debug("使用制限後、一定期間経過後したので使用制限解除!!");
                    this.$store.commit("auth/resetRestrection");
                }
                this.$log.debug("使用制限タイマー開始!!");
                this.$store.dispatch("app/startRestrictionTimer", () => {
                    this.$store.commit("auth/setRestrictionTimerStartDate");
                    this.$store.commit("auth/inclementRestrectionAccumulatedMinute");
                    const accumulatedMinute = this.$store.getters["auth/getRestrictionAccumulatedMinute"];
                    this.$log.debug("連続使用時間=>%d", accumulatedMinute);
                    if (accumulatedMinute >= process.env.VUE_APP_RESTRICTION_AVAILABLE_CONTINUATION_USAGE) {
                        this.$log.debug("使用制限開始!!");
                        this.$store.commit("auth/startRestriction");
                        this.$store.dispatch("app/stopRestrictionTimer");

                        this.$store.dispatch("history/viewButtonEvent", {
                            loginId: this.$store.getters["auth/getLoginId"],
                            view: this.$store.getters["app/getCurrentView"],
                            event: "使用制限開始",
                            table: this.$store.getters["app/getCurrentTable"],
                        });
                    }
                });
            }
        },
        // 使用制限タイマー停止
        stopRestrictionTimer() {
            this.$log.debug("使用制限タイマー停止!!");
            this.$store.dispatch("app/stopRestrictionTimer").then(() => {
                // 使用制限初期化
                this.$store.commit("auth/resetRestrection");
            });
        },
        // 使用制限抑止
        disableRestriction() {
            this.$log.debug("使用制限抑止開始!!");
            this.$store.commit("auth/setRestrictionDisable", true);
        },
        // 使用制限抑止解除
        enableRestriction() {
            this.$store.commit("auth/setRestrictionDisable", false);
            this.$log.debug("使用制限抑止解除!!");
        }

    }
}