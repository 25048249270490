<template>
  <div class="listen-exprs">
    <div class="listen-expr" v-if="weaknessFirst">
      <button
        v-if="showAnswer"
        type="button"
        class="btn-speaker"
        @click="playWeaknessFirst"
        :class="{
          disabled: isPlayWeaknessFirst || isPlayWeaknessSecond,
        }"
      >
        <div class="suggest" v-if="viewSuggestFirst && !isSuggestStop"></div>
      </button>
      <div class="modal-expr">
        <div class="multiplicand">{{ weaknessFirst[0] }}</div>
        <div class="times">×</div>
        <div class="multiplier">{{ weaknessFirst[1] }}</div>
        <div class="equal">=</div>
        <div class="product">
          {{ showAnswer ? weaknessFirst[0] * weaknessFirst[1] : "" }}
        </div>
      </div>
      <button
        v-if="!showAnswer"
        type="button"
        class="btn-speaker"
        @click="playWeaknessFirst"
        :class="{
          disabled: isPlayWeaknessFirst || isPlayWeaknessSecond,
        }"
      >
        <div class="suggest" v-if="viewSuggestFirst && !isSuggestStop"></div>
      </button>
    </div>
    <div class="listen-expr" v-if="weaknessSecond">
      <button
        v-if="showAnswer"
        type="button"
        class="btn-speaker"
        @click="playWeaknessSecond"
        :class="{
          disabled: isPlayWeaknessFirst || isPlayWeaknessSecond,
        }"
      >
        <div class="suggest" v-if="viewSuggestSecond && !isSuggestStop"></div>
      </button>
      <div class="modal-expr">
        <div class="multiplicand">{{ weaknessSecond[0] }}</div>
        <div class="times">×</div>
        <div class="multiplier">{{ weaknessSecond[1] }}</div>
        <div class="equal">=</div>
        <div class="product">
          {{ showAnswer ? weaknessSecond[0] * weaknessSecond[1] : "" }}
        </div>
      </div>
      <button
        v-if="!showAnswer"
        type="button"
        class="btn-speaker"
        @click="playWeaknessSecond"
        :class="{
          disabled: isPlayWeaknessFirst || isPlayWeaknessSecond,
        }"
      >
        <div class="suggest" v-if="viewSuggestSecond && !isSuggestStop"></div>
      </button>
    </div>
  </div>
</template>

<script>
const MP_WEAKNESS_MOUNTED_EVENT = "mounted"; // 苦手な九九マウント時イベント
const MP_WEAKNESS_LISTEN_ALL_EVENT = "listenAll"; // 苦手な九九を全て聞いた時イベント
const MP_WEAKNESS_LISTEN_START = "listenStart"; // 苦手な九九再生開始
const MP_WEAKNESS_LISTEN_FINISH = "listenFinish"; // 苦手な九九再生終了
export default {
  props: {
    // 苦手な九九リスト
    results: {
      type: Array,
      default: () => [],
    },
    showAnswer: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: function () {
    return {
      weaknessFirst: null, // 苦手な九九１
      weaknessSecond: null, // 苦手な九九２
      isPlayWeaknessFirst: false, // 苦手な九九１再生中
      isPlayWeaknessSecond: false, // 苦手な九九２再生中
      idPlayedWeaknessFirst: true, // 苦手な九九１再生有無（true:再生済みまたは苦手な九九なし、false:未再生）
      idPlayedWeaknessSecond: true, // 苦手な九九２再生中回数（true:再生済みまたは苦手な九九なし、false:未再生）
      viewSuggestFirst: false, // 苦手な九九１指マーク表示有無（true:表示、false:非表示）
      viewSuggestSecond: false, // 苦手な九九２指マーク表示有無（true:表示、false:非表示）
      viewSuggestTiming: 2000, // 苦手な九九指マークの１→２移動間隔（ミリ秒）
      isSuggestStop: false, // 苦手な九九指マーク停止有無（true:停止、false:継続）
    };
  },
  computed: {
    loginId: function () {
      return this.$store.getters["auth/getLoginId"];
    },
    content: function () {
      return this.$store.getters["app/getCurrentContent"];
    },
  },
  watch: {
    isSuggestStop: function (val) {
      if (val) {
        this.viewSuggestFirst = false;
        this.viewSuggestSecond = false;
      }
    },
  },
  mounted() {
    // 苦手な九九設定
    if (this.results.length >= 1) {
      // 学習履歴学習後苦手な九九1問目
      this.$store.dispatch("history/weakness", {
        loginId: this.loginId,
        content: this.content,
        position: 1,
        multiplicand: this.results[0].multiplicand,
        multiplier: this.results[0].multiplier,
      });
      // 苦手な九九1
      this.weaknessFirst = [
        this.results[0].multiplicand,
        this.results[0].multiplier,
      ];
      this.idPlayedWeaknessFirst = false;
      this.$log.debug("苦手な九九1:%s", JSON.stringify(this.weaknessFirst));
    }
    if (this.results.length >= 2) {
      // 学習履歴学習後苦手な九九2問目
      this.$store.dispatch("history/weakness", {
        loginId: this.loginId,
        content: this.content,
        position: 2,
        multiplicand: this.results[1].multiplicand,
        multiplier: this.results[1].multiplier,
      });
      // 苦手な九九2
      this.weaknessSecond = [
        this.results[1].multiplicand,
        this.results[1].multiplier,
      ];
      this.idPlayedWeaknessSecond = false;
      this.$log.debug("苦手な九九2:%s", JSON.stringify(this.weaknessSecond));
    }

    // 苦手な九九マウント時イベント
    this.$emit(MP_WEAKNESS_MOUNTED_EVENT);
  },
  methods: {
    // 苦手な九九に指マークを順番に表示
    showWeaknessSuggest() {
      this.$log.debug("苦手な九九指マーク表示開始１");
      if (this.isSuggestStop) return;
      this.viewSuggestFirst = true;
      this.$timer.once(() => {
        this.$log.debug("苦手な九九指マーク表示開始２");
        this.viewSuggestFirst = false;
        if (this.isSuggestStop) return;
        this.viewSuggestSecond = true;
        this.$timer.once(() => {
          this.viewSuggestSecond = false;
          this.$log.debug("苦手な九九指マーク表示終了");
        }, this.viewSuggestTiming);
      }, this.viewSuggestTiming);
    },
    // 1番目の苦手な九九読み上げ
    playWeaknessFirst() {
      // 指マーク停止
      this.isSuggestStop = true;
      if (!this.weaknessFirst || this.weaknessFirst.length == 0) return;
      this.$log.debug(
        "1番目の苦手な九九読み上げ(%dx%d)",
        this.weaknessFirst[0],
        this.weaknessFirst[1]
      );
      // 学習履歴苦手な九九タップ
      this.$store.dispatch("history/weaknessTap", {
        loginId: this.loginId,
        content: this.content,
        position: 1,
        multiplicand: this.weaknessFirst[0],
        multiplier: this.weaknessFirst[1],
      });
      // ボタン抑止
      this.isPlayWeaknessFirst = true;
      // 苦手な九九再生開始イベント
      this.$emit(MP_WEAKNESS_LISTEN_START);
      this.$sound
        .multiplication(this.weaknessFirst[0], this.weaknessFirst[1])
        .play(() => {
          // ボタン抑止解除
          this.isPlayWeaknessFirst = false;
          // 苦手な九九再生終了イベント
          this.$emit(MP_WEAKNESS_LISTEN_FINISH);

          // 再生済み
          this.idPlayedWeaknessFirst = true;

          // 全て聞いたか判定
          if (this.idPlayedWeaknessSecond) {
            // 苦手な九九を全て聞いた時のイベント送信
            this.$emit(MP_WEAKNESS_LISTEN_ALL_EVENT);
          }
        });
    },

    // 2番目の苦手な九九読み上げ
    playWeaknessSecond() {
      // 指マーク停止
      this.isSuggestStop = true;
      if (!this.weaknessSecond || this.weaknessSecond.length == 0) return;
      this.$log.debug(
        "2番目の苦手な九九読み上げ(%dx%d)",
        this.weaknessSecond[0],
        this.weaknessSecond[1]
      );
      // 学習履歴苦手な九九タップ
      this.$store.dispatch("history/weaknessTap", {
        loginId: this.loginId,
        content: this.content,
        position: 2,
        multiplicand: this.weaknessSecond[0],
        multiplier: this.weaknessSecond[1],
      });
      // ボタン抑止
      this.isPlayWeaknessSecond = true;
      // 苦手な九九再生開始イベント
      this.$emit(MP_WEAKNESS_LISTEN_START);
      this.$sound
        .multiplication(this.weaknessSecond[0], this.weaknessSecond[1])
        .play(() => {
          // ボタン抑止解除
          this.isPlayWeaknessSecond = false;
          // 苦手な九九再生終了イベント
          this.$emit(MP_WEAKNESS_LISTEN_FINISH);

          // 再生済み
          this.idPlayedWeaknessSecond = true;

          // 全て聞いたか判定
          if (this.idPlayedWeaknessFirst) {
            // 苦手な九九を全て聞いた時のイベント送信
            this.$emit(MP_WEAKNESS_LISTEN_ALL_EVENT);
          }
        });
    },
  },
};
</script>