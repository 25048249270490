<template>
  <div id="container" class="menu-bonus">
    <slot />
    <header>
      <h1>うちゅう</h1>
      <div id="nav">
        <button id="list" @click="doTimesTable">九九のいちらん</button>
        <MpHamburger />
      </div>
    </header>
    <main>
      <div id="stages">
        <div class="stage-row row-1st">
          <MpBonusStage
            :table="sequence.table6and7"
            addtionalStarClass="star1"
            starIcon="6_7.png"
          />
          <MpBonusStage
            :table="sequence.table7and8"
            addtionalStarClass="star2"
            starIcon="7_8.png"
          />
          <MpBonusStage
            :table="sequence.table8and9"
            addtionalStarClass="star3"
            starIcon="8_9.png"
          />
          <MpBonusStage
            :table="sequence.table6and9"
            addtionalStarClass="star4"
            starIcon="6_9.png"
          />
          <MpBonusStage
            :table="sequence.table4and7"
            addtionalStarClass="star5"
            starIcon="4_7.png"
          />
        </div>
        <div class="stage-row row-2nd">
          <MpBonusStage
            :table="sequence.table6to8"
            addtionalStarClass="star6"
            starIcon="6-8.png"
          />
          <MpBonusStage
            :table="sequence.table7to9"
            addtionalStarClass="star7"
            starIcon="7-9.png"
          />
          <MpBonusStage
            :table="sequence.table2to5"
            addtionalStarClass="star8"
            starIcon="2-5.png"
          />
          <MpBonusStage
            :table="sequence.table6to9"
            addtionalStarClass="star9"
            starIcon="6-9.png"
          />
          <MpBonusStage
            :table="sequence.table2to9"
            addtionalStarClass="star10"
            starIcon="2-9.png"
          />
        </div>
        <div class="btn-box">
          <button type="button" @click="doBackNormal">
            うみにもどる
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MpHamburger from "@/components/ui/MpHamburger";
import MpBonusStage from "@/components/ui/MpBonusStage";
import Bonus from "@/mixins/bonus";
export default {
  components: {
    MpHamburger,
    MpBonusStage,
  },
  mixins: [Bonus],
  created() {
    // 横レイアウト強制
    this.$log.debug("横レイアウト強制");
    this.$store.commit("app/enforceOrientationLandscape");
  },
  mounted() {
    // ボーナスステージ選択
    this.$store.dispatch("app/selectStage", "bonus");
  },
  methods: {
    // 九九いちらん表示
    doTimesTable() {
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "九九いちらん表示",
      });
      // 画面遷移
      this.$store.commit("app/setCurrentView", "ViewTimesTable");
    },
    // ノーマルステージ表示
    doBackNormal() {
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "うみにもどる",
      });
      // 画面遷移
      this.$store.commit("app/setCurrentView", "ViewStageNormal");
    },
  },
};
</script>