<template>
  <div id="loading" class="box">
    <div class="inner">
      <img class="content" src="~@/assets/img/loading.gif" />
    </div>
    <span v-show="false">{{ step }}</span>
  </div>
</template>

<script>
import Restriction from "@/mixins/restriction";
import Activity from "@/mixins/activity";
import ServiceWorker from "@/mixins/serviceWorker";
export default {
  mixins: [Restriction, Activity, ServiceWorker],
  data: function () {
    return {
      step: 0, // ローディングステップ（0:ロード開始、1:データストローディング、2:サービスワーカーローディング、3:APPローディング、4:APP開始）
      checkLogin: false, // ログインチェック状態（true:チェック済み、false:未チェック）
      storeTimeout: 5, // データストア待機時間（秒）
    };
  },
  computed: {
    isLoginValid() {
      return this.$store.getters["auth/isLoginValid"];
    },
    isStoreReady() {
      return this.$store.getters["auth/isReady"];
    },
    isInitializeSkip() {
      return process.env.VUE_APP_SKIP_INITIALIZE.toUpperCase() === "ON";
    },
    appVersion(){
      return this.$store.state.learningState.appVersion;
    },
    isResourceLoaded() {
      return Object.keys(this.$store.state.soundData.data).length > 0 &&
        Object.keys(this.$store.state.imageData.data).length > 0;
    },
  },
  mounted() {
    //  データストアローディング
    this.step += 1;
    this.$log.debug(
      "【ローディング】ステップ=>%s(データストアローディング)",
      this.step
    );
    var timer = this.$timer.recursive(
      () => {
        this.$log.debug("データストア準備待機=>%s", this.isStoreReady);
        if (this.isStoreReady) {
          // サービスワーカーローディング開始
          this.serviceWorkerLoging();
          timer.exit();
        }
      },
      () => {
        this.$log.debug("データストア準備待機タイムアウト！！");
        // サービスワーカーローディング開始
        this.serviceWorkerLoging();
      },
      1000,
      this.storeTimeout
    );
  },
  methods: {
    serviceWorkerLoging() {
      this.step += 1;
      this.$log.debug(
        "【ローディング】ステップ=>%s(サービスワーカーローディング)",
        this.step
      );
      if (this.isServiceWorker) {
        var timer = this.$timer.recursive(
          () => {
            this.$log.debug(
              "サービスワーカー起動状態チェック=>%s",
              this.isServiceWorkerReady
            );
            if (this.isServiceWorkerReady) {
              // APPローディング
              this.appLoading();
              timer.exit();
            }
          },
          () => {
            this.$log.debug("サービスワーカー起動状態チェックタイムアウト！！");
            // APPローディング
            this.appLoading();
          },
          1000,
          this.serviceWorkerTimeout
        );
      } else {
        // APPローディング
        this.appLoading();
      }
    },
    appLoading() {
      this.step += 1;
      this.$log.debug(
        "【ローディング】ステップ=>%s(APPローディング)",
        this.step
      );
      this.$log.debug(
        "【ローディング】APPローディング=>%s",
        this.isInitializeSkip ? "スキップ" : "開始"
      );
      if (this.isInitializeSkip) {
        // アプリケーション開始
        this.startApp();
      } else {
        this.loadAssets()
          .catch(() => {
            // アセット読み込み失敗
            this.$log.debug(
              "【起動とログイン】アセット読み込み失敗"
            );
          });
        // ログイン有効チェック
        this.$log.debug("【起動とログイン】ログイン有効チェック");
        this.checkLogin = true;
        if (this.isLoginValid) {
          // ログイン有効
          this.$log.debug("【起動とログイン】ログイン有効");
          if (this.isOnline()) {
            // トークンリフレッシュ
            this.$store
              .dispatch("auth/refresh")
              .then(() => {
                // 学習状況最新化
                this.$log.debug("【起動とログイン】学習状況最新化");
                this.$store
                  .dispatch("learningState/refresh")
                  .then(() => {
                    // 学習状況最新化成功
                    this.$log.debug("【起動とログイン】学習状況最新化成功");
                    // 学習状況送信
                    this.$log.debug("【起動とログイン】ローカル学習状況送信");
                    this.$store.dispatch("learningState/post");
                    // 学習履歴送信
                    this.$log.debug("【起動とログイン】学習履歴送信");
                    this.$store.dispatch("history/post");
                    this.$log.debug("【起動とログイン】アセット読み込み開始");

                    this.startApp();
                  })
                  .catch(() => {
                    // NGまたはオフライン
                    this.$log.debug(
                      "【起動とログイン】学習状況最新化NGまたはオフライ"
                    );
                    // アプリケーション開始
                    this.startApp();
                  });
              })
              .catch(() => {
                // トークンリフレッシュ失敗
                this.$log.debug("【起動とログイン】トークンリフレッシュ失敗");
                // アプリケーション開始
                this.startApp();
              });
          } else {
            // オフライン

            // アプリケーション開始
            this.startApp();
          }
        } else {
          // ログイン無効
          this.$log.debug("【起動とログイン】ログイン無効");
          // ログイン日時リセット
          this.$store.commit("auth/resetLoginDateTime");
          // ログイン画面表示
          this.$log.debug("【起動とログイン】ログイン画面表示");
          this.checkInstall(() => {
            this.$store.commit("app/setCurrentView", "ViewLogin");
          });
        }
      }
    },
    startApp() {
      this.checkInstall(()=>{ // インストール状況をチェック
        this.step += 1;
        this.$log.debug(
          "【ローディング】ステップ=>%s(APPスタート！！)",
          this.step
        );
        // バージョン設定
        this.$store.commit("learningState/setAppVersion", this.$version);
        // 使用制限タイマー開始
        this.startRestrictionTimer();

        if (this.isResourceLoaded) {
          this.setCssVarForImages();
        }
        // ノーマルステージへ遷移
        this.$store.commit("app/setCurrentView", "ViewStageNormal");
      });
    },
    checkInstall(callback){
      // ホームにインストールされているか確認する
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      let utm_source = params.get("utm_source");
      let ua = navigator.userAgent.toLowerCase();

      let next = ()=>{
        this.$store.commit('app/setPwa', true);
        callback();
      }
      let prompt = ()=>{
        this.$store.commit("app/setCurrentView", "InstallPrompt");
      }
      if(utm_source === 'pwa') { // パラメータがある時
        next();
      }else if(!('ontouchstart' in window) || ua.indexOf('silk') !== -1){ // PCの時
        next();
      }else{
        prompt();
      }
    },
    loadAssets() {
      const isVersionUpdated = this.appVersion !== this.$version && this.isOnline() && this.appVersion !== null;
      const isResourceLoaded = this.isResourceLoaded;
      this.$log.debug("【起動とログイン】アセット 前バージョン: %s, 現バージョン: %s", this.appVersion, this.$version);
      this.$log.debug("【起動とログイン】アセット isVersionUpdated: %s", isVersionUpdated);
      this.$log.debug("【起動とログイン】アセット isResourceLoaded: %s", isResourceLoaded);
      if (isVersionUpdated || !isResourceLoaded) {
        this.$log.debug("【起動とログイン】アセット読み込み実行");
        return Promise.all([
          this.$store.dispatch('soundData/loadSounds'),
          this.$store.dispatch('imageData/loadImages'),
        ]);
      }

      this.$log.debug("【起動とログイン】アセット読み込みスキップ");
      return Promise.resolve();
    },
    setCssVarForImages() {
      const vars = {
        'normal-island1_locked': 'normal/island1_locked.svg',
        'normal-island2_locked': 'normal/island2_locked.svg',
        'normal-island3_locked': 'normal/island3_locked.svg',
        'bg_bonus': 'bg_bonus.svg',
        'speaker': 'speaker.svg',
        'level-medal': 'level/medal.svg',
        'level-arrow_left_locked': 'level/arrow_left_locked.svg',
        'level-arrow_right_locked': 'level/arrow_right_locked.svg',
        'tooltip_arrow_reverse': 'tooltip_arrow_reverse.svg',
        'question-timer1': 'question/timer1.svg',
        'question-timer2': 'question/timer2.svg',
        'question-timer3': 'question/timer3.svg',
        'question-timer4': 'question/timer4.svg',
        'question-timer5': 'question/timer5.svg',
        'question-timer6': 'question/timer6.svg',
        'question-timer_intime': 'question/timer_intime.svg',
        'question-timer_timeover': 'question/timer_timeover.svg',
        'question-hintlimit_disable': 'question/hintlimit_disable.svg',
        'question-tooltip_arrow_hint': 'question/tooltip_arrow_hint.svg',
        'finger': 'finger.svg',
        'ok': 'ok.svg',
        'ng': 'ng.svg',
        'tooltip_arrow_blue': 'tooltip_arrow_blue.svg',
        'question-bg_stageclear': 'question/bg_stageclear.png',
        'question-bg_allclear': 'question/bg_allclear.png',
        'speaker_disable': 'speaker_disable.svg',
        'numpad-p_clear': 'numpad/p_clear.svg',
        'numpad-n_clear': 'numpad/n_clear.svg',
        'numpad-p_enter': 'numpad/p_enter.svg',
        'numpad-n_enter': 'numpad/n_enter.svg',
        'light': 'light.svg',
        'light_disable': 'light_disable.svg',
      };

      const paths7steps = [1, 2, 4, 6, 8, 10, 12, 14];

      for (let path = 1; path <= 15; path++) {
        const stepCount = (paths7steps.includes(path)) ? 7 : 2;
        for (let step = 0; step <= stepCount; step++) {
          vars[`normal-path${path}-step${step}`] = `normal/path${path}/step${step}.svg`;
        }
      }

      for (let i = 1; i <= 6; i++) {
        vars[`level-tobi${i}`] = `level/tobi${i}.svg`;
        vars[`level-tobi${i}_locked`] = `level/tobi${i}_locked.svg`;
      }

      for (let i = 0; i <= 9; i++) {
        vars[`numpad-n_${i}`] = `numpad/n_${i}.svg`;
        vars[`numpad-p_${i}`] = `numpad/p_${i}.svg`;
      }

      for (const key in vars) {
        document.documentElement.style.setProperty(`--bg-image-${key}`, `url("${this.$image(vars[key])}")`);
      }
    }
  },
};
</script>