import moment from 'moment';

const Logger = {
    install(Vue) {
        var traceLog = process.env.VUE_APP_TRACE_LOG.toUpperCase();
        var logLevel = process.env.VUE_APP_LOG_LEVEL.toUpperCase();
        Vue.prototype.$log = {
            error(...args) {
                args[0] = "[ERROR][" + moment().format("YYYY-MM-DD HH:mm:ssZ") + "]" + args[0];
                console.error.apply(this, args)
            },
            warn(...args) {
                if (logLevel === "ERROR") {
                    // ログレベル:ERRORでは、出力抑止
                    return;
                }
                args[0] = "[WARN][" + moment().format("YYYY-MM-DD HH:mm:ssZ") + "]" + args[0];
                console.warn.apply(this, args)
            },
            info(...args) {
                if (logLevel === "ERROR" || logLevel === "WARN") {
                    // ログレベル:ERROR、WARNでは、出力抑止
                    return;
                }
                args[0] = "[INFO][" + moment().format("YYYY-MM-DD HH:mm:ssZ") + "]" + args[0];
                console.info.apply(this, args)
            },
            debug(...args) {
                if (logLevel === "ERROR" || logLevel === "WARN" || logLevel === "INFO") {
                    // ログレベル:ERROR、WARN、INFOでは、出力抑止
                    return;
                }
                args[0] = "[DEBUG][" + moment().format("YYYY-MM-DD HH:mm:ssZ") + "]" + args[0];
                console.debug.apply(this, args)
            },
            trace(...args) {
                if (traceLog === "ON") {
                    // トレースログonの場合のみ出力
                    args[0] = "[TRACE][" + moment().format("YYYY-MM-DD HH:mm:ssZ") + "]" + args[0];
                    console.debug.apply(this, args)
                }
            },
            log(...args) {
                if (process.env.NODE_ENV !== 'production') {
                    console.log.apply(this, args);
                }
            },
        };
    }
}

export default Logger;