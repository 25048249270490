<template>
  <div id="time">
    <div
      id="time-limit"
      :class="[{ timeup: timeup && !terminate }, timerClass]"
    >
      {{ minit }}<span>:</span>{{ second | padding }}
    </div>
    <slot />
  </div>
</template>

<script>
const MP_TIMER_LIMIT_STSRT_EVENT = "start"; // タイマー開始直前（初期化後）のイベント
const MP_TIMER_LIMIT_FINISH_EVENT = "finish"; // タイマー終了直後のイベント（パラメータ:トータル時間）
const MP_TIMER_LIMIT_TERMINATE_EVENT = "terminate"; // タイマー強制終了イベント（パラメータ:トータル時間）
export default {
  filters: {
    padding: function (value) {
      return ("00" + value).slice(-2);
    },
  },
  props: {
    // 制限時間（秒）
    limit: {
      type: Number,
      required: true,
    },
    // タイマー終了有無（true:終了あり、false:終了なし）
    terminate: {
      type: Boolean,
      required: false,
      default: false,
    },
    // タイマータイプ（true:カウントアップ、false:カウントダウン）
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    timerClass: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      remaining: this.limit, // 残り時間（秒）
      total: 0, // トータル時間（秒）
      timeup: false, // タイマータイムアップ
      timer: null, // タイマーインスタンス
    };
  },
  computed: {
    // 分
    minit() {
      return this.remaining < 0
        ? this.terminate
          ? 0
          : Math.floor((this.remaining * -1) / 60)
        : Math.floor(this.remaining / 60);
    },
    // 秒
    second() {
      return this.remaining < 0
        ? this.terminate
          ? 0
          : (this.remaining * -1) % 60
        : this.remaining % 60;
    },
  },
  destroyed() {
    // タイマー終了
    this.exit();
  },
  methods: {
    // タイマーリセット
    reset() {
      this.remaining = this.reverse ? 0 : this.limit;
      this.total = 0;
      this.timeup = false;
      this.timer = null;
    },

    // タイマー開始
    start() {
      this.$log.debug("タイマー開始");
      this.$log.debug("タイマー初期化");
      this.remaining = this.reverse ? 0 : this.limit;
      // タイマー開始イベント
      this.$emit(MP_TIMER_LIMIT_STSRT_EVENT);
      this.$log.debug("タイマー起動");
      this.timer = this.$timer.recursive(
        () => {
          // トータル時間
          this.total++;
          // 残り時間
          this.reverse ? this.remaining++ : this.remaining--;
          if (!this.reverse && this.remaining < 0) {
            // タイムアップ
            this.timeup = true;
            if (this.terminate) {
              this.$log.debug("タイマー強制終了開始！！");
              // タイマー終了
              this.timer.exit();
              // タイマー強制終了イベント
              this.$emit(MP_TIMER_LIMIT_TERMINATE_EVENT, this.total);
            }
          } else {
            if (this.terminate && this.remaining >= this.limit) {
              this.$log.debug("タイマー強制終了開始！！");
              // タイマー終了
              this.timer.exit();
              // タイマー強制終了イベント
              this.$emit(MP_TIMER_LIMIT_TERMINATE_EVENT, this.total);
            }
          }
        },
        () => {},
        1000,
        0
      );
    },

    // タイマー停止
    pause() {
      this.$log.debug("タイマー停止");
      this.timer.pause();
    },

    // タイマー再開
    resume() {
      this.$log.debug("タイマー再開");
      this.timer.resume();
    },

    // タイマー終了
    exit() {
      this.$log.debug("タイマー終了");
      if (this.timer) {
        this.timer.exit();
        // タイマー終了イベント
        this.$emit(MP_TIMER_LIMIT_FINISH_EVENT, this.total);
      }
    },
  },
};
</script>