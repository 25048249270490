<template>
  <div id="expr">
    <div class="expr-inner">
      <div id="result" :class="resultClass">&nbsp;</div>
      <div id="expr-content">
        <div id="multiplicand">{{ multiplicand }}</div>
        <div id="times">×</div>
        <div id="multiplier">{{ multiplier }}</div>
        <div id="equal">=</div>
        <div id="product">
          <input
            type="text"
            name="answer"
            id="answer"
            :value="value"
            maxlength="3"
            disabled="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const MP_EXPRESSION_MARKING_EVENT = "marking"; // 採点時のイベント（引数:被乗数,乗数,採点結果,計測時間）
export default {
  data: function () {
    return {
      potision: "", // 問題番号
      multiplicand: "", // 被乗数
      multiplier: "", // 乗数
      value: "", // 入力値
      resultClass: "", // 結果クラス
      instrumentTime: 0, // 計測時間（秒）
      instrumentTimer: null, // 時間計測タイマーインスタンス
    };
  },
  computed: {
    loginId: function () {
      return this.$store.getters["auth/getLoginId"];
    },
    content: function () {
      return this.$store.getters["app/getCurrentContent"];
    },
    answer: function () {
      var multiplicand = !isNaN(this.multiplicand) ? this.multiplicand : 0;
      var multiplier = !isNaN(this.multiplier) ? this.multiplier : 0;
      return multiplicand * multiplier;
    },
  },
  destroyed() {
    // 時間継続タイマー停止
    if (this.instrumentTimer) this.instrumentTimer.exit();
  },
  methods: {
    // 出題開始
    start(potision, multiplicand, multiplier) {
      this.$log.debug(
        "問題%d 出題開始:%dx%d",
        potision,
        multiplicand,
        multiplier
      );
      this.position = potision;
      this.multiplicand = multiplicand;
      this.multiplier = multiplier;
      this.value = "";
      this.resultClass = "";
      this.instrumentTime = 0;
      if (this.instrumentTimer) this.instrumentTimer.exit();
      this.instrumentTimer = null;
    },
    // 問題再開
    restart() {
      this.resultClass = "";
    },
    // 時間計測開始
    startInstrument() {
      // 時間計測タイマースタート
      this.$log.debug(
        "時間計測開始:" + this.multiplicand + "x" + this.multiplier
      );
      this.instrumentTimer = this.$timer.recursive(
        () => {
          this.instrumentTime++;
        },
        () => {},
        1000,
        0
      );
    },
    // 時間計測停止
    pauseInstrument() {
      if (!this.instrumentTimer) return;
      this.$log.debug(
        "時間計測停止:" + this.multiplicand + "x" + this.multiplier
      );
      this.instrumentTimer.pause();
    },
    // 時間計測再開
    resumeInstrument() {
      if (!this.instrumentTimer) return;
      this.$log.debug(
        "時間計測再開:" + this.multiplicand + "x" + this.multiplier
      );
      this.instrumentTimer.resume();
    },
    // 時間計測終了
    exitInstrument() {
      // 計測タイマー停止
      if (this.instrumentTimer) {
        this.$log.debug(
          "時間計測終了:" + this.multiplicand + "x" + this.multiplier
        );
        this.instrumentTimer.exit();
      }
    },
    // キー入力
    enter(key) {
      this.$log.debug("入力キー:" + key);
      if (key == "clear") {
        // 学習履歴クリアキー操作
        this.$store.dispatch("history/clear", {
          loginId: this.loginId,
          content: this.content,
          position: this.position,
          multiplicand: this.multiplicand,
          multiplier: this.multiplier,
        });
        // クリアの場合、全部の数字が消える
        this.value = "";
      } else if (key == "enter") {
        // Enterの場合、採点
        this.marking();
      } else {
        // 学習履歴10キー操作
        this.$store.dispatch("history/tenKey", {
          loginId: this.loginId,
          content: this.content,
          position: this.position,
          multiplicand: this.multiplicand,
          multiplier: this.multiplier,
          keyCode: key,
        });
        var len = String(this.value).length;
        if (len < 3) {
          // 数字の場合、最大３桁まで後ろにつなげる
          this.value += key;
        }
        if (this.value.length >= 2 && this.value.slice(0, 1) === "0") {
          // 2桁以上で入力の最初が0の場合、0を削除
          this.value = this.value.slice(1);
        }
      }
    },
    // 採点開始
    marking() {
      // 採点
      this.$log.debug("採点します！");
      this.$log.debug("答え:" + this.answer);
      var value = this.value;
      this.$log.debug("回答:" + value);
      var result = String(this.answer) === String(value);
      this.$log.debug("結果:" + result);
      // エンターキー操作
      this.$store.dispatch("history/enter", {
        loginId: this.loginId,
        content: this.content,
        position: this.position,
        multiplicand: this.multiplicand,
        multiplier: this.multiplier,
        value: this.value,
        answer: this.answer,
        result: result,
      });
      // 親画面に結果を送信
      this.$emit(MP_EXPRESSION_MARKING_EVENT, {
        multiplicand: this.multiplicand,
        multiplier: this.multiplier,
        result: result,
        instrumentTime: this.instrumentTime,
      });
    },
    // 正解
    correct() {
      // 正解画像表示
      this.resultClass = "correct";
      // 正解音再生
      this.$sound.ok().play();
    },
    // 不正解
    wrong() {
      // 不正解画像表示
      this.resultClass = "wrong";
      // 不正解音再生
      this.$sound.ng().play();
      // 誤答の場合は、「→」押下時に回答が消える
      this.value = "";
    },
  },
};
</script>