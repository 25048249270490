var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"hint"}},[(_vm.hintLimit == 0)?_c('button',{staticClass:"btn-light",class:{
      disabled: _vm.showHint || _vm.isProcessing || _vm.disabledClass || _vm.hintClosing,
      suggest: !_vm.complete && _vm.showHintHand,
      pickup: _vm.pickup,
    },attrs:{"id":"hintButton","type":"button","disabled":_vm.disabled},on:{"click":function($event){return _vm.doHint(false)}}}):_vm._e(),(_vm.hintLimit > 0)?_c('div',{attrs:{"id":"hint-limited"}},[_c('button',{staticClass:"btn-light",class:{
        disabled:
          _vm.showHint ||
          _vm.isProcessing ||
          _vm.disabledClass ||
          _vm.hintClosing ||
          _vm.hintUsed <= 0,
        suggest: !_vm.complete && _vm.showHintHand,
        pickup: _vm.pickup,
      },attrs:{"type":"button","disabled":_vm.disabled || _vm.showHint || _vm.isProcessing || _vm.hintClosing || _vm.hintUsed <= 0},on:{"click":_vm.doLimitedHint}},[_vm._v("   ")]),_vm._l((_vm.hintLimit),function(n){return _c('span',{key:n,class:{ used: n > _vm.hintUsed }},[_c('img',{attrs:{"src":_vm.$image('question/hintlimit.svg'),"alt":"ヒント"}})])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }