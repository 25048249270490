<template>
<div v-if="shouldShowIllust">
  <slot></slot>
</div>
<div class="placeholder" v-else>
  {{ count }}
</div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    answered: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    shouldShowIllust() {
      return this.answered >= this.count
    }
  },
};
</script>