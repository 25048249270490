<template>
  <div id="container" class="level">
    <slot />
    <header>
      <div id="nav">
        <MpHamburger />
      </div>
    </header>
    <h1>{{ title }}のだん</h1>
    <div id="current-stage">
      <img
        :src="$image(`level/level${times}.png`)"
        :alt="times + 'のだん'"
      />
    </div>
    <main class="main-single" :class="{ intoroduction: isIntroduction }">
      <div id="grid-container" class="single">
        <div id="prev" class="grid-item stage-select">
          <MpLevelPrev v-if="prev" :table="prev" />
        </div>
        <MpLevel
          id="listen"
          class="grid-item"
          level="conversation"
          view="ViewLevelConversation"
          :locked="false"
          :class="{ intoro: intoroductionStep >= 1 }"
          :intoroduction="isIntroduction"
        >
          <div>き<span class="before_dot">く</span>・いう</div>
        </MpLevel>
        <div id="level-box">
          <MpLevel
            id="level1"
            class="grid-item"
            level="level1"
            view="ViewLevelNormalSingle1"
            :locked="!isIntroduction && !isContentClear('conversation')"
            :class="['level-select', { intoro: intoroductionStep >= 2 }]"
            :intoroduction="isIntroduction"
          >
            レベル<span class="level-num">1</span>
          </MpLevel>
          <MpLevel
            id="level2"
            class="grid-item"
            level="level2"
            view="ViewLevelNormalSingle2"
            :locked="!isIntroduction && !isContentClear('level1')"
            :class="['level-select', { intoro: intoroductionStep >= 3 }]"
            :intoroduction="isIntroduction"
          >
            レベル<span class="level-num">2</span>
          </MpLevel>
          <MpLevel
            id="level3"
            class="grid-item"
            level="level3"
            view="ViewLevelNormalSingle3"
            :locked="!isIntroduction && !isContentClear('level2')"
            :class="['level-select', { intoro: intoroductionStep >= 4 }]"
            :intoroduction="isIntroduction"
          >
            レベル<span class="level-num">3</span>
          </MpLevel>
          <MpLevel
            id="level4"
            class="grid-item"
            level="level4"
            view="ViewLevelNormalSingle4"
            :locked="!isIntroduction && !isContentClear('level3')"
            :class="['level-select', { intoro: intoroductionStep >= 5 }]"
            :intoroduction="isIntroduction"
          >
            レベル<span class="level-num">4</span>
          </MpLevel>
          <MpLevel
            id="level5"
            class="grid-item"
            level="level5"
            view="ViewLevelNormalSingle5"
            :locked="!isIntroduction && !isContentClear('level4')"
            :class="['level-select', { intoro: intoroductionStep >= 6 }]"
            :intoroduction="isIntroduction"
          >
            レベル<span class="level-num">5</span>
          </MpLevel>
          <MpLevel
            id="level6"
            class="grid-item"
            level="level6"
            view="ViewLevelNormalSingle6"
            :locked="!isIntroduction && !isContentClear('level5')"
            :class="['level-select', { intoro: intoroductionStep == 7 }]"
            :intoroduction="isIntroduction"
          >
            レベル<span class="level-num">6</span>
          </MpLevel>
        </div>
        <div id="next" class="grid-item stage-select">
          <MpLevelNext v-if="next" :table="next" />
        </div>
      </div>
    </main>
    <footer class="footer-single">
      <MpBack view="ViewStageNormal" />
    </footer>
    <div id="overlay" :class="{ show: isIntroduction || showTimestable }">
      <div class="modal listen-table" v-if="showTimestable">
        <img :src="$image(`hint/hint_${times}.svg`)" alt="きく" />
        <div class="btn-box">
          <button class="btn-finish" @click="doTimestableFinish">おわる</button>
        </div>
      </div>
      <div class="modal modal-tutorial" v-if="isIntroduction">
        <div class="tooltip-box">
          <div class="tooltip">だんだんむずかしくなるよ！</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MpHamburger from "@/components/ui/MpHamburger";
import MpLevel from "@/components/ui/MpLevel";
import MpLevelPrev from "@/components/ui/MpLevelPrev";
import MpLevelNext from "@/components/ui/MpLevelNext";
import MpBack from "@/components/ui/MpBack";
import Normal from "@/mixins/normal";
export default {
  components: {
    MpHamburger,
    MpLevel,
    MpLevelPrev,
    MpLevelNext,
    MpBack,
  },
  mixins: [Normal],
  data: function () {
    return {
      showTimestable: false,
      audioPlayer: null,
      intoroductionStep: 0,
      intoroductionInterval: 800,
    };
  },
  computed: {
    stage() {
      return this.$store.getters["app/getCurrentStage"];
    },
    table() {
      return this.$store.getters["app/getCurrentTable"];
    },
    title() {
      return this.sequence[this.table].title;
    },
    times() {
      return this.sequence[this.table].times[0];
    },
    prev() {
      return this.getPrev(this.table);
    },
    next() {
      return this.getNext(this.table);
    },
    isIntroduction() {
      return (
        // 2の段の最初のみ案内ガイド開始
        this.times == 2 && this.$store.getters["learningState/isIntroduction"]
      );
    },
    isOverlay() {
      return this.$store.getters["app/isOverlay"];
    },
  },
  created() {
    // 横レイアウト強制
    this.$log.debug("横レイアウト強制");
    this.$store.commit("app/enforceOrientationLandscape");
  },
  mounted() {
    // レベル選択解除
    this.$store.dispatch("app/deselectLevel");
    if (this.isIntroduction) {
      // 案内ガイド開始

      // 案内ガイド音声開始
      this.$sound.intoroduction().play(() => {
        // ガイド音声終了時
        this.intoroductionStep = 1;
        // 一定時間ごとにガイド繰り返し
        this.$timer.recursive(
          () => {
            this.intoroductionStep++;
          },
          () => {
            // コンテンツ開始時案内フラグをオフにする
            this.$store.commit("learningState/offIntroduction");
            // イントロ終了
            this.intoroductionStep = 0;
            // スタートガイド再生
            this.$sound.start(this.table).play();
          },
          this.intoroductionInterval,
          7
        );
      });
    }
  },
  methods: {
    // 指定レベルのコンテンツをクリアしたかどうか判定
    isContentClear(level) {
      return this.$store.getters["learningState/isContentClear"](
        this.stage,
        this.table,
        level
      );
    },
    // 九九一覧おわるボタン押下時
    doTimestableFinish() {
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "九九一覧おわる",
        table: this.$store.getters["app/getCurrentTable"],
      });
      // 録音再生停止
      if (this.audioPlayer) this.audioPlayer.stop();
      // 九九一覧非表示
      this.showTimestable = false;
    },
  },
};
</script>