<template>
  <div class="inner-box" :class="[locked ? 'locked' : '']">
    <a href="#" class="arrow" @click.stop.prevent="doPrev"
      ><img
        :src="$image('level/arrow_left.svg')"
        :alt="table.title + 'のだん'"
    /></a>
    <a href="#" @click.stop.prevent="doPrev">
      <span class="stage">{{ table.title }}</span>
      <span>の</span>
      <span>だん</span>
    </a>
  </div>
</template>

<script>
import Normal from "@/mixins/normal";
export default {
  mixins: [Normal],
  props: {
    table: {
      type: Object,
      required: true,
    },
  },
  computed: {
    locked() {
      return this.table.premise && !this.isTableClear(this.table.premise);
    },
  },
  methods: {
    doPrev() {
      if (this.locked) return;
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: this.table.title + "のだん選択",
        table: this.$store.getters["app/getCurrentTable"],
      });
      // テーブル選択
      this.$store.dispatch("app/selectTable", this.table.name);
      // ビュー変更
      this.$store.commit("app/setCurrentView", this.table.view);
    },
  },
};
</script>

