import axios from 'axios';

const state = () => {
    return {
        data: {},
    };
};

const getters = {
    getSound: (state) => {
        return (key) => {
            return state.data[key];
        };
    },
};

const mutations = {
    setSounds(state, sounds) {
        state.data = sounds;
    },
};

const actions = {
    async loadSounds({ commit }) {
        const response = await axios.get('data/sounds.json');

        commit('setSounds', response.data);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};