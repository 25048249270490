<template>
  <div id="container" class="listen-record"  :class="{ cleared: complete }">
    <slot />
    <header>
      <div id="nav">
        <MpFinish
          :class="{ disabled: complete }"
          :disabled="complete"
        />
        <MpHamburger />
      </div>
    </header>
    <main>
      <div id="message" class="message-listen">
        <button v-if="!complete && step < 3" type="button" class="btn-listen" disabled>
          <img :src="$image('speaker.svg')" alt="きく" />
        </button>
        <span v-if="step === 1">をおして、九九をききましょう。</span>
        <span v-if="step === 2" class="speak"
          >をおして、あとにつづけていってみましょう。</span
        >
      </div>
      <div id="content" class="content-listen">
        <div
          class="expr-box"
          v-for="n in 9"
          :key="n"
          :class="{ selected: n === playTimes }"
        >
          <div class="expr">
            <img
              :src="$image(`table/${times}x${n}.svg`)"
              alt=""
            />
            <div class="btn-box show">
              <button
                type="button"
                class="btn-listen"
                v-if="n === playTimes"
                @click="doPlay"
                :class="{ disabled: disabled }"
              >
                <img :src="$image('speaker.svg')" alt="きく" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div
      id="overlay"
      :class="{ show: listenGuide || speakGuide }"
    >
      <div class="modal modal-listen" v-if="listenGuide">
        <div class="modal-icon">
          <img :src="$image('listen.svg')" alt="きいてみよう" />
        </div>
        <div class="modal-message">
          {{ times }} のだんを<br />きいてみよう！
        </div>
        <div class="modal-btn-box">
          <button
            type="button"
            class="btn-start"
            @click="doListenStart"
            :disabled="isGuidePlaying"
          >
            スタート
          </button>
        </div>
      </div>
      <div class="modal modal-speak" v-if="speakGuide">
        <div class="modal-icon">
          <img :src="$image('speak.svg')" alt="いってみよう" />
        </div>
        <div class="modal-message">あとにつづけて<br />いってみよう！</div>
        <div class="modal-btn-box">
          <button
            type="button"
            class="btn-start"
            @click="doSpeakStart"
            :disabled="isGuidePlaying"
          >
            スタート
          </button>
        </div>
      </div>
    </div>
    <div class="welldone show" v-if="complete">
      <img :src="$image('welldone.svg')" />
    </div>
    <MpCompletion
      ref="completion"
      v-if="complete"
      :autoCompleteTime="3000"
    />
  </div>
</template>

<script>
import MpHamburger from "@/components/ui/MpHamburger";
import MpFinish from "@/components/ui/MpFinish.vue";
import MpCompletion from "@/components/ui/MpCompletion.vue";
import Question from "@/mixins/question";
import Restriction from "@/mixins/restriction";
export default {
  components: {
    MpHamburger,
    MpFinish,
    MpCompletion,
  },
  mixins: [Question, Restriction],
  data: function () {
    return {
      step: 1, // 学習ステップ（1:きく、2:いう、3:ろくおん、その他:きく）
      listenGuide: false, // きくガイダンス
      speakGuide: false, // いうガイダンス
      playTimes: 0, // 再生する九九
      playInternal: 0, // 再生間隔
      disabled: false, // 再生ボタン抑止
      complete: false, // 全問正解
      isGuidePlaying: false, // 音声ガイド再生有無（true:再生中、false:未再生）
    };
  },
  computed: {
    loginId: function () {
      return this.$store.getters["auth/getLoginId"];
    },
    // 学習中の九九の段数
    times: function () {
      return this.$store.getters["app/getCurrentTimes"];
    },
    // 学習中のコンテンツ
    content: function () {
      return this.$store.getters["app/getCurrentContent"];
    },
  },
  watch: {
    listenGuide: function (val) {
      if (val) {
        // 聞いてみよう開始の場合、音声案内再生
        this.isGuidePlaying = true;
        this.$log.debug("聞いてみよう:%d", this.times);
        this.$log.debug("レベル:%s", this.level);
        this.$sound.listen(this.times).play(() => {
          this.isGuidePlaying = false;
        });
      }
    },
    speakGuide: function (val) {
      if (val) {
        // 後に続いて言ってみよう開始の場合、音声案内再生
        this.isGuidePlaying = true;
        this.$log.debug("言ってみよう:%d", this.times);
        this.$sound.repeat().play(() => {
          this.isGuidePlaying = false;
        });
      }
    },
  },
  created() {
    // 縦レイアウト設定
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("portrait");
    // 縦レイアウト強制
    this.$log.debug("縦レイアウト強制");
    this.$store.commit("app/enforceOrientationPortrait");
  },
  mounted() {
    // 使用制限抑止
    this.disableRestriction();
    // ステップ開始
    this.doStep();
  },
  destroyed() {
    // 縦レイアウト解除
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("portrait");
    // 使用制限抑止解除
    this.enableRestriction();
  },
  methods: {
    // ステップ開始
    doStep() {
      switch (this.step) {
        case 1:
          // 聞いてみよう案内ガイド
          this.listenGuide = true;
          break;
        case 2:
          // 言ってみよう案内ガイド
          this.speakGuide = true;
          break;
        case 3:
          // 0.5秒後にはなまるを表示
          setTimeout(() => {
            this.complete = true;
          }, 500)
          break;
        default:
          // 聞いてみよう案内ガイド
          this.listenGuide = true;
          break;
      }
    },
    // 聞いてみよう開始
    doListenStart() {
      // 学習履歴その他のタップイベント
      this.$store.dispatch("history/othersTapEvent", {
        loginId: this.loginId,
        content: this.content,
        event: "きく",
      });
      // 聞いてみよう案内ガイド停止
      this.listenGuide = false;
      // 九九学習開始
      this.playTimes = 1;
    },
    // 言ってみよう開始
    doSpeakStart() {
      // 学習履歴その他のタップイベント
      this.$store.dispatch("history/othersTapEvent", {
        loginId: this.loginId,
        content: this.content,
        event: "いう",
      });
      // 言ってみよう案内ガイド停止
      this.speakGuide = false;
      // 九九学習開始
      this.playTimes = 1;
    },
    // 再生
    doPlay() {
      // 学習履歴九九読み上げ再生タップ
      this.$store.dispatch("history/timestablePlayTap", {
        loginId: this.loginId,
        content: this.content,
        multiplicand: this.times,
        multiplier: this.playTimes,
      });
      // ボタン抑止
      this.disabled = true;
      // 学習する九九を再生
      this.$sound.timestable(this.times, this.playTimes).play(
        (id) => {
          this.$log.debug(
            "%s x %s再生開始(%s)",
            this.times,
            this.playTimes,
            id
          );
        },
        (id) => {
          this.$log.debug(
            "%s x %s再生終了(%s)",
            this.times,
            this.playTimes,
            id
          );
          setTimeout(() => {
            if (this.step === 2) {
              // ステップ２（いう）の場合は、２回目
              this.$sound.timestableSmall(this.times, this.playTimes).play(
                (id) => {
                  this.$log.debug(
                    "%s x %s２回目再生開始(%s)",
                    this.times,
                    this.playTimes,
                    id
                  );
                },
                (id) => {
                  this.$log.debug(
                    "%s x %s２回目再生終了(%s)",
                    this.times,
                    this.playTimes,
                    id
                  );
                  // 学習履歴九九読み上げ再生終了
                  this.$store.dispatch("history/timestablePlayFinish", {
                    loginId: this.loginId,
                    content: this.content,
                    multiplicand: this.times,
                    multiplier: this.playTimes,
                  });
                  setTimeout(() => {
                    this.doNext();
                  }, this.playInternal);
                }
              );
            } else {
              // 学習履歴九九読み上げ再生終了
              this.$store.dispatch("history/timestablePlayFinish", {
                loginId: this.loginId,
                content: this.content,
                multiplicand: this.times,
                multiplier: this.playTimes,
              });
              this.doNext();
            }
          }, this.playInternal);
        }
      );
    },
    // ステップ操作
    doNext() {
      // ボタン復活
      this.disabled = false;
      if (this.playTimes == 9) {
        // 九九リセット
        this.playTimes = 0;
        // 次のステップ
        this.step += 1;
        // ステップ開始
        this.doStep();
      } else {
        // 次の九九へ移動
        this.playTimes += 1;
      }
    },
    doFinish() {
      // 全問終了
      this.$log.debug("コンテンツ終了！！");
    },
  },
};
</script>