<template>
  <div
    id="orientation"
    :class="{ vertical: showPortraitGuide, horizontal: showLandscapeGuide }"
    v-if="showPortraitGuide || showLandscapeGuide"
  >
    <MpCopyright :isPortrait="showLandscapeGuide" />
    <div class="inner-vertical" v-if="showPortraitGuide">
      <div class="message">たてにしよう</div>
      <img :src="$image('vertical.svg')" />
    </div>
    <div class="inner-horizontal" v-if="showLandscapeGuide">
      <div class="message">よこにしよう</div>
      <img :src="$image('horizontal.svg')" />
      <div class="caption">縦画面のロックを外す、画面の自動回転をオンに設定するなどの必要があります</div>
    </div>
  </div>
</template>
<script>
import MpCopyright from './MpCopyright.vue';

export default {
    data: function () {
        return {
            showPortraitGuide: false,
            showLandscapeGuide: false, // 横強制画面表示有無（true：表示、false:非表示）
        };
    },
    computed: {
        isEnforcePortrait() {
            return this.$store.getters["app/isEnforceOrientationPortrait"];
        },
        isEnforceLandscape() {
            return this.$store.getters["app/isEnforceOrientationLandscape"];
        },
    },
    watch: {
        showPortraitGuide: function (val) {
            this.$store.commit("app/setVisibleOrientationGide", val);
        },
        showLandscapeGuide: function (val) {
            this.$store.commit("app/setVisibleOrientationGide", val);
        },
    },
    mounted() {
        this.$log.debug("ユーザエージェント=>%s", navigator.userAgent);
        if (this.isPortrait()) {
            // 縦画面
            this.$log.debug("縦画面です。");
            if (this.isEnforceLandscape) {
                this.showLandscapeGuide = true;
            }
        }
        else {
            // 横画面
            this.$log.debug("横画面です。");
            if (this.isEnforcePortrait) {
                this.showPortraitGuide = true;
            }
        }
    },
    methods: {
        isPortrait() {
            return (document.documentElement.clientHeight >
                document.documentElement.clientWidth);
        },
        validate() {
            this.$log.debug("ユーザエージェント=>%s", navigator.userAgent);
            this.$log.debug("縦横強制をチェックします！！");
            if (this.isPortrait()) {
                // 縦画面
                this.$log.debug("現在は縦画面です。");
                if (this.isEnforcePortrait && this.showPortraitGuide) {
                    this.showPortraitGuide = false;
                }
                if (this.isEnforceLandscape && !this.showLandscapeGuide) {
                    this.showLandscapeGuide = true;
                }
            }
            else {
                // 横画面
                this.$log.debug("現在は横画面です。");
                if (this.isEnforceLandscape && this.showLandscapeGuide) {
                    this.showLandscapeGuide = false;
                }
                if (this.isEnforcePortrait && !this.showPortraitGuide) {
                    this.showPortraitGuide = true;
                }
            }
        },
    },
    components: { MpCopyright }
};
</script>

<style lang="scss" scoped>
.caption {
    margin-top: 10%;
    padding: 0 7%;
    font-size: 3rem;
}
</style>