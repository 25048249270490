<template>
  <div id="finish">
    <a href="#" @click.stop.prevent="doFinish">
      おわる
    </a>
  </div>
</template>

<script>
import Activity from "@/mixins/activity";

const MP_COMPLETION_BEFORE_FINISH_EVENT = "beforeFinish"; // ガイダンス終了前イベント
export default {
  mixins: [Activity],
  data() {
    return {
      completeSound: null,
      timer: null,
      completed: false,
    };
  },
  props: {
    // 自動終了時間（ミリ秒・0は自動終了なし）
    autoCompleteTime: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    // ログインID
    loginId: function () {
      return this.$store.getters["auth/getLoginId"];
    },
    // コンテンツ
    content: function () {
      return this.$store.getters["app/getCurrentContent"];
    },
    // コンテンツ学習回数
    challengeCount() {
      return this.$store.getters["learningState/getContentChallengeCount"](
        this.content.stage,
        this.content.table,
        this.content.level
      );
    },
    // コンテンツクリア回数
    clearCount() {
      return this.$store.getters["learningState/getContentClearCount"](
        this.content.stage,
        this.content.table,
        this.content.level
      );
    },
    // 総問題数
    answered() {
      return this.$store.getters["learningState/getAnswered"];
    },
    // 総学習時間
    totalTime() {
      return this.$store.getters["learningState/getTotalTime"];
    },
  },
  mounted() {
    // コンテンツクリアイベント
    this.$store.dispatch("history/contentCleare", {
      loginId: this.loginId,
      content: this.content,
    });

    // よくできましたね！再生
    if (this.autoCompleteTime > 0) {
      // 自動終了あり

      // おわる処理
      this.doComplete();

      // 音声再生
      this.completeSound = this.$sound.complete();
      this.completeSound.play(() => {
        this.timer = this.$timer.once(() => {
          // アプリビュー変更（前の画面に戻る）
          var prevView = this.$store.getters["app/getPrevView"];
          this.$store.commit("app/setCurrentView", prevView);
        }, this.autoCompleteTime);
      });
    } else {
      // 自動終了なし
      this.$sound.complete().play();
    }
  },
  methods: {
    doComplete() {
      if (this.completed) {
        return;
      }
      this.completed = true;
      // ガイダンス終了前イベント
      this.$log.debug("おわる処理前イベント");
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "コンテンツおわるボタン",
        table: this.$store.getters["app/getCurrentTable"],
      });
      this.$emit(MP_COMPLETION_BEFORE_FINISH_EVENT);
      // コンテンツ学習時間計測終了
      this.$store.commit("app/stopLearningTime");
      // コンテンツ学習時間取得
      var time = this.$store.getters["app/getLearningTime"];
      // 総学習時間加算
      this.$store.commit("learningState/addTotalTime", time);
      // クリア回数加算
      this.$store.commit(
        "learningState/incrementContentClearCount",
        this.content
      );
      // 学習履歴１コンテンツの学習時間
      this.$store.dispatch("history/finishContent", {
        loginId: this.loginId,
        content: this.content,
        time: time,
      });
      // 学習履歴コンテンツ累積ログ① 総学習回数
      this.$store.dispatch("history/cumulativeNumberOfChallenge", {
        loginId: this.loginId,
        content: this.content,
        challenge: this.challengeCount,
      });
      // 学習履歴コンテンツ累積ログ② クリア回数
      this.$store.dispatch("history/cumulativeNumberOfClear", {
        loginId: this.loginId,
        content: this.content,
        clear: this.clearCount,
      });
      // 学習履歴コンテンツ累積ログ③ 総問題数
      this.$store.dispatch("history/cumulativeNumberOfAnswered", {
        loginId: this.loginId,
        answered: this.answered,
      });
      // 学習履歴コンテンツ累積ログ④ 総学習時間
      this.$store.dispatch("history/cumulativeTotalLearningTime", {
        loginId: this.loginId,
        time: this.totalTime,
      });
      // ログ送信アクティビティ
      this.activitySendLog();
    },
    doFinish() {
      this.$log.debug("おわるボタン操作");
      if (this.timer) {
        this.timer.exit();
      }
      // おわる処理
      this.doComplete();
      if (this.completeSound) {
        this.completeSound.stop();
      }
      // アプリビュー変更（前の画面に戻る）
      var prevView = this.$store.getters["app/getPrevView"];
      this.$store.commit("app/setCurrentView", prevView);
    },
  },
};
</script>