export default {
    data: function () {
        return {
            sequence: {
                table6and7: {
                    name: "table6and7",
                    title: "6・7",
                    view: "ViewTableBonusMulti",
                    premise: "",
                    times: [6, 7]
                },
                table7and8: {
                    name: "table7and8",
                    title: "7・8",
                    view: "ViewTableBonusMulti",
                    premise: "",
                    times: [7, 8]
                },
                table8and9: {
                    name: "table8and9",
                    title: "8・9",
                    view: "ViewTableBonusMulti",
                    premise: "",
                    times: [8, 9]
                },
                table6and9: {
                    name: "table6and9",
                    title: "6・9",
                    view: "ViewTableBonusMulti",
                    premise: "",
                    times: [6, 9]
                },
                table4and7: {
                    name: "table4and7",
                    title: "4・7",
                    view: "ViewTableBonusMulti",
                    premise: "",
                    times: [4, 7]
                },
                table6to8: {
                    name: "table6to8",
                    title: "6〜8",
                    view: "ViewTableBonusMulti",
                    premise: "",
                    times: [6, 7, 8]
                },
                table7to9: {
                    name: "table7to9",
                    title: "7〜9",
                    view: "ViewTableBonusMulti",
                    premise: "",
                    times: [7, 8, 9]
                },
                table2to5: {
                    name: "table2to5",
                    title: "2〜5",
                    view: "ViewTableBonusMulti",
                    premise: "",
                    times: [2, 3, 4, 5]
                },
                table6to9: {
                    name: "table6to9",
                    title: "6〜9",
                    view: "ViewTableBonusMulti",
                    premise: "",
                    times: [6, 7, 8, 9]
                },
                table2to9: {
                    name: "table2to9",
                    title: "2〜9",
                    view: "ViewTableBonusMulti",
                    premise: "",
                    times: [2, 3, 4, 5, 6, 7, 8, 9]
                },
            }
        };
    },
    methods: {
        // 一つ前のテーブル取得
        getPrev(table) {
            var index = Object.keys(this.sequence).indexOf(table);
            if (index == 0) {
                this.$log.debug("一つ前のテーブルなし");
                // ボーナスステージの場合、一つ前のテーブルが存在しない場合最は後のテーブルに戻る
                var max = Object.keys(this.sequence).length;
                return Object.values(this.sequence)[max - 1];
            }
            var prev = Object.values(this.sequence)[index - 1];
            this.$log.debug("一つ前のテーブル");
            return prev;
        },
        // 一つ後のテーブル取得
        getNext(table) {
            var index = Object.keys(this.sequence).indexOf(table);
            var max = Object.keys(this.sequence).length;
            if (index >= max - 1) {
                this.$log.debug("一つ後のテーブルなし");
                // ボーナスステージの場合、一つ後のテーブルが存在しない場合は最初のテーブルに戻る
                return Object.values(this.sequence)[0];
            }
            var next = Object.values(this.sequence)[index + 1];
            this.$log.debug("一つ後のテーブル");
            return next;
        },
        // 対象テーブルクリア有無
        isTableClear(table) {
            this.$log.debug("テーブルクリア判定:%s", table);
            return table ? this.$store.getters["learningState/isTableClear"]("bonus", table) : true;
        },
    }
}