var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    show:
      _vm.isShowFirstGuide ||
      _vm.isShowWeaknessGuide ||
      _vm.isShowCompletionGuide1 ||
      _vm.isShowCompletionGuide2 ||
      _vm.isShowCompletionGuide3 ||
      _vm.isShowFinalGuide,
  },attrs:{"id":"modal-box"}},[(_vm.isShowFirstGuide)?_c('MpModal',{staticClass:"modal-inner",class:{ show: _vm.isShowFirstGuide },attrs:{"id":"first-message"},on:{"destroyed":_vm.onFirstGuideDestroyed}},[_c('div',{staticClass:"modal-message-box"},[_vm._t("firstGuide")],2),_c('div',{staticClass:"modal-btn-box"},[_c('div',[_c('button',{staticClass:"btn-forward",attrs:{"type":"button"},on:{"click":_vm.doNext}},[_vm._v(" すすむ ")])])])]):_vm._e(),(_vm.isShowWeaknessGuide)?_c('MpModal',{staticClass:"modal-inner",class:{ show: _vm.isShowWeaknessGuide },attrs:{"id":"listen-message"},on:{"destroyed":_vm.onWeaknessGuideDestroyed}},[_c('div',{staticClass:"modal-message-box"},[_c('div',{staticClass:"message"},[_vm._v("さいしょに きいてみよう！")]),_c('MpWeakness',{ref:"weaknessFirst",attrs:{"results":_vm.weaknessResults,"showAnswer":false},on:{"mounted":_vm.onWeaknessMounted,"listenAll":_vm.onWeaknessListenAll}})],1),_c('div',{staticClass:"modal-btn-box"},[_c('div',[_c('button',{staticClass:"btn-forward",class:{ disabled: !_vm.isWeaknessListenAll },attrs:{"type":"button"},on:{"click":_vm.doStart}},[_vm._v(" スタート ")])])])]):_vm._e(),(
      _vm.isShowCompletionGuide1 ||
      _vm.isShowCompletionGuide2 ||
      _vm.isShowCompletionGuide3
    )?_c('MpModal',{staticClass:"modal-inner",class:[
      _vm.resultMessageClass,
      {
        show:
          _vm.isShowCompletionGuide1 ||
          _vm.isShowCompletionGuide2 ||
          _vm.isShowCompletionGuide3,
        'stage-clear': _vm.isBackgoudClear & _vm.isContetClear,
      } ],attrs:{"id":"result-message"}},[_c('div',{staticClass:"modal-message-box"},[_c('div',{staticClass:"result-box",class:{ show: _vm.isShowCompletionGuide1 },attrs:{"id":"finished-complete"}},[_c('div',{staticClass:"result-img"},[_c('img',{attrs:{"src":_vm.$image('question/congratulations.svg'),"alt":"おめでとう！"}})]),_c('div',{staticClass:"result-message-box"},[_vm._t("completionGuide1")],2)]),_c('div',{staticClass:"result-box",class:{ show: _vm.isShowCompletionGuide2 },attrs:{"id":"finished-good"}},[_c('div',{staticClass:"result-img"},[_c('img',{attrs:{"src":_vm.$image('question/goodplay.svg'),"alt":"はやくできたね！"}})]),_c('div',{staticClass:"result-message-box"},[_vm._t("completionGuide2"),_c('div',{staticClass:"retry"},[_c('button',{staticClass:"btn-forward",class:{
                disabled: _vm.isWeakness && !_vm.isWeaknessListenAll,
              },attrs:{"type":"button"},on:{"click":_vm.doRetry}},[_vm._v(" もう１かい ")])])],2)]),_c('div',{staticClass:"result-box",class:{ show: _vm.isShowCompletionGuide3 },attrs:{"id":"finished-played"}},[_c('div',{staticClass:"result-img"},[_c('img',{attrs:{"src":_vm.$image('question/goodplay.svg'),"alt":"がんばったね！"}})]),_c('div',{staticClass:"result-message-box"},[_vm._t("completionGuide3"),_c('div',{staticClass:"retry"},[_c('button',{staticClass:"btn-forward",class:{
                disabled: _vm.isWeakness && !_vm.isWeaknessListenAll,
              },attrs:{"type":"button"},on:{"click":_vm.doRetry}},[_vm._v(" もう１かい ")])])],2)])]),(_vm.isWeakness)?_c('div',{staticClass:"modal-listen-box"},[_c('div',{attrs:{"id":"listen-repeat"}},[_c('div',{staticClass:"message"},[_vm._v("さいごに きいてみよう！")]),_c('MpWeakness',{ref:"weaknessLast",attrs:{"results":_vm.weaknessResults},on:{"mounted":_vm.onWeaknessMounted,"listenAll":_vm.onWeaknessListenAll,"listenStart":_vm.onWeaknessListenStart,"listenFinish":_vm.onWeaknessListenFinish}})],1)]):_vm._e(),_c('div',{attrs:{"id":"finish-modal"}},[_c('a',{class:{
          disabled:
            ((_vm.isShowCompletionGuide2 || _vm.isShowCompletionGuide3) &&
              _vm.isWeakness &&
              !_vm.isWeaknessListenAll) ||
            ((_vm.isShowCompletionGuide2 || _vm.isShowCompletionGuide3) &&
              _vm.isWeakness &&
              _vm.isWeaknessListenAll &&
              _vm.isWeaknessListen) ||
            (_vm.isShowCompletionGuide1 && _vm.isWeakness && _vm.isWeaknessListen),
        },attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.doFinish.apply(null, arguments)}}},[_vm._v(" おわる ")])])]):_vm._e(),(_vm.isShowFinalGuide)?_c('MpModal',{staticClass:"modal-inner all-clear",class:{ show: _vm.isShowFinalGuide },attrs:{"id":"result-message"}},[_c('div',{staticClass:"modal-message-box"},[_c('div',{staticClass:"result-box",class:{ show: _vm.isShowFinalGuide },attrs:{"id":"finished-all"}},[_c('div',{staticClass:"result-img"},[_c('img',{attrs:{"src":_vm.$image('question/congratulations.svg'),"alt":"おめでとう！"}})]),_c('div',{staticClass:"result-message-box"},[_c('div',[_c('p',{staticClass:"result-message-title"},[_vm._v("おめでとう！")]),_c('p',{staticClass:"result-message-body"},[_vm._v("九九をマスターしたね！！")])])])]),_c('div',{attrs:{"id":"finish-modal"}},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.doFinalFinish.apply(null, arguments)}}},[_vm._v(" おわる ")])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }