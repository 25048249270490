import jsonData from '@/assets/json/questions.json';

const state = () => ({
    currentView: '',                // 現在のビュー
    prevView: '',                   // 以前のビュー
    stage: '',                      // 現在のステージ（ノーマルステージ／ボーナスステージ）
    table: '',                      // 現在のテーブル（２の段、３の段、２〜３の段など）
    level: '',                      // 現在のレベル（きく・いう、レベル１、レベル２など）
    menu: false,                    // メニュー状態(true:表示/false:非表示)
    overlay: 0,                     // オーバレイ状態(0:オーバーレイなし/1+:オーバーレイ枚数)
    questions: jsonData,            // 問題集
    processing: false,              // 計算中かどうか（true:計算中/false:計算中でない）
    learningTime: 0,                // コンテンツごとの学習計測時間（ミリ秒）
    showLearningStateEditor: false, // 学習状況編集エディター表示有無（true:表示、false:表示しない）
    showHistoryLog: false,          // 学習履歴ログ表示有無（true:表示、false:表示しない）
    restrictedView: '',             // 使用制限中のビュー
    restrictionTimer: null,         // 使用制限タイマー
    enforceOrientation: '',         // portrait:縦強制、landscape:横強制、その他:強制なし
    visibleOrientationGide: false,  // 縦横強制ガイドが表示中かどうか（true:表示中、false:非表示）
    showHint: false,                // ヒントテーブル表示状態(true:表示、false:非表示)
    hintClosing: false,             // ヒントを閉じている途中の場合true、それ以外false
    pwa: false,                        // インストールの有無
    deferredPrompt: false,             // アプリインストール用プロンプト
});
const getters = {
    isProcessing: state => {
        return state.processing;
    },
    getQuestions: state => {   // 問題集取得
        var questions = state.questions[state.stage][state.table][state.level];
        return questions ? questions : [];
    },
    getCurrentStage: state => { // 現在のステージ
        return state.stage;
    },
    getCurrentTable: state => { // 現在のテーブル
        return state.table;
    },
    getCurrentLevel: state => { // 現在のレベル
        return state.level;
    },
    getCurrentContent: state => {   // 現在のコンテンツ（{stage:'normal', table:'table2', level:'level1'}など）
        return { stage: state.stage, table: state.table, level: state.level };
    },
    getCurrentView: state => {  // 現在のビュー
        return state.currentView;
    },
    getPrevView: state => {     // 前のビュー
        return state.prevView;
    },
    isMenu: state => {  // メニュー
        return state.menu;
    },
    isOverlay: state => {   // オーバーレイ
        return state.overlay > 0;
    },
    getCurrentTimes: state => {    // テーブルに対応した九九の段数
        switch (state.table) {
            case "table2":
                return 2;
            case "table3":
                return 3;
            case "table4":
                return 4;
            case "table5":
                return 5;
            case "table6":
                return 6;
            case "table7":
                return 7;
            case "table8":
                return 8
            case "table9":
                return 9;
            default:
                return 0;
        }
    },
    getLearningTime: state => { // コンテンツごとの学習計測時間
        return state.learningTime;
    },
    isShowLearningStateEditor: state => {
        return state.showLearningStateEditor;
    },
    isShowHistoryLog: state => {
        return state.showHistoryLog;
    },
    getRestrictedView: state => {
        return state.restrictedView;
    },
    isEnforceOrientationPortrait: state => {
        return state.enforceOrientation === 'portrait';
    },
    isEnforceOrientationLandscape: state => {
        return state.enforceOrientation === 'landscape';
    },
    visibleOrientationGide: state => {
        return state.visibleOrientationGide;
    },
    isShowHint: state => {
        return state.showHint;
    },
    isHintClosing: state => {
        return state.hintClosing;
    },
    getPwa: state => {
        return state.pwa;
    },
    getDeferredPrompt: state => {
        return state.deferredPrompt;
    },
}
const mutations = {
    setProcessing(state, processing) {
        state.processing = processing;
    },
    setCurrentView(state, view) {
        state.prevView = state.currentView;
        this.$log.debug("以前のビュー：%s", state.prevView);

        this.$log.debug("ビュー変更");
        state.currentView = view;
        this.$log.debug("現在のビュー：%s", state.currentView);
    },
    setCurrentViewWithoutHistory(state, view) {
        this.$log.debug("ビュー変更");
        state.currentView = view;
        this.$log.debug("現在のビュー：%s", state.currentView);
    },
    setStage(state, stage) {
        this.$log.debug("ステージ：%s", stage);
        state.stage = stage;
    },
    setTable(state, table) {
        this.$log.debug("テーブル：%s", table);
        state.table = table;
    },
    setLevel(state, level) {
        this.$log.debug("レベル：%s", level);
        state.level = level;
    },
    setMenu(state, show) {
        this.$log.debug("メニュー状態:%s", show);
        state.menu = show;
    },
    setOverlay(state, enable) {
        if (enable) {
            state.overlay += 1;
        } else {
            if (state.overlay > 0) state.overlay -= 1;
        }
        this.$log.debug("オーバーレイ状態:%d", state.overlay);
    },
    startLearningTime(state) {
        var start = new Date().getTime();
        this.$log.debug("コンテンツ学習時間記録開始=>%d", start);
        state.learningTime = start;
    },
    stopLearningTime(state) {
        var stop = new Date().getTime();
        this.$log.debug("コンテンツ学習時間記録終了=>%d", stop);
        state.learningTime = stop - state.learningTime;
        this.$log.debug("コンテンツ学習時間記録結果=>%d", state.learningTime);
    },
    setShowLearningStateEditor(state, value) {
        state.showLearningStateEditor = value;
    },
    setShowHistoryLog(state, value) {
        state.showHistoryLog = value;
    },
    setRestrectedView(state, view) {
        state.restrictedView = view;
    },
    enforceOrientationPortrait(state) {
        state.enforceOrientation = 'portrait';
    },
    enforceOrientationLandscape(state) {
        state.enforceOrientation = 'landscape';
    },
    enforceOrientationOff(state) {
        state.enforceOrientation = '';
    },
    setVisibleOrientationGide(state, value) {
        state.visibleOrientationGide = value;
    },
    setShowHint(state, value) {
        state.showHint = value;
    },
    setHintClosing(state, value) {
        state.hintClosing = value;
    },
    setPwa(state,page){
        state.pwa = page;
    },
    setDeferredPrompt(state,page){
        state.deferredPrompt = page;
    },
}
const actions = {
    selectStage(context, stage) {   // ステージ選択
        this.$log.debug("ステージ選択");
        context.commit('setStage', stage);
    },
    deselectStage(context) {    // ステージ選択解除
        this.$log.debug("ステージ選択解除");
        context.commit('setStage', '');
    },
    selectTable(context, table) {   // テーブル選択
        this.$log.debug("テーブル選択");
        context.commit('setTable', table);
    },
    deselectTable(context) {    // テーブル選択解除
        this.$log.debug("テーブル選択解除");
        context.commit('setTable', '');
    },
    selectLevel(context, level) {   // レベル選択
        this.$log.debug("レベル選択");
        context.commit('setLevel', level);
    },
    deselectLevel(context) {    // レベル選択解除
        this.$log.debug("レベル選択解除");
        context.commit("setLevel", "");
    },
    openMenu(context) { // メニューオープン
        this.$log.debug("メニューオープン");
        context.commit("setMenu", true);
        context.commit("setOverlay", true);
    },
    closeMenu(context) { // メニュークローズ
        this.$log.debug("メニュークローズ");
        context.commit("setMenu", false);
        context.commit("setOverlay", false);
    },
    startRestrictionTimer(context, callback) {
        if (context.state.restrictionTimer) context.state.restrictionTimer.exit();
        context.state.restrictionTimer = this.$timer.recursive(callback, () => {
        }, 60000, 0);
    },
    stopRestrictionTimer(context) {
        if (context.state.restrictionTimer) context.state.restrictionTimer.exit();
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}