import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja.json';
import * as rules from 'vee-validate/dist/rules';
import axios from 'axios';
import App from './App.vue'
import store from './store'
import Logger from './plugins/logger';
import Sound from './plugins/sound';
import Timer from './plugins/timer';

Vue.config.productionTip = false

// バージョン設定
Vue.prototype.$version = require('../package.json').version;

// axiosの設定
Vue.prototype.$axios = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  timeout: process.env.VUE_APP_AXIOS_TIMEOUT,
  headers: {
    'X-API-KEY': process.env.VUE_APP_API_KEY
  },
});

Vue.prototype.$image = (key) => {
  return store.getters['imageData/getImage'](key)
}

// バリデーション設定
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('ja', ja);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// プラグインインストール
Vue.use(Logger);
Vue.use(Sound);
Vue.use(Timer);

// Appインスタンス化
var app = new Vue({
  store,
  render: h => h(App)
});

// ストアにプラグインインスタンス設定
store.$log = app.$log;
store.$axios = app.$axios;
store.$timer = app.$timer;

// Appマウント
app.$mount('#container')
