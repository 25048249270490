<template>
  <div class="stage" :class="{ cleared: isTableClear(table.name) }">
    <a href="#" @click.stop.prevent="doSelect" :class="[addtionalStarClass]">
      <img
        :src="$image(`bonus/${starIcon}`)"
        :alt="table.title + 'のだん'"
      />
      <p>{{ table.title }}<span>の</span>だん</p>
    </a>
  </div>
</template>

<script>
import Bonus from "@/mixins/bonus";
export default {
  mixins: [Bonus],
  props: {
    table: {
      type: Object,
      required: true,
    },
    addtionalStarClass: {
      type: String,
      required: false,
      default: "",
    },
    starIcon: {
      type: String,
      required: true,
    },
  },
  methods: {
    // ステージ選択
    doSelect() {
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: this.table.title + "のだん選択",
      });
      // テーブル選択
      this.$store.dispatch("app/selectTable", this.table.name);
      // ビュー変更
      this.$store.commit("app/setCurrentView", this.table.view);
      // スタートガイド再生
      this.$sound.start(this.table.name).play();
    },
  },
};
</script>