<template>
  <transition name="timestable" @after-leave="onTimestableAfterLeave">
    <div class="hint-box no-close" v-show="showHint">
      <div class="hint-card no-close">
        <div class="close">
          <div
            class="btn-close"
            :class="{ disabled: hintClosing }"
            @click="doCloseHint"
          >
            <div>
              <img
                :src="$image(`close_white.svg`)"
                alt="メニューを閉じる"
              />
            </div>
          </div>
        </div>
        <img
          v-if="multiplicand >= 2 && multiplicand <= 9"
          class="no-close"
          :src="$image(`hint/hint_${multiplicand}.svg`)"
          alt=""
        />
      </div>
    </div>
  </transition>
</template>

<script>
const MP_HINT_TIMESTABLE_HINT_FINISH_EVENT = "hintFinish"; // ヒントが終了した直後のイベント
export default {
  props: {
    // 問題番号
    position: {
      type: Number,
      required: true,
    },
    // 被乗数
    multiplicand: {
      type: Number,
      require: true,
    },
    // 乗数
    multiplier: {
      type: Number,
      require: true,
    },
  },
  computed: {
    showHint: function () {
      return this.$store.getters["app/isShowHint"];
    },
    hintClosing: function () {
      return this.$store.getters["app/isHintClosing"];
    },
    loginId: function () {
      return this.$store.getters["auth/getLoginId"];
    },
    content: function () {
      return this.$store.getters["app/getCurrentContent"];
    },
  },
  methods: {
    // ヒントを閉じる
    doCloseHint() {
      if (this.hintClosing) return;
      this.$log.debug("ヒントを閉じる");
      // ヒント閉じる開始
      this.$store.commit("app/setHintClosing", true);
      // 学習履歴ヒント閉じる
      this.$store.dispatch("history/hintClose", {
        loginId: this.loginId,
        content: this.content,
        position: this.position,
        multiplicand: this.multiplicand,
        multiplier: this.multiplier,
      });
      // ヒント非表示
      this.$store.commit("app/setShowHint", false);
    },
    onTimestableAfterLeave() {
      this.$log.debug("九九一覧が閉じました！");
      // ヒント閉じる終了
      this.$store.commit("app/setHintClosing", false);
      // ヒント終了イベント
      this.$emit(MP_HINT_TIMESTABLE_HINT_FINISH_EVENT);
    },
  },
};
</script>
<style lang="scss">
.timestable-enter-active {
  transition: opacity 0.5s;
}

.timestable-leave-active {
  transition: opacity 3s;
}

.timestable-enter,
.timestable-leave-to {
  opacity: 0;
}
</style>