import { register } from 'register-service-worker'

export default {
    data: function () {
        return {
            serviceWorkerTimeout: 30, // サービスワーカー待機時間（秒）
        }
    },
    computed: {
        isServiceWorkerReady() {
            return this.$store.getters["auth/isServiceWorkerReady"];
        },
        isServiceWorkerUpdatefound() {
            return this.$store.getters["auth/getServiceWorkerUpdate"] === "updatefound";
        },
        isServiceWorkerUpdated() {
            return this.$store.getters["auth/getServiceWorkerUpdate"] === "updated";
        },
        isServiceWorker() {
            if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
                this.$log.debug("サービスワーカー利用可能");
                return true;
            } else {
                this.$log.debug("サービスワーカー利用不可");
                return false;
            }
        }
    },
    methods: {
        initServiceWorker() {
            this.$log.debug("サービスワーカー初期化開始！");
            const log = this.$log;
            const store = this.$store;
            const timer = this.$timer;
            const serviceWorkerTimeout = this.serviceWorkerTimeout;
            store.commit("auth/setServiceWorkerReady", false);
            register(`${process.env.BASE_URL}service-worker.js`, {
                ready() {
                    log.info("【サービスワーカー】アプリケーションがサービスワーカーによってキャッシュされ準備が整いました。(ready)");
                    store.commit("auth/setServiceWorkerReady", true);
                    store.commit("auth/setServiceWorkerUpdate", "");
                },
                registered() {
                    log.info("【サービスワーカー】サービスワーカーが登録されました(registered)。");
                },
                cached() {
                    log.info("【サービスワーカー】全てのコンテンツがキャッシュされました。オフラインでの利用が可能です。(cached)");
                    store.commit("auth/setServiceWorkerUpdate", "cached");
                },
                updatefound() {
                    log.info("【サービスワーカー】新しいコンテンツをダウンロードしています。(updatefound)");
                    store.commit("auth/setServiceWorkerUpdate", "updatefound");
                    timer.once(() => {
                        // サービスワーカー待機タイムアウト
                        if (store.getters["auth/getServiceWorkerUpdate"] === "updatefound") {
                            store.commit("auth/setServiceWorkerUpdate", "");
                        }
                    }, serviceWorkerTimeout * 1000);
                },
                updated() {
                    log.info("【サービスワーカー】新しいコンテンツが利用可能です。アプリケーションを更新してください。(updated)");
                    store.commit("auth/setServiceWorkerUpdate", "updated");
                },
                offline() {
                    log.info("【サービスワーカー】オフラインモードで起動しています。(offline)");
                },
                error() {
                    log.error("【サービスワーカー】サービスワーカーの登録に失敗しました。(error)");
                },
            });
        },
        appUpdate() {
            this.$log.debug("【アプリ更新】開始!");
            if (this.isServiceWorker) {
                this.$log.debug("【アプリ更新】サービスワーカー再登録");
                var userAgent = window.navigator.userAgent.toLowerCase();
                this.$log.debug("【アプリ更新】ユーザエージェント=>%s", userAgent);
                if (userAgent.indexOf('android') != -1) {
                    this.$log.debug("【アプリ更新】Androidの場合");
                    window.navigator.serviceWorker
                        .getRegistrations()
                        .then((registrations) => {
                            var scope = null;
                            this.$log.debug("【アプリ更新】サービスワーカー更新開始");
                            for (let registration of registrations) {
                                if (registration.scope) {
                                    if (!window.location.href.indexOf(registration.scope)) {
                                        scope = registration.scope;
                                        this.$log.debug("【アプリ更新】スコープサービスワーカー削除開始=>%s", scope);
                                        registration.unregister();
                                        this.$log.debug("【アプリ更新】スコープサービスワーカー削除終了=>%s", scope);
                                    } else {
                                        this.$log.debug("【アプリ更新】サービスワーカー削除スキップ=>%s", registration.scope);
                                    }
                                } else {
                                    this.$log.debug("【アプリ更新】サービスワーカー削除開始");
                                    registration.unregister();
                                    this.$log.debug("【アプリ更新】サービスワーカー削除終了");
                                }
                            }
                            this.$log.debug("【アプリ更新】サービスワーカー更新終了");

                            this.$log.debug("【アプリ更新】キャッシュクリア開始");
                            caches.keys().then((keys) => {
                                keys.forEach((cacheName) => {
                                    if (scope) {
                                        // スコープキャッシュのみ削除
                                        if (cacheName.indexOf(scope) > -1) {
                                            this.$log.debug("【アプリ更新】スコープキャッシュクリア開始=>%s", cacheName);
                                            caches.delete(cacheName);
                                            this.$log.debug("【アプリ更新】スコープキャッシュクリア終了=>%s", cacheName);
                                        } else {
                                            this.$log.debug("【アプリ更新】キャッシュクリアスキップ=>%s", cacheName);
                                        }
                                    }
                                });
                                this.$log.debug("【アプリ更新】キャッシュクリア終了");
                                this.$log.debug("【アプリ更新】ブラウザリロード");
                                window.location.reload();
                                this.$log.debug("【アプリ更新】終了!");
                            });
                        });
                    return;
                }
                window.navigator.serviceWorker
                    .getRegistrations()
                    .then((registrations) => {
                        for (let registration of registrations) {
                            console.log(registration);
                            registration.unregister();
                        }
                    });
            }
            this.$log.debug("【アプリ更新】ブラウザリロード");
            this.$timer.once(()=>{
                window.location.reload();
            }, 1500);
            this.$log.debug("【アプリ更新】終了!");
        },
    }
}