<template>
  <div id="container" class="question" :class="{ 'show-hint': showHint }">
    <slot />
    <div id="tooltip-box" class="tooltip-level3" :class="{ show: showTooltip }">
      <div class="tooltip">
        このボタンをおすと、<br />
        ヒントを<span>{{ hintLimit }}かい</span>みることができるよ。<br />
        おしたら、じかんがとまるよ。
      </div>
    </div>
    <header>
      <div id="nav">
        <MpFinish />
        <MpHamburger />
      </div>
    </header>
    <MpHintBox
      :position="position"
      :multiplicand="multiplicand"
      :multiplier="multiplier"
      @hintFinish="onHintFinish"
    />
    <main>
      <div id="message">
        <div class="message-inner"></div>
      </div>
      <div id="content">
        <div id="question">
          <div id="controls">
            <MpTimerBox ref="timer" :sec="timerSec" @finish="onTimerFinish" />
            <MpHintTimestable
              ref="hint"
              :position="position"
              :multiplicand="multiplicand"
              :multiplier="multiplier"
              :hintLimit="hintLimit"
              :disabled="showTooltip"
              :disabledClass="complete"
              :pickup="showTooltip"
              @hintStart="onHintStart"
            />
            <div id="tooltip-hint-box" :class="{ show: showTooltipHint }">
              <div class="tooltip-hint">ヒントは{{ hintLimit }}かいまで</div>
            </div>
          </div>
          <MpExpression ref="expression" @marking="onMarking" />
          <MpProgress :max="questionCount" :value="answered" />
        </div>
        <MpCalculator @enterKey="onEnterKey" v-if="!complete && !showHint" />
      </div>
    </main>
    <MpGuidance
      ref="guidance"
      firstGuidSoundId="ns_level4_first"
      :weaknessResults="weaknessResults"
      @firstGuideFinish="onFirstGuideFinish"
      @weaknessGuideFinish="onWeaknessGuideFinish"
      @beforeFinish="doFinish"
      @retry="doRetry"
    >
      <template v-slot:firstGuide>
        <p>
          ぜんぶで<span class="emphasis"
            ><span class="num">{{ questionCount }}</span
            >もん</span
          ><br />
          それぞれ<span class="emphasis"
            ><span class="num">{{ clearJudgementSec }}</span
            ><ruby><rb>秒</rb><rp>(</rp><rt>びょう</rt><rp>)</rp></ruby></span
          >いないでやってみよう！
        </p>
      </template>
      <template v-slot:completionGuide1>
        <div>
          <p class="result-message-title">おめでとう！</p>
          <p class="result-message-body">
            すべて<span>{{ clearJudgementSec }}</span
            ><ruby><rb>秒</rb><rp>(</rp><rt>びょう</rt><rp>)</rp></ruby
            >いないで<br />
            できました。
          </p>
        </div>
      </template>
      <template v-slot:completionGuide2>
        <div class="result-message-title">はやくできたね！</div>
        <div class="result-message-body q-count">
          <div>
            あと<span>{{ missingsBefore }}</span
            >もん
          </div>
          <div class="q-count-img">
            <img :src="$image('question/arrow_result.svg')" alt="" />
          </div>
          <div>
            あと<span>{{ missings }}</span
            >もん
          </div>
        </div>
      </template>
      <template v-slot:completionGuide3>
        <div class="result-message-title">がんばったね！</div>
        <div class="result-message-body">
          あと<span>{{ missings }}</span
          >もんでした。
        </div>
      </template>
    </MpGuidance>
  </div>
</template>

<script>
import MpHamburger from "@/components/ui/MpHamburger";
import MpFinish from "@/components/ui/MpFinish";
import MpTimerBox from "@/components/ui/MpTimerBox";
import MpHintTimestable from "@/components/ui/MpHintTimestable";
import MpHintBox from "@/components/ui/MpHintBox";
import MpExpression from "@/components/ui/MpExpression";
import MpProgress from "@/components/ui/MpProgress";
import MpCalculator from "@/components/ui/MpCalculator";
import MpGuidance from "@/components/ui/MpGuidance.vue";
import Question from "@/mixins/question";
import Weakness from "@/mixins/weakness";
import Restriction from "@/mixins/restriction";
export default {
  components: {
    MpHamburger,
    MpFinish,
    MpTimerBox,
    MpHintTimestable,
    MpHintBox,
    MpExpression,
    MpProgress,
    MpCalculator,
    MpGuidance,
  },
  mixins: [Question, Weakness, Restriction],
  data: function () {
    return {
      showTooltip: false, // ツールチップ表示有無（true:表示、false:非表示）
      timerSec: 2, // 制限時間（秒）
      clearJudgementSec: 10, // クリア判定基準時間（秒）
      hintLimit: 2, // ヒント回数制限
      results: [], // 回答結果
      showHint: false, // ヒント表示有無（true:表示、false:非表示）
      isHint: false, // ヒントを利用したかどうか（true:利用した、false:利用していない）
      isWrong: false, // 誤答があるかどうか（true:ある、false:ない）
      showTooltipHint: false, // ツールチップヒント表示有無（true:表示、false:非表示）
    };
  },
  computed: {
    // 今回クリアできかった問題数
    missings: function () {
      return this.results.filter(
        (result) => (result.time ? result.time : 0) > this.clearJudgementSec
      ).length;
    },
    // 前回クリアできなかった問題数
    missingsBefore: function () {
      return this.$store.getters["learningState/getContentResults"](
        this.content
      ).filter(
        (result) => (result.time ? result.time : 0) > this.clearJudgementSec
      ).length;
    },
    // クリアしたかどうか
    isCleared: function () {
      return this.missings == 0;
    },
  },
  watch: {
    showTooltip: function (val) {
      if (val) {
        // オーバーレイ表示
        this.$store.commit("app/setOverlay", true);
        // ヒントガイド音声再生
        this.$sound.guide("ns_level4_second").play(() => {
          this.$timer.once(() => {
            // ツールチップ非表示
            this.showTooltip = false;
            // ヒントガイド終了
            this.onHintGuideFinish();
          }, this.guideInterval);
        });
      } else {
        // オーバーレイ非表示
        this.$store.commit("app/setOverlay", false);
      }
    },
  },
  mounted() {
    // 使用制限抑止
    this.disableRestriction();
    // 問題開始
    this.startExpression();
    // ファーストガイド表示
    this.$refs.guidance.showFirstGuide();
  },
  destroyed() {
    // 使用制限抑止解除
    this.enableRestriction();
  },
  methods: {
    // ファーストガイド終了時
    onFirstGuideFinish() {
      // ツールチップ開始
      this.showTooltip = true;
    },
    // ヒントガイド終了時
    onHintGuideFinish() {
      // 同じ段の中で直近に学習したコンテンツの最後に表示した苦手な九九と同じものを表示
      this.weaknessResults = this.$store.getters[
        "learningState/getLatestWeakness"
      ](this.content.stage, this.content.table);
      if (this.isWeakness) {
        // 苦手な九九が存在する場合

        // 苦手な九九ガイド表示
        this.$refs.guidance.showWeaknessGuide();
      } else {
        // 最初の問題出題タイミング
        this.$store.dispatch("history/startQuestion", {
          loginId: this.loginId,
          content: this.content,
          position: this.position,
          multiplicand: this.multiplicand,
          multiplier: this.multiplier,
        });
        // ツールチップヒント表示
        this.showTooltipHint = true;
        // 計測開始
        this.$refs.expression.startInstrument();
        // タイマー開始
        this.$refs.timer.start();
      }
    },
    // 苦手な九九ガイド終了時
    onWeaknessGuideFinish() {
      // 最初の問題出題タイミング
      this.$store.dispatch("history/startQuestion", {
        loginId: this.loginId,
        content: this.content,
        position: this.position,
        multiplicand: this.multiplicand,
        multiplier: this.multiplier,
      });
      // ツールチップヒント表示
      this.showTooltipHint = true;
      // 計測開始
      this.$refs.expression.startInstrument();
      // タイマー開始
      this.$refs.timer.start();
    },
    // テンキー操作時
    onEnterKey(key) {
      // ツールチップヒント非表示
      this.showTooltipHint = false;
      // 式にキー送信
      this.enterExpression(key);
    },
    // 採点時
    onMarking(expression) {
      // 結果判定
      if (expression.result) {
        // 正解の場合

        // 計測終了
        this.$refs.expression.exitInstrument();
        // タイマー終了
        this.$refs.timer.exit();
        expression.time = this.$refs.timer.getTimerValue();
        if (this.isWrong || this.isHint) {
          // 誤答後やヒントを見た（聞いた）後に正答を入力した場合

          // 答え音声再生
          this.$sound
            .multiplication(this.multiplicand, this.multiplier)
            .play(() => {
              // 結果保存
              this.$log.debug("回答結果保持:%s", JSON.stringify(expression));
              this.results.push(expression);
              // 採点結果判定共通処理
              this.judgementExpression(expression);
              // 式正解
              this.$refs.expression.correct();
            });
        } else {
          // 結果保存
          this.$log.debug("回答結果保持:%s", JSON.stringify(expression));
          this.results.push(expression);
          // 採点結果判定共通処理
          this.judgementExpression(expression);
          // 式正解
          this.$refs.expression.correct();
        }
      } else {
        // 不正解の場合
        this.isWrong = true;
        // 採点結果判定共通処理
        this.judgementExpression(expression);
        // 式不正解
        this.$refs.expression.wrong();
      }
    },
    // 次問題出題
    onNext() {
      // 誤答あるかどうかリセット
      this.isWrong = false;
      // ヒントを利用したかどうかリセット
      this.isHint = false;
      // 計測開始
      this.$refs.expression.startInstrument();
      // タイマー開始
      this.$refs.timer.start();
    },
    // ヒント開いた時
    onHintStart() {
      this.$log.debug("ヒント開始");
      // ツールチップヒント非表示
      this.showTooltipHint = false;
      // タイマー停止
      this.$refs.timer.pause();
      // ヒント開始
      this.showHint = true;
      this.isHint = true;
    },
    // ヒント閉じた時
    onHintFinish() {
      this.$log.debug("ヒント終了");
      // ヒント終了
      this.showHint = false;
    },
    // タイマータイムアップ
    onTimerFinish() {
      this.$log.debug("タイマー終了!!");
      // ヒント表示
      this.$refs.hint.doHint(true);
    },
    // コンテンツ終了
    onComplete() {
      // 計測終了
      this.$refs.expression.exitInstrument();
      // タイマー終了
      this.$refs.timer.exit();
      // 苦手な九九を判定
      this.judgementWeakness(this.results);
      // 苦手な九九を保存
      this.$store.commit("learningState/setLatestWeakness", {
        content: this.content,
        weakness: this.weaknessResults,
      });
    },
    // コンテンツ終了後
    onAfterComplete() {
      // クリア画面判定
      this.$log.debug("クリア画面判定");
      if (this.isCleared) {
        // クリアした場合

        // クリア回数加算
        this.$store.commit(
          "learningState/incrementContentClearCount",
          this.content
        );
        // クリア画面表示
        this.$refs.guidance.showCompletionGuide1();
      } else {
        // クリアしなかった場合
        if (this.missings < this.missingsBefore) {
          // 前回より良かった場合
          this.$refs.guidance.showCompletionGuide2();
        } else {
          // 前回より良くなかった場合
          this.$refs.guidance.showCompletionGuide3();
        }
      }
    },
    // もういちど
    doRetry() {
      // 回答結果保存
      this.$store.commit("learningState/setContentResults", {
        content: this.content,
        results: this.results,
      });

      // タイマーリセット
      this.$refs.timer.reset();
      // ヒントリセット
      this.$refs.hint.reset();
      // 問題リセット
      this.resetExpression();
      // 回答結果クリア
      this.results = [];
      // 誤答あるかどうかリセット
      this.isWrong = false;
      // ヒントを利用したかどうかリセット
      this.isHint = false;
      // 問題開始
      this.startExpression();
      // ファーストガイド表示
      this.$refs.guidance.showFirstGuide();
    },
    // 終了
    doFinish() {
      // 回答結果保存
      this.$store.commit("learningState/setContentResults", {
        content: this.content,
        results: this.results,
      });
    },
  },
};
</script>