<template>
  <div class="path" :class="['step' + numberOfClearedContets]">&nbsp;</div>
</template>

<script>
export default {
  props: {
    table: {
      type: String,
      required: true,
    },
  },
  computed: {
    numberOfClearedContets() {
      return this.$store.getters["learningState/countTableClearedContets"](
        "normal",
        this.table
      );
    },
  },
};
</script>