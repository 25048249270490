<template>
  <div id="time">
    <div id="time-box" :class="['state' + step]">&nbsp;</div>
  </div>
</template>

<script>
const MP_TIMER_BOX_STSRT_EVENT = "start"; // タイマー開始直前（初期化後）のイベント
const MP_TIMER_BOX_FINISH_EVENT = "finish"; // タイマー終了直後のイベント
export default {
  props: {
    // タイマー間隔（秒）注、6を割り切れる秒数を指定して下さい（1、2、3、6）。
    sec: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      step: 1, // タイマーステップ(1〜6)
      maxStep: 5, // タイマーステップ最大
      timer: null, // タイマーインスタンス
    };
  },
  destroyed() {
    // タイマー終了
    this.exit();
  },
  methods: {
    // タイマーリセット
    reset() {
      this.step = 1;
      this.timer = null;
    },

    // タイマー開始
    start() {
      this.$log.debug("タイマー開始");
      this.$log.debug("タイマー初期化");
      this.step = 1;
      this.$log.debug("タイマーステップ:%d", this.step);
      // タイマー開始イベント
      this.$emit(MP_TIMER_BOX_STSRT_EVENT);
      this.$log.debug("タイマー起動");
      this.timer = this.$timer.recursive(
        () => {
          // ステップを一つ進める
          this.$log.debug("タイマーステップ:%d", this.step);
          this.step++;
          this.$log.debug("タイマーカウントアップ=>%d", this.step);
        },
        () => {
          // タイマー終了イベント
          this.$emit(MP_TIMER_BOX_FINISH_EVENT);
        },
        this.sec * 1000,
        this.maxStep
      );
    },

    // タイマー停止
    pause() {
      this.$log.debug("タイマー停止");
      this.timer.pause();
    },

    // タイマー再開
    resume() {
      this.$log.debug("タイマー再開");
      this.timer.resume();
    },

    // タイマー終了
    exit() {
      this.$log.debug("タイマー終了");
      if (this.timer) this.timer.exit();
    },

    // タイマー時間取得
    getTimerValue() {
      var timerValue = this.sec * this.step;
      this.$log.debug("タイマー時間=>%d", timerValue);
      return timerValue;
    },
  },
};
</script>