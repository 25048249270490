function getDefaultState() {
    return {
        appVersion: '', // アプリケーションバージョン
        version: 0, // 学習状況バージョン番号
        introduction: true,    // コンテンツ開始時案内フラグ（true:案内あり、false:案内なし）
        contentClearAllGuidance: true,    // 全コンテンツクリア案内フラグ（true:案内あり、false:案内なし）
        weaknessSuggestFirst: true, // 苦手な九九のさいしょにきいてみようの指マーク表示有無（true:表示あり、false:表示なし）
        weaknessSuggestLast: true,  // 苦手な九九のさいごにきいてみようの指マーク表示有無（true:表示あり、false:表示なし）
        answered: 0,        // 回答問題数累計
        lastTimestable: 1,      // 最後に開いた九九一覧 
        totalTime: 0,           // 総学習時間（ミリ秒）  
        weakness: { // 各ステージ・各段ごとの段の最後に表示した苦手な九九
            normal: {
                table2: [],
                table3: [],
                table2to3: [],
                table4: [],
                table2to4: [],
                table5: [],
                table2to5: [],
                table6: [],
                table2to6: [],
                table7: [],
                table2to7: [],
                table8: [],
                table2to8: [],
                table9: [],
                table2to9: [],
            },
            bonus: {
                table6and7: [],
                table7and8: [],
                table8and9: [],
                table6and9: [],
                table4and7: [],
                table6to8: [],
                table7to9: [],
                table2to5: [],
                table6to9: [],
                table2to9: [],
            }
        },
        normal: {   // ノーマルステージ
            table2: {       // ２の段
                conversation: {     // きく・いう
                    challenge: 0,       // チャレンジ回数
                    clear: 0,           // クリア回数
                },
                level1: {           // レベル1
                    challenge: 0,       // チャレンジ回数
                    clear: 0,           // クリア回数
                    time: 0,            // クリアまでかかった時間（秒）
                    results: [],        // 回答結果
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level3: {           // レベル3
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level4: {           // レベル4
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level5: {           // レベル5
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level6: {           // レベル6
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table3: {       // 3の段
                conversation: {     // きく・いう
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level3: {           // レベル3
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level4: {           // レベル4
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level5: {           // レベル5
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level6: {           // レベル6
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table2to3: {    // 2〜3の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table4: {       // 4の段
                conversation: {     // きく・いう
                    challenge: 0,
                    clear: 0,
                },
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level3: {           // レベル3
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level4: {           // レベル4
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level5: {           // レベル5
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level6: {           // レベル6
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table2to4: {    // 2〜4の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table5: {       // 5の段
                conversation: {     // きく・いう
                    challenge: 0,
                    clear: 0,
                },
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level3: {           // レベル3
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level4: {           // レベル4
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level5: {           // レベル5
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level6: {           // レベル6
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table2to5: {    // 2〜5の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table6: {       // 6の段
                conversation: {     // きく・いう
                    challenge: 0,
                    clear: 0,
                },
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level3: {           // レベル3
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level4: {           // レベル4
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level5: {           // レベル5
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level6: {           // レベル6
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table2to6: {    // 2〜6の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table7: {       // 7の段
                conversation: {     // きく・いう
                    challenge: 0,
                    clear: 0,
                },
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level3: {           // レベル3
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level4: {           // レベル4
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level5: {           // レベル5
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level6: {           // レベル6
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table2to7: {    // 2〜7の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table8: {       // 8の段
                conversation: {     // きく・いう
                    challenge: 0,
                    clear: 0,
                },
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level3: {           // レベル3
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level4: {           // レベル4
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level5: {           // レベル5
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level6: {           // レベル6
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table2to8: {    // 2〜8の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table9: {       // 9の段
                conversation: {     // きく・いう
                    challenge: 0,
                    clear: 0,
                },
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level3: {           // レベル3
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level4: {           // レベル4
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level5: {           // レベル5
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level6: {           // レベル6
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table2to9: {    // 2〜9の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
        },
        bonus: {    // ボーナスステージ
            table6and7: {   // 6・7の段
                level1: {           // レベル1
                    challenge: 0,       // チャレンジ数
                    clear: 0,           // クリア数
                    time: 0,            // クリアまでかかった時間（秒）
                    results: [],        // 回答結果
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table7and8: {   // 7・8の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table8and9: {   // 8・9の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table6and9: {   // 6・9の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table4and7: {   // 4・7の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table6to8: {   // 6〜8の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table7to9: {   // 7〜9の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table2to5: {   // 2〜5の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table6to9: {   // 6〜9の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
            table2to9: {   // 2〜9の段
                level1: {           // レベル1
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
                level2: {           // レベル2
                    challenge: 0,
                    clear: 0,
                    time: 0,
                    results: [],
                },
            },
        }
    }
}

const state = () => (getDefaultState());
const getters = {
    getVersion: state => {  // 学習状況バージョン番号
        return state.version;
    },
    isIntroduction: state => {  // コンテンツ開始時案内フラグ
        return state.introduction;
    },
    getAnswered: state => {    // 回答数
        return state.answered;
    },
    getLastTimestable: state => { // 最後の九九いちらん
        return state.lastTimestable;
    },
    isTableClear: state => (stage, table) => { // テーブルクリア有無
        var contents = state[stage][table];
        var filtered = Object.keys(contents).filter(function (key) {
            return contents[key].clear > 0;
        });
        return filtered.length >= Object.keys(contents).length;
    },
    countTableContents: state => (stage, table) => { // テーブルのコンテンツ数
        return Object.keys(state[stage][table]).length;
    },
    countTableClearedContets: state => (stage, table) => { // テーブルのクリアしたコンテンツ数
        var contents = state[stage][table];
        var filtered = Object.keys(contents).filter(function (key) {
            return contents[key].clear > 0;
        });
        return filtered.length;
    },
    isContentClear: state => (stage, table, level) => { // コンテンツクリア有無
        return state[stage][table][level].clear > 0;
    },
    isContentClearAllGuidance: state => {    // コンテンツ全クリアガイダンス有無
        if (!state.contentClearAllGuidance) {
            return false;
        }
        for (let i in state["normal"]) {
            for (let j in state["normal"][i]) {
                if (state["normal"][i][j].clear == 0) {
                    return false;
                }
            }
        }
        for (let i in state["bonus"]) {
            for (let j in state["bonus"][i]) {
                if (state["bonus"][i][j].clear == 0) {
                    return false;
                }
            }
        }
        return true;
    },
    getContentChallengeCount: state => (stage, table, level) => {    // コンテンツチャレンジ数
        return state[stage][table][level].challenge;
    },
    getContentClearCount: state => (stage, table, level) => {    // コンテンツクリア回数
        return state[stage][table][level].clear;
    },
    getTime: state => content => {  // コンテンツごとのクリアまでかかった時間（秒）
        return state[content.stage][content.table][content.level].time
            ? state[content.stage][content.table][content.level].time
            : 0;
    },
    getContentResults: state => content => { // コンテンツごとの回答結果
        return state[content.stage][content.table][content.level].results
            ? state[content.stage][content.table][content.level].results
            : [];
    },
    getLatestWeakness: state => (stage, table) => { // 段ごとのコンテンツの最後に表示した苦手な九九
        return state.weakness[stage][table] ? state.weakness[stage][table] : [];
    },
    getTotalTime: state => {
        return state.totalTime;
    },
    getState: state => {
        return state;
    },
    getDefaultState: state => { // eslint-disable-line
        return getDefaultState()
    },
    isWeaknessSuggestFirst: state => {
        return state.weaknessSuggestFirst;
    },
    isWeaknessSuggestLast: state => {
        return state.weaknessSuggestLast;
    }
}
const mutations = {
    // アプリケーションバージョン
    setAppVersion(state, appVersion) {
        state.appVersion = appVersion;
    },
    // コンテンツ開始時案内フラグOFF
    offIntroduction(state) {
        state.version += 1;
        state.introduction = false;
    },
    // 回答問題数累計加算
    inclementAnswered(state, content) {
        this.$log.debug(content);
        // 累計の上限は9999までとする
        if (state.answered >= 9999) return;
        state.version += 1;
        state.answered += 1;
    },
    // コンテンツのチャレンジ回数加算
    incrementContentChallengeCount(state, content) {
        this.$log.debug("コンテンツチャレンジ回数加算");
        state.version += 1;
        state[content.stage][content.table][content.level].challenge += 1;
    },
    // コンテンツのクリア回数加算
    incrementContentClearCount(state, content) {
        this.$log.debug("コンテンツクリア回数加算");
        state.version += 1;
        state[content.stage][content.table][content.level].clear += 1;
    },
    // 最後に開いた九九一覧保存
    setLastTimestable(state, times) {
        this.$log.debug("最後に開いた九九一覧:%s", times);
        state.version += 1;
        state.lastTimestable = times;
    },
    // クリアまでかかった時間（秒）
    setTime(state, { content, time }) {
        this.$log.debug("クリアまでにかかった時間=>%d", time);
        state.version += 1;
        state[content.stage][content.table][content.level].time = time;
    },
    // 回答結果保存
    setContentResults(state, { content, results }) {
        this.$log.debug("回答結果追加=>コンテンツ:%s", JSON.stringify(content));
        state.version += 1;
        state[content.stage][content.table][content.level].results = results;
    },
    // 段ごとにコンテンツの最後に表示した苦手な九九保存
    setLatestWeakness(state, { content, weakness }) {
        this.$log.debug("コンテンツの最後に表示した苦手な九九保存=>コンテンツ:%s", JSON.stringify(content));
        state.version += 1;
        state.weakness[content.stage][content.table] = weakness;
    },
    // ステート全体を書式化
    init(state) {
        Object.assign(state, getDefaultState());
    },
    // ステート全体をコピー
    copy(state, newState) {
        Object.assign(state, newState);
    },
    // 総学習時間加算
    addTotalTime(state, learningTime) {
        state.totalTime += learningTime;
    },
    // コンテンツ全クリアガイダンス表示有無設定
    setContentClearAllGuidance(state, value) {
        state.contentClearAllGuidance = value;
    },
    // さいしょにきいてみよう苦手な九九の指マーク表示有無設定
    setWeaknessSuggestFirst(state, value) {
        state.weaknessSuggestFirst = value;
    },
    // さいごにきいてみよう苦手な九九の指マーク表示有無設定
    setWeaknessSuggestLast(state, value) {
        state.weaknessSuggestLast = value;
    }
}

const getLearningStateUrl = (login_id) => {
    // return process.env.VUE_APP_API_PATH_STATE + (login_id ? "/" + login_id : "");
    return process.env.VUE_APP_API_PATH_STATE.replace('{loginId}', login_id);
}

const actions = {
    // 学習状況最新化
    async refresh(context, login_id = null) {
        login_id ??= context.rootGetters['auth/getLoginId'];
        this.$log.debug("学習状況最新化開始");
        const url = getLearningStateUrl(login_id);
        this.$log.debug("サーバ学習状況取得=>%s", url);
        await this.$axios.get(url, context.state).then((response) => {
            this.$log.debug("サーバ学習状況取得成功");
            const data = response.data;
            if (Object.keys(data).length > 0) {
                // サーバ学習状況あり
                var localVer = context.getters["getVersion"];
                var serverState = data;
                this.$log.log(serverState);
                this.$log.debug("サーバ学習状況あり=>サーババージョン:%s、ローカルバージョン:%s", serverState.version, localVer);
                if (serverState.version > localVer) {
                    // サーバの方が新しい
                    this.$log.debug("サーバの方が新しい");
                    // ローカル学習状況上書き
                    context.commit("copy", serverState);
                    this.$log.debug("ローカル学習状況上書き=>%s", JSON.stringify(context.state));
                } else {
                    // ローカルの方が新しいまたは同じ
                    this.$log.debug("ローカルの方が新しいまたは同じ");
                }
            } else {
                // サーバ学習状況なし
                this.$log.debug("サーバ学習状況なし");
            }
        }).catch((e) => {
            this.$log.debug(e);
            this.$log.debug("学習状況取得失敗！！")
        });
    },
    // 学習状況送信
    async post(context, login_id = null) {
        login_id ??= context.rootGetters['auth/getLoginId'];
        this.$log.debug("学習状況送信開始")
        const url = getLearningStateUrl(login_id);
        this.$log.debug(url);
        await this.$axios.post(url, {
            learning_state: context.state,
        }).then(() => {
            this.$log.debug("学習状況送信成功")
        }).catch((e) => {
            this.$log.debug(e);
            this.$log.debug("学習状況送信失敗！！")
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}