<template>
  <div id="container" class="single center listen-record">
    <div v-if="!isPwa">
      <div class="appBanner">
        <div v-if="isInstallComp" class="txt">
          <div v-html="text_after"></div>
        </div>
        <div v-else-if="isMobileSafari" class="txt">
          <div v-html="text_install"></div>
          <div class="btnLink">
            <div class="link" @click="isModal = true">ホーム画面に<br>追加する方法</div>
          </div>
        </div>
        <div v-else-if="isMobileOther" class="txt">
          ブラウザSafariで<br>アクセスしてください。
          <div class="btn-center">
            <button class="add-button" @click="copyUrl">URLをコピーする</button>
          </div>
        </div>
        <div v-else-if="isMobileFirefox" class="txt">
          ブラウザChromeで<br>アクセスしてください。
          <div class="btn-center">
            <button class="add-button" @click="copyUrl">URLをコピーする</button>
          </div>
        </div>
        <div v-else class="txt">
          <div v-html="text_install"></div>
          <div class="btn-center">
            <div class="inner">
              <img class="content" src="~@/assets/img/loading.gif"/>
            </div>
            <button class="add-button">{{ btn_title }}</button>
            <div class="notes">
              ※この上にボタンが<br>
              表示されない場合<br>
              画面をタップ<br>
              してください。
            </div>
          </div>
        </div>
        <figure>
          <img src="~@/assets/img/apple-touch-icon.png" alt="">
        </figure>
      </div>

      <div v-if="isModal" class="descriptionWrap" @click="isModal = false">
        <div class="description">
          <h2>ホーム画面に追加する方法</h2>
          <ol>
            <li>「<img class="img" src="~@/assets/img/ico_share.png"/>ボタン」をタップ</li>
            <li>「ホーム画面に追加<img class="img" src="~@/assets/img/ico_home.png"/>」をタップ</li>
            <li>「追加」をタップでホームに追加されます。</li>
          </ol>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "InstallPrompt",
  data() {
    return {
      isBtn: false,
      isMoble: false,
      isModal: false,
      isTimeout: false,
      isInstallComp: false,
      isMobileSafari: false,
      isMobileOther: false,
      isMobileFirefox: false,
      vendor: '',
      userAgent: '',
      count: 0,
      btn_title: 'ホーム画面に追加',
      text_install: 'アプリを使用するには、<br>ホーム画面に<br>追加してください。',
      text_after: 'ホーム画面から<br>アプリを開いてください。',
    }
  },
  computed:{
    isPwa(){
      return this.$store.getters['app/getPwa'];
    },
    deferredPrompt(){
      return this.$store.getters['app/getDeferredPrompt'];
    }
  },
  mounted() {
    this.appCheck();
  },
  methods: {
    appCheck(){
      this.vendor = navigator.vendor.toLowerCase();
      this.userAgent = navigator.userAgent.toLowerCase();
      this.isMoble = ('ontouchstart' in window);
      if ('onbeforeinstallprompt' in window) {
        this.$timer.once(()=>{
          // ブラウザがchromeの時
          this.appInstallChrome();
        },100);
      } else if (this.isMoble) {
        if(this.vendor.indexOf('apple') !== -1){ // ios
          if(this.userAgent.match(/(crios|fxios)/i)){ // chrome firefox
            this.isMobileOther = true;
          }else{
            this.isMobileSafari = true;
          }
        }else if(this.userAgent.match(/(firefox)/i)){ // Android firefox
          this.isMobileFirefox = true;
        }
      }
    },
    appInstallChrome() {
      let timer;
      let addBtn = document.querySelector('.add-button');
      let inner = document.querySelector('.inner');
      if(this.isVal(addBtn)) addBtn.style.display = 'none';

      if(!this.isMoble){
        this.text_install = 'アプリを使用するには、<br>インストールしてください。';
        this.text_after = 'アプリから開いてください。';
        this.btn_title = 'アプリをインストール';
      }

      if(process.env.VUE_APP_DEVELOPMENT_MENU === 'ON'){
        console.log(this.deferredPrompt);
      }
      if(this.deferredPrompt){
        // Update UI to notify the user they can add to home screen
        addBtn.style.display = 'block';
        inner.style.display = 'none';

        clearTimeout(timer);

        addBtn.addEventListener('click', () => {
          // hide our user interface that shows our A2HS button
          addBtn.style.display = 'none';
          // Show the prompt
          this.deferredPrompt.prompt();
          // Wait for the user to respond to the prompt
          this.deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }
            this.deferredPrompt = null;
          });
        });

        window.addEventListener('appinstalled', () => {
          if (('ontouchstart' in window)) {
            this.isInstallComp = true;
          } else {
            window.location.href = '/?utm_source=pwa';
          }
        });
      }else{
        if(this.count < 500){
          this.count++;
          setTimeout(()=>{
            this.appInstallChrome();
          },1000);
        }else{
          if(!this.isMobileSafari && !this.isMobileOther && !this.isMobileFirefox){
            this.isInstallComp = true;
          }
        }
      }
    },
    copyUrl(){
      let url = window.location.href;
      if (navigator.clipboard) { // サポートしているかを確認
        navigator.clipboard.writeText(url).then(() => {
          // Alert the user that the action took place.
          // Nobody likes hidden stuff being done under the hood!
          alert('コピーしました:\n' + url);
        });
      }else{
        const el = document.createElement('textarea');
        el.value = url;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        alert('コピーしました:\n' + url);
      }
    },
    isVal(val){
      return !(!val || false || false);
    },
  }
}
</script>

<style lang="scss" scoped>
.appBanner{
  figure{
    width: 100%;
    max-width: 200px;
    margin: 2rem auto 0 auto;
    img{
      width: 100%;
    }
  }
  .btnLink{
    margin-top: 2rem;
  }
  .txt {
    line-height: 1.6;
    padding-left: 1rem;
    font-size: 2rem;
    text-align: center;
  }
  .link{
    color: #0d6efd;
  }
  .close{
    border: 2px solid #000000;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    left: -5px;
    top: -5px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before{
      content: "";
      display: block;
      width: 20px;
      height: 1px;
      background-color: #000000;
      transform: rotate(-45deg);
      position: absolute;
    }
    &:after{
      content: "";
      display: block;
      width: 20px;
      height: 1px;
      background-color: #000000;
      transform: rotate(45deg);
      position: absolute;
    }
  }
}

.descriptionWrap{
  position: absolute;
  z-index: 10001;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffffee;
}

button {
  font-size: 1rem;
  white-space: nowrap;
  color: #000;
  border: 3px solid #000;
  border-radius: 100vw;
  padding: 2% 3%;
  background: linear-gradient(to bottom, #eee 70%, #bbb);
  -webkit-appearance: button;
}

.btn-center{
  text-align: center;
  margin-top: 2rem;
}
.inner {
  position: static;
  max-width: 64px;
  margin: 0 auto;

  img {
    width: 100%;
  }
}
.notes{
  color: #cc0000;
  margin-top: 2rem;
}
.add-button{
  font-size: 2rem;
  margin: 0 auto;
}
.description {
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 3rem;
  width: 94%;
  margin: 0 auto;
  padding: 2rem 1.5rem;

  h2 {
    font-size: 1.7rem;
    font-weight: bold;
  }

  ol {
    li {
      margin-bottom: 1rem;

      img {
        width: 4.5%;
        display: inline-block;
        vertical-align: bottom;
        margin: 0 3px;
      }
    }
  }
}
</style>