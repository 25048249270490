<template>
  <div id="menu" :class="{ show: show }" v-show="show">
    <a
      class="close no-close"
      href="#"
      @click.stop.prevent="doClose"
      v-if="!showContact"
    >
      <img :src="$image('menu_close.svg')" alt="メニューを閉じる" />
    </a>
    <ul class="no-close" v-if="!showContact">
      <li class="no-close" v-if="!isLoginView">
        <div class="tap restriction no-close" @click="doRestrictionCancel">
          使用制限を解除
        </div>
      </li>
      <li class="no-close" v-if="!isLoginView">
        <div class="tap no-close" @click="doLogout">ログアウト</div>
      </li>
      <li class="no-close">
        <a class="tap no-close" :href="faqUrl" target="_blank">よくあるご質問</a>
      </li>
      <li class="no-close">
        <a class="tap no-close" :href="noticeUrl" target="_blank">お知らせ</a>
      </li>
      <li v-if="isDevelopment" class="no-close">
        <div class="tap no-close" @click="doStartLearningStateEditor">
          学習状況編集
        </div>
      </li>
      <li v-if="isDevelopment" class="no-close">
        <div class="tap no-close" @click="doStartHistory">学習履歴確認</div>
      </li>
      <li class="no-close">
        <div class="tap no-close" @click="doRefresh">最新版に更新</div>
      </li>
      <li class="no-close">
        <small>ソフトウェアバージョン:{{ appVersion }}</small>
      </li>
    </ul>
  </div>
</template>

<script>
import Restriction from "@/mixins/restriction";
import Orientation from "@/mixins/orientation";
import Activity from "@/mixins/activity";
import ServiceWorker from "@/mixins/serviceWorker";
export default {
  mixins: [Restriction, Orientation, Activity, ServiceWorker],
  data: function () {
    return {
      showContact: false, // お問い合わせ表示有無(true:表示、false:非表示)
    };
  },
  computed: {
    show() {
      return this.$store.getters["app/isMenu"];
    },
    isDevelopment() {
      return process.env.VUE_APP_DEVELOPMENT_MENU === "ON";
    },
    contactMailAddress() {
      return process.env.VUE_APP_CONTACT_MAIL_ADDRESS;
    },
    appVersion() {
      return this.$version;
    },
    faqUrl() {
      return (new URL('faq/', process.env.VUE_APP_LINK_BASE)).href;
    },
    noticeUrl() {
      return (new URL('notice/', process.env.VUE_APP_LINK_BASE)).href;
    },
    isLoginView() {
      return this.$store.getters["app/getCurrentView"] === 'ViewLogin';
    }
    // contactUsUrl() {
    //   return (new URL('contact-us/', process.env.VUE_APP_LINK_BASE)).href;
    // },
  },
  mounted() {
    // メニュー閉じるイベント設定
    this.$log.debug("メニュー閉じるイベント設定");
    window.addEventListener("click", this.handleCloseMenu);
    window.addEventListener("touchend", this.handleCloseMenu);
  },
  destroyed() {
    // メニュー閉じるイベント解除
    this.$log.debug("メニュー閉じるイベント解除");
    window.removeEventListener("click", this.handleCloseMenu);
    window.removeEventListener("touchend", this.handleCloseMenu);
  },
  methods: {
    handleCloseMenu(event) {
      if (
        event.target.id != "menu" &&
        !event.target.classList.contains("no-close")
      ) {
        if (this.show && !this.isOrientation) this.doClose();
      }
    },
    doClose() {
      this.$timer.once(() => {
        if (!this.show) return;
        // 画面ボタン操作イベント
        this.$store.dispatch("history/viewButtonEvent", {
          loginId: this.$store.getters["auth/getLoginId"],
          view: this.$store.getters["app/getCurrentView"],
          event: "ハンバーガーメニュー閉じる",
          table: this.$store.getters["app/getCurrentTable"],
        });
        // メニューを閉じる
        this.$store.dispatch("app/closeMenu");
        // 学習状況編集画面を閉じる
        this.$store.commit("app/setShowLearningStateEditor", false);
        // 学習履歴確認画面を閉じる
        this.$store.commit("app/setShowHistoryLog", false);
      }, 100);
    },
    doRestrictionCancel() {
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "ハンバーガーメニュー使用制限を解除",
        table: this.$store.getters["app/getCurrentTable"],
      });
      // メニューを閉じる
      this.$store.dispatch("app/closeMenu");
      // １日の使用制限解除画面へ
      this.$log.debug("１日の使用制限解除画面へ");
      this.$store.commit(
        "app/setRestrectedView",
        this.$store.getters["app/getCurrentView"]
      );
      this.$store.commit(
        "app/setCurrentViewWithoutHistory",
        "ViewRestrictionCancel"
      );
    },
    async doLogout() {
      // 画面ボタン操作イベント
      this.$store.dispatch("history/viewButtonEvent", {
        loginId: this.$store.getters["auth/getLoginId"],
        view: this.$store.getters["app/getCurrentView"],
        event: "ハンバーガーメニューログアウト",
        table: this.$store.getters["app/getCurrentTable"],
      });
      // 使用制限タイマー停止
      this.stopRestrictionTimer();
      // メニューを閉じる
      this.$store.dispatch("app/closeMenu");
      // ログアウト
      this.$log.debug("【ログアウト】ログアウト");
      this.$store.dispatch("auth/logout");
      // ログイン日時破棄
      this.$log.debug("【ログアウト】ログイン日時破棄");
      this.$store.commit("auth/resetLoginDateTime");
      // ログイン画面へ
      this.$log.debug("【ログアウト】ログイン画面");
      this.$store.commit("app/setCurrentView", "ViewLogin");
    },
    doStartLearningStateEditor() {
      // メニューを閉じる
      this.$store.dispatch("app/closeMenu");
      // 学習状況編集画面を開く
      this.$store.commit("app/setShowLearningStateEditor", true);
    },
    doStartHistory() {
      // メニューを閉じる
      this.$store.dispatch("app/closeMenu");
      // 学習履歴確認画面を開く
      this.$store.commit("app/setShowHistoryLog", true);
    },
    doContact() {
      this.showContact = true;
    },
    doCloseContact() {
      this.showContact = false;
    },
    doRefresh() {
      // アプリ更新
      this.appUpdate();
    },
  },
};
</script>