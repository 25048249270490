<template>
  <div id="container" class="multiplication-table">
    <slot />
    <div
      id="overlay"
      :class="{ show: showPlaySpeedButton }"
      v-if="showPlaySpeedButton"
    >
      <div id="play-speed">
        <button type="button" id="btn-normal" @click="doListenNormal">
          <img :src="$image('speaker.svg')" alt="ふつう" />
          <div>ふつう</div>
        </button>
        <button type="button" id="btn-slow" @click="doListenSlowly">
          <img :src="$image('speaker_slow.svg')" alt="ゆっくり" />
          <div>ゆっくり</div>
        </button>
      </div>
    </div>
    <header>
      <div id="nav">
        <MpFinish :cumulativeLog="false" />
        <MpHamburger />
      </div>
    </header>
    <main>
      <div id="message">
        <button
          type="button"
          id="btn-listen"
          @click="startListen"
          :disabled="playTimes > 0"
          v-if="timestable <= 9"
        >
          きく
        </button>
      </div>
      <div id="content">
        <div
          class="expr-box"
          v-for="n in 9"
          :key="n"
          :class="{ selected: n === playTimes }"
        >
          <div class="expr">
            <img
              :src="$image(`table/${timestable}x${n}.svg`)"
              alt=""
            />
          </div>
        </div>
      </div>
      <div id="stages">
        <button
          v-for="n in 10"
          :key="n"
          type="button"
          class="btn-stage"
          :class="{ current: timestable === n }"
          @click="changeTimesTable(n)"
          :disabled="playTimes > 0"
        >
          <img v-if="timestable === n" :src="$image(`table_button/table_button-${n}-selected.svg`)" />
          <img v-else :src="$image(`table_button/table_button-${n}.svg`)" />
        </button>
      </div>
    </main>
  </div>
</template>

<script>
import MpHamburger from "@/components/ui/MpHamburger";
import MpFinish from "@/components/ui/MpFinish.vue";
import Restriction from "@/mixins/restriction";
export default {
  components: { MpHamburger, MpFinish },
  mixins: [Restriction],
  computed: {
    loginId: function () {
      return this.$store.getters["auth/getLoginId"];
    },
    timestable() {
      return this.$store.getters["learningState/getLastTimestable"];
    },
    content() {
      return {
        stage: "timestable",
        table: "table" + this.timestable,
        level: "",
      };
    },
  },
  data: function () {
    return {
      playTimes: 0, // 再生する九九
      playInternal: 1000, // 再生間隔
      showPlaySpeedButton: false, // 再生スピードボタン表示有無（true:表示、false:非表示）
    };
  },
  watch: {
    playTimes: function (val) {
      if (val > 9) {
        // 全部終わったらリセット
        this.$log.debug("再生する九九リセット:$d", this.playTimes);
        this.playTimes = 0;
        // 学習履歴その他のイベント
        this.$store.dispatch("history/othersEvent", {
          loginId: this.loginId,
          content: this.content,
          event: "音声終了",
        });
      }
      if (val > 0 && val < 10) {
        this.$sound.timestable(this.timestable, val).play(
          (id) => {
            this.$log.debug("%s x %s再生開始(%s)", this.timestable, val, id);
          },
          (id) => {
            this.$log.debug("%s x %s再生終了(%s)", this.timestable, val, id);
            setTimeout(() => {
              this.playTimes += 1;
            }, this.playInternal);
          }
        );
      }
    },
    timestable: function () {
      // 学習履歴コンテンツ学習開始
      this.$store.dispatch("history/startContent", {
        loginId: this.loginId,
        content: this.content,
      });
      // コンテンツ学習時間計測開始
      this.$store.commit("app/startLearningTime");
    },
  },
  created() {
    // 縦レイアウト設定
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("portrait");
    // 縦レイアウト強制
    this.$log.debug("縦レイアウト強制");
    this.$store.commit("app/enforceOrientationPortrait");
  },
  mounted() {
    // 使用制限抑止
    this.disableRestriction();
    // 学習履歴コンテンツ学習開始
    this.$store.dispatch("history/startContent", {
      loginId: this.loginId,
      content: this.content,
    });
    // コンテンツ学習時間計測開始
    this.$store.commit("app/startLearningTime");
  },
  destroyed() {
    // 縦レイアウト解除
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("portrait");
    // 使用制限抑止解除
    this.enableRestriction();
  },
  methods: {
    // きくボタン押下
    startListen() {
      // 再生スピードボタン表示
      this.showPlaySpeedButton = true;
      // 学習履歴その他のタップイベント
      this.$store.dispatch("history/othersTapEvent", {
        loginId: this.loginId,
        content: this.content,
        event: "きく",
      });
    },
    // ふつう
    doListenNormal() {
      this.$log.debug("ふつうに再生開始");
      // 学習履歴その他のタップイベント
      this.$store.dispatch("history/othersTapEvent", {
        loginId: this.loginId,
        content: this.content,
        event: "ふつう",
      });
      // 再生スピードボタン非表示
      this.showPlaySpeedButton = false;
      // 再生間隔設定
      this.playInternal = 0;
      // 1の段から再生開始
      this.playTimes += 1;
    },
    // ゆっくり
    doListenSlowly() {
      this.$log.debug("ゆっくり再生開始");
      // 学習履歴その他のタップイベント
      this.$store.dispatch("history/othersTapEvent", {
        loginId: this.loginId,
        content: this.content,
        event: "ゆっくり",
      });
      // 再生スピードボタン非表示
      this.showPlaySpeedButton = false;
      // 再生間隔設定
      this.playInternal = 1000;
      // 1の段から再生開始
      this.playTimes += 1;
    },
    changeTimesTable(timestable) {
      // 最後に開いた九九一覧設定
      this.$store.commit("learningState/setLastTimestable", timestable);
    },
  },
};
</script>