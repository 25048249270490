import { Howl, Howler } from "howler";
import store from '../store/index';

const UA = navigator.userAgent;
var html5 = /\b(macintosh|iPod|iPhone|iPad)\b/i.test(UA) && "ontouchend" in window;
var preload = "auto";
var Player = (function () {
    var instance;
    function init() {
        return {
            src: '',
            howl: null,
            play: function (onplay, onend) {
                var audioCtx = Howler.ctx;
                if (audioCtx && audioCtx.state === 'suspended') {
                    audioCtx.resume().then(() => {
                        this.doPlay(onplay, onend);
                    });
                } else {
                    this.doPlay(onplay, onend);
                }
            },
            doPlay: function (onplay, onend) {
                this.stop();
                this.howl = new Howl({
                    src: this.src,
                    html5: html5,
                    preload: preload,
                    onplay: onplay,
                    onend: onend
                });
                this.howl.play();
            },
            stop: function () {
                if (this.howl) {
                    this.howl.stop();
                    this.howl.unload();
                    this.howl = null;
                }
            }
        }
    }
    return {
        getInstance: function (src) {
            if (!instance) {
                instance = init();
            }
            instance.src = store.getters['soundData/getSound'](src);
            return instance;
        },
    }
})();
var MultiPlayer = (function () {
    var instance;
    function init() {
        return {
            srcs: [],
            howl: null,
            play: function (onend) {
                var audioCtx = Howler.ctx;
                if (audioCtx && audioCtx.state === 'suspended') {
                    audioCtx.resume().then(() => {
                        this.doPlay(onend);
                    });
                } else {
                    this.doPlay(onend);
                }
            },
            doPlay: function (onend) {
                this.stop();
                if (this.srcs.length > 1) {
                    this.howl = new Howl({
                        src: this.srcs.shift(),
                        html5: html5,
                        preload: preload,
                        onend: () => { this.play(onend) },
                    });
                    this.howl.play();
                } else if (this.srcs.length === 1) {
                    this.howl = new Howl({
                        src: this.srcs.shift(),
                        html5: html5,
                        preload: preload,
                        onend: onend,
                    });
                    this.howl.play();
                }
            },
            stop: function () {
                if (this.howl) {
                    this.howl.stop();
                    this.howl.unload();
                    this.howl = null;
                }
            }
        }
    }
    return {
        getInstance: function (srcs) {
            if (!instance) {
                instance = init();
            }
            instance.srcs = [];
            if (Array.isArray(srcs)) {
                instance.srcs = srcs.map((src) => store.getters['soundData/getSound'](src));
            } else {
                instance.srcs.push(store.getters['soundData/getSound'](srcs));
            }
            return instance;
        },
    }
})();
var AudioPlayer = (function () {
    var instance;
    function init() {
        return {
            src: '',
            format: '',
            howl: null,
            play: function (onplay, onend) {
                var audioCtx = Howler.ctx;
                if (audioCtx && audioCtx.state === 'suspended') {
                    audioCtx.resume().then(() => {
                        this.doPlay(onplay, onend);
                    });
                } else {
                    this.doPlay(onplay, onend);
                }
            },
            doPlay: function (onplay, onend) {
                this.stop();
                this.howl = new Howl({
                    src: this.src,
                    html5: html5,
                    format: this.format,
                    onplay: onplay,
                    onend: onend
                });
                this.howl.play();
            },
            stop: function () {
                if (this.howl) this.howl.stop();
            }
        }
    }
    return {
        getInstance: function (src, format) {
            if (!instance) {
                instance = init();
            }
            instance.src = store.getters['soundData/getSound'](src);
            instance.format = format;
            return instance;
        },
    }
})();
const Sound = {
    install(Vue) {
        // WebAudio利用可否
        Vue.prototype.$log.debug("WebAudioが利用可能かチェックします。=>%s", Howler.usingWebAudio);
        // オーディオ自動的有効化
        Howler.autoUnlock = true;
        Vue.prototype.$log.debug("モバイル（iOS、Androidなど）デバイスおよびデスクトップのChrome / Safariでオーディオを自動的に有効にしようとします。=>%s", Howler.autoUnlock);
        Vue.prototype.$sound = {
            ok() {    // 回答OKの場合
                return Player.getInstance("ok.wav");
            },
            ng() {    // 回答NGの場合
                return Player.getInstance("ng.wav");
            },
            listenFirst() {  // 「さいしょにきいてみよう！」
                return MultiPlayer.getInstance("listen_1.mp3");
            },
            listenLast() { // 「さいごにきいてみよう！」
                return MultiPlayer.getInstance("listen_2.mp3");
            },
            repeat() { // 「あとにつづけていってみよう！」
                return MultiPlayer.getInstance("read_1.mp3");
            },
            record() { // 「ろくおんする？」
                return MultiPlayer.getInstance("record.mp3");
            },
            restriction() { // 「30分をこえたのですこしきゅうけいしましょう」
                return MultiPlayer.getInstance("rest.mp3");
            },
            complete() {   // 「効果音」「よくできたね！」 
                return MultiPlayer.getInstance(["content_clear.wav", "good_1.mp3"]);
            },
            congratulations() {    // 「効果音」「おめでとうと！」
                return MultiPlayer.getInstance(["content_clear.wav", "good_2.mp3"]);
            },
            quickly() {     // 「はやくできたね！」
                return MultiPlayer.getInstance("good_3.mp3");
            },
            triedHard() {   // 「がんばったね！」
                return MultiPlayer.getInstance("good_4.mp3");
            },
            masterAll() {  // 「九九をマスターしたね！」
                return MultiPlayer.getInstance(["content_clear_all.wav", "good_5.mp3"]);
            },
            listen(timestable) {    // きく・いうの「○のだんを聞いてみよう！」
                return MultiPlayer.getInstance("listen_" + timestable + "s.mp3");
            },
            intoroduction() {  // 「だんだん難しくなるよ！」
                return MultiPlayer.getInstance("guide/2s_s.mp3");
            },
            start(table) {    // レベルスタートガイド
                var id = table.replace("table", "").replace("and", "&").replace("to", "-");
                return MultiPlayer.getInstance("guide/start_" + id + "s.mp3");
            },
            guide(id) {    // レベルごとのガイド
                Vue.prototype.$log.debug("ガイド生成=>%s", id);
                if (id === "ns_level1") {
                    // N単-Lv.1
                    return MultiPlayer.getInstance("guide/hint_1.mp3");
                } else if (id === "ns_level2") {
                    // N単-Lv.2
                    return MultiPlayer.getInstance("guide/hint_2.mp3");
                } else if (id === "ns_level3_first") {
                    // N単-Lv.3 最初
                    return MultiPlayer.getInstance(["guide/problem_18.mp3", "guide/time_15s.mp3"]);
                } else if (id === "ns_level3_second") {
                    // N単-Lv.3 次
                    return MultiPlayer.getInstance(["guide/hint_5t.mp3", "guide/tap_stop.mp3"]);
                } else if (id === "ns_level4_first") {
                    // N単-Lv.4 最初
                    return MultiPlayer.getInstance(["guide/problem_18.mp3", "guide/time_10s.mp3"]);
                } else if (id === "ns_level4_second") {
                    // N単-Lv.4 次
                    return MultiPlayer.getInstance(["guide/hint_2t.mp3", "guide/tap_stop.mp3"]);
                } else if (id === "ns_level5_first") {
                    // N単-Lv.5 最初
                    return MultiPlayer.getInstance(["guide/problem_18.mp3", "guide/time_3m.mp3"]);
                } else if (id === "ns_level6_first") {
                    // N単-Lv.6 最初
                    return MultiPlayer.getInstance(["guide/problem_18.mp3", "guide/time_1m30s.mp3"]);
                } else if (id === "nm_level1_first") {
                    // N複-Lv.1
                    return MultiPlayer.getInstance(["guide/problem_24.mp3", "guide/time_3m.mp3"]);
                } else if (id === "nm_level2_first_table2to3") {
                    // N複-Lv.2 2〜3の段
                    return MultiPlayer.getInstance(["guide/problem_24.mp3", "guide/time_2m.mp3"]);
                } else if (id === "nm_level2_first_table2to4") {
                    // N複-Lv.2 2〜4の段
                    return MultiPlayer.getInstance(["guide/problem_24.mp3", "guide/time_1m55s.mp3"]);
                } else if (id === "nm_level2_first_table2to5") {
                    // N複-Lv.2 2〜5の段
                    return MultiPlayer.getInstance(["guide/problem_24.mp3", "guide/time_1m50s.mp3"]);
                } else if (id === "nm_level2_first_table2to6") {
                    // N複-Lv.2 2〜6の段
                    return MultiPlayer.getInstance(["guide/problem_24.mp3", "guide/time_1m45s.mp3"]);
                } else if (id === "nm_level2_first_table2to7") {
                    // N複-Lv.2 2〜7の段
                    return MultiPlayer.getInstance(["guide/problem_24.mp3", "guide/time_1m40s.mp3"]);
                } else if (id === "nm_level2_first_table2to8") {
                    // N複-Lv.2 2〜8の段
                    return MultiPlayer.getInstance(["guide/problem_24.mp3", "guide/time_1m35s.mp3"]);
                } else if (id === "nm_level2_first_table2to9") {
                    // N複-Lv.2 2〜9の段
                    return MultiPlayer.getInstance(["guide/problem_24.mp3", "guide/time_1m30s.mp3"]);
                } else if (id === "bm_level1_first") {
                    // B複-Lv.1
                    return MultiPlayer.getInstance(["guide/problem_24.mp3", "guide/time_3m.mp3"]);
                } else if (id === "bm_level2_first") {
                    // B複-Lv.2
                    return MultiPlayer.getInstance(["guide/problem_24.mp3", "guide/time_1m30s.mp3"]);
                }
            },
            multiplication(multiplicand, multiplier) {   // 九九読み上げ
                return MultiPlayer.getInstance("multiplication/m" + multiplicand + "_" + multiplier + ".mp3");
            },
            question(multiplicand, multiplier) {    // 九九問題読み上げ
                return MultiPlayer.getInstance("question/m" + multiplicand + "_" + multiplier + "_no.mp3");
            },
            timestable(multiplicand, multiplier) { // 九九一覧の読み上げ
                return Player.getInstance("multiplication/m" + multiplicand + "_" + multiplier + ".mp3");
            },
            timestableSmall(multiplicand, multiplier) { // 九九一覧の読み上げ（音量小バージョン）
                return Player.getInstance("multiplication/m" + multiplicand + "_" + multiplier + "_small.mp3");
            },
            audioPlayer(blob) {
                Vue.prototype.$log.debug("録音データ再生");
                // blobよりBase64URL生成
                const audioURL = URL.createObjectURL(blob);
                Vue.prototype.$log.debug("再生する録音データURL=>%s", audioURL);
                // 音声データのフォーマット取得
                let audioFormat = 'mp3';
                if (blob) {
                    const matches = blob.type.match(/audio\/([^;]+)/);
                    if (matches) {
                        audioFormat = matches[1];
                    }
                }
                Vue.prototype.$log.debug("再生する録音データフォーマット=>%s", audioFormat);
                return AudioPlayer.getInstance(audioURL, audioFormat);
            }
        }
    }
}

export default Sound;